import { combineReducers } from 'redux';

import { filterReducer } from './filterReducer';
import { userReducer } from './userReducer';
import { promotionsReducer } from './promotionsReducer';
import { purchasesReducer } from './purchasesReducer';
import { formReducer } from './formReducer';
import { popupModalReducer } from './popupModalReducer';
import { googleReducer } from './googleReducer';

const rootReducer = combineReducers({
    filter: filterReducer,
    user: userReducer,
    promotions: promotionsReducer,
    purchases: purchasesReducer,
    form: formReducer,
    popupModal: popupModalReducer,
    google: googleReducer
})

export default rootReducer;