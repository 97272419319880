//libraries
import React from 'react';

//images
import DirectionsIcon from '../../img_resources/Feed/directions-purple.svg'
import DirectionsIconApple from '../../img_resources/Feed/directions-purple-apple.svg'

const DirectionsBtn = ({address}) => {

    const userAgent = window.navigator.userAgent;

    return(  
        <>
            {
                <a className="icon smaller" style={{margin:'0px 10px'}} href={"http://maps.google.com/?q="+ address} target="_blank" rel="noopener noreferrer">
                    <img 
                        className="icon smaller" 
                        src={
                            (userAgent.match(/iPhone/i) || userAgent.match(/Mac/i))?
                            DirectionsIconApple:
                            DirectionsIcon
                        } 
                        alt="purchase icon" 
                    />
                </a>
            }
        </>
    )
}


export default DirectionsBtn;