import React from 'react';
import {useSelector} from 'react-redux';
import Fade from 'react-reveal'
import {NavLink} from 'react-router-dom';

//components
import Title from '../components/Title'
import PurchasedAd from '../components/Ad Blocks/PurchasedAd';
import Loader from '../components/Loader';

//images
import cartoonFood from '../img_resources/Purchases/cute food.svg'

const PurchaseHistory = () => {
    
    // const dispatch = useDispatch();
    
    const archivePurchases = useSelector(store=>store.purchases.archive)
    const isLoading = useSelector(store=>store.purchases.loading)

    if(isLoading){
        return <Loader/>
    } else{
        return(
            <Fade bottom>
                <div className="new-container" id="feed">
                    {
                        isLoading?
                        <Loader/>:
                        <div className="content-wrapper profile-wrapper">
                            {
                                archivePurchases.length>0?
                                    <Title text={`Your Past Purchases`}/>:
                                    <></>
                            }
                            {
                                archivePurchases.length>0?
                                    archivePurchases.map(purchase=>(
                                        <PurchasedAd key={purchase.RelatedPromotion.PromotionId} promotion={purchase.RelatedPromotion} active={false} purchase={purchase}/>
                                    )):
                                    <>
                                        
                                        <img src={cartoonFood} alt="presents" className="not-active-purchases"/>
                                        <h2 className="instructions-title" style={{width:'90vw'}}>It seems you haven't collected any of your purchases</h2>
                                        <NavLink to={'/Feed'} className="feed-button" style={{textDecoration:"none", marginTop:'15px'}}>
                                            Find Zestty Deals!
                                        </NavLink>
                                    </>
                            }
                        </div>
                    }
                </div>
            </Fade>
        )
    }    

}

export default PurchaseHistory;