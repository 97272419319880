//external libraries
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Fade from 'react-reveal'
import QRCode from 'qrcode.react';
import {Rnd} from 'react-rnd'
import { Redirect } from 'react-router-dom';

//components
import Payment from '../components/Form Types/Payment';
import Report from '../components/Form Types/Report';
import Share from '../components/Form Types/Share';
import RedirectShare from '../components/Form Types/RedirectShare';
import Terms from '../components/Form Types/Terms';
import FormAd from '../components/Ad Blocks/FormAd';
import PurchaseSuccess from '../components/Form Types/PurchaseSuccess';
import Feedback from '../components/Form Types/Feedback';
import NewPaymentMethod from '../components/Form Types/NewPaymentMethod';

//actions
import {updatePromotion} from '../actions/promotionsActions';
import {setForm} from '../actions/formActions';
import {setModal} from '../actions/popupModalActions';

//images
import loading from '../img_resources/Forms/loading-purchase.gif'
import partyImg from '../img_resources/Popup Modal/already-purchased-error.svg'
import soldOutImg from '../img_resources/Popup Modal/sold-out-error.svg'

//inner libraries
import ZESTTY from '../api'
import UI from '../ui_functions'







const Form = () => {
    const dispatch = useDispatch();

    const userId = useSelector(store=>store.user.user.username);
    const form = useSelector(store=>store.form);
    const purchases = useSelector(store=>store.purchases.active);
    const promotions = useSelector(store=>store.promotions.promotions);

    const [promotion, setPromotion] = useState({});
    const [purchase, setPurchase] = useState({});
    const [message, setMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    
    //Looks for the purchase in the purchases array
    useEffect(()=>{
        if(form.action==="PURCHASE"){

            setLoading(true);
            if(purchases.length>0){
                let purchaseRes = purchases.filter(itm=>itm.purchaseId.includes(form.promotion.PromotionId))
                console.log(purchases)
                if(purchaseRes.length>0){
                    // setMessage("It seems you have already purchased this promotion")
                    dispatch(setModal("error", "Hurray!", "It seems you already own this promotion!", partyImg))
                    setPurchase(purchaseRes[0])
                }
            }
            
        }
    },[purchases])

    //Fetches the promotion from the backend to get the most updated details
    useEffect(()=>{
        if(form.action==="PURCHASE"){
            setLoading(true);
            ZESTTY.getPromotion(form.promotion.PromotionId)
                .then(res=>{
                    if(res.promotion.hasOwnProperty("Item")){
                        setPromotion(res.promotion.Item);
                        dispatch(updatePromotion(res.promotion.Item, promotions))
                    }
                })
                .catch(err=> {
                    setMessage("There seems to be a communication error. Please try again later.")
                    setLoading(false);
                    console.log(err)
                })
        }
    },[form.action])

    //validates that the promotion is still active and has more than 0 quantity
    useEffect(()=>{
        if(form.action==="PURCHASE" && promotion.hasOwnProperty("PromotionId")){
            if(promotion.Quantity>0 && promotion.Active){
                setLoading(false);
            } else {
                dispatch(setModal("error", "Uh-Oh!", "Looks like this promotion ran out!", soldOutImg))
                setMessage("Oops! Looks like this promotion ran out")
                dispatch(setForm({}, "Hide", ""));
                setLoading(false);
            }
        }
    },[promotion])

    const userAgent = window.navigator.userAgent;

    const formType = (action) => {
        switch (action) {
            case "PURCHASE":
                if(promotion.hasOwnProperty("PromotionId")){
                    return(
                        <>
                            <FormAd promotion={promotion}/>
                            <p className="purchase-message" style={{marginTop:'10px'}}>This promotion does <b>NOT</b> allow you to skip lines to enter the establishment unless stated otherwise.</p>
                            <Payment promotion={promotion}/>
                        </>
                    )
                } else {
                    if(message==="It seems you have already purchased this promotion."){
                        dispatch(setForm({}, "Hide", ""));
                        
                        return <Redirect to={{ 
                            pathname: `/Promotion/`+form.promotion.PromotionId.replace("#",""),
                            state: {promotion: form.promotion, purchase: purchase}
                        }} />
                    } else if (message==="Oops! Looks like this promotion ran out"){
                        dispatch(setForm({}, "Hide", ""));
                        //PUT MODAL BOX TO SAY THAT IT RAN OUT
                        //REDIRECT TO FEED
                        return <Redirect to={{ pathname: `/Feed/` }} />
                    }
                }
                break;
        
            case "REPORT":
                return (
                    <>
                        <FormAd promotion={form.promotion}/>
                        <Report promotion={form.promotion}/>
                    </>
                    )

            case "SHARE":
                return (
                    <>
                        <FormAd promotion={form.promotion}/>
                        <Share promotion={form.promotion}/>
                    </>
                    )

            case "REDIRECT": 
                return(
                    <RedirectShare />
                )

            case "QR":
                return (
                    <>
                        <FormAd promotion={form.promotion}/>
                        <QRCode 
                            className="qr-code"
                            value={form.QR} 
                            size={128} 
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            renderAs={"svg"}
                            />
                            <p><b>{form.code}</b></p>
                            <p className="terms-link" style={{marginTop:'10px',color:'#AEAEAE'}}>This promotion does <b>NOT</b> allow you to skip lines to enter the establishment unless stated otherwise.</p>
                    </>
                )

            case "TERMS":
                return <Terms/>

            case "REPORT SUCCESS":
                return(
                    <>
                        <FormAd promotion={form.promotion}/>
                    </>
                )

            case "COMPLETE":
                return(
                    <>
                        <FormAd promotion={form.promotion}/>
                        <PurchaseSuccess/>
                    </>
                )

            case "FEEDBACK":
                return (
                    <>
                        <FormAd promotion={form.promotion}/>
                        <Feedback promotion={form.promotion}/>
                    </>
                    )

            case "NEW PAYMENT METHOD":
                return (
                    <>
                        <NewPaymentMethod promotion={form.promotion}/>
                    </>
                    )

            default:
                break;
        }
    }

    const styleAndroid = {
        height: 'auto',
        width: '100vw',
        borderRadius: '35px 35px 0px 0px',
        padding: '10px 30px 30px 30px',
        paddingBottom: 'calc(15vh + 30px)',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: '#FFFFFF',
        overflow: 'auto',
        boxSizing: 'border-box',
        transition: 'all 1s ease'
    }

    const styleiPhone = {
        height: 'auto',
        width: '100vw',
        borderRadius: '35px 35px 0px 0px',
        padding: '10px 30px 30px 30px',
        paddingBottom: '10vh',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: '#FFFFFF',
        overflow: 'auto',
        boxSizing: 'border-box',
        transition: 'all 1s ease'
    }
    
    return(
        <>
        {
            form.action!=="Hide"?
            <Fade>
                <div 
                    className="form-wrapper" 
                    onClick={()=> {
                        if(!form.loading){
                            dispatch(setForm({}, "Hide",""))
                        }
                    }}
                    
                    >
                    <Fade bottom>
                        {/* <Rnd 
                            // className="form" 
                            style={(userAgent.match(/iPhone/i) || userAgent.match(/Mac/i))?styleiPhone:styleAndroid}
                            onClick={(e)=>{e.stopPropagation()}}
                            enableResizing={{top:true, right:false, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false}}
                            disableDragging={true}
                            resizeGrid= {[0.5,1]}
                            dragAxis={'none'}
                            maxHeight={'90vh'}
                            resizeHandleStyles={
                                {
                                    top: {
                                        width: '100%',
                                        height: '100px',
                                        backgroundColor: 'transparent'
                                    }
                                }
                            }
                            onResizeStop={(e, direction, ref, delta, position)=>{
                                if(ref.style.height.split("px")[0]<300){
                                    dispatch(setForm({}, "Hide",""))
                                }
                            }}
                            > */}
                                <div 
                                    className="form-content-wrapper" 
                                    style={
                                        (userAgent.match(/iPhone/i) || userAgent.match(/Mac/i))?
                                        {paddingBottom:'100px'}:
                                        {paddingBottom:'0px'}
                                    }
                                    onClick={(e)=> e.stopPropagation() }
                                >
                                    <div className="form-line"></div>

                                    <h2 className="form-text" style={{textAlign:form.action==="REPORT SUCCESS"?"center":"left"}}>{form.text}</h2>

                                    {
                                        isLoading?
                                            <div className="form-animation-wrapper">
                                                <img style={{width:"50px", height:"auto"}} src={loading} alt="logo animation"/>
                                            </div>:
                                            formType(form.action)
                                    }    
                                </div>                                                    
                        {/* </Rnd> */}
                    </Fade>
                </div>
            </Fade>:
            <></>
        }
        </>
    )
}

export default Form;