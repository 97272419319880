import React from 'react';
import {useSelector} from 'react-redux';
import {Map, Marker} from 'google-maps-react';

import zIcon from '../img_resources/Map View/map-anchor.png';

// const SingleAdGoogleMaps = (props) => {
const SingleAdGoogleMaps = ({ geoLocation }) => {
  
    const google = useSelector(store=>store.google.google)

    const style = {
        width: '100%',
        height: '30vh',
        position: 'absolute',
        borderRadius: '8px',
        zIndex: 50
      }

    return (
        <Map google={google}           
            style={style}
            zoom={18} 
            mapTypeControl={false}
            fullscreenControl={false}
            streetViewControl={false}
            // zoomControl={false}
            // gestureHandling={'cooperative'}
            initialCenter={{
                lat: geoLocation.lat,
                lng: geoLocation.lng
            }}
            center={{
                lat: geoLocation.lat,
                lng: geoLocation.lng
            }}
            >
            <Marker 
                //onClick={this.onMarkerClick}
                position={{
                    lat: geoLocation.lat,
                    lng: geoLocation.lng
                }}
                icon={{
                    url: zIcon,
                    // anchor: new this.props.google.maps.Point(32,32),
                    scaledSize: new google.maps.Size(40,40)
                }}
                />

        </Map>
    );
}
 
// export default GoogleApiWrapper({
//   apiKey: ('AIzaSyBPJUpilKiLyuG7hpmiIqgOfiVP3AXLO0U')
// }) (SingleAdGoogleMaps)
export default SingleAdGoogleMaps