import ZESTY from '../api';

export function postPurchaseToServer(promotionId, userId, price, token, timeStamp, paymentMethod, advertiserId, appName, qty) {

    return function(dispatch) {
        dispatch(postPurchaseStarted());
        if (qty < 10) {
            qty = "0" + qty
        }
        ZESTY.postPurchase(promotionId, userId, price, token, timeStamp, paymentMethod, advertiserId, appName, qty)
            .then(response => {
                dispatch(addToPurchaseList({
                    PurchaseDateTime: {
                        S: timeStamp
                    },
                    token: {
                        S: token
                    },
                    Amount: {
                        N: price
                    },
                    PaymentMethod: {
                        S: paymentMethod
                    },
                    Active: {
                        BOOL: true
                    },
                    purchaseId: {
                        S: promotionId + "#" + userId
                    },
                    AdvertiserId: {
                        S: advertiserId
                    },
                    AppName: {
                        S: appName
                    },
                    Code: {
                        S: (promotionId[1] + userId[userId.length - 1] + advertiserId[0] + qty[0] + advertiserId[advertiserId.length - 1] + qty[1] + promotionId[promotionId.length - 1] + userId[0]).toUpperCase()
                    }
                }));
            })
            .catch(err => {
                console.log(err);
                dispatch(postPurchaseFail(err.message))
            })
    }
}

export function fetchPurchases(userId) {
    return function(dispatch) {
        dispatch(getPurchasesStarted());
        // ZESTY.getPurchases(userId)
        ZESTY.getUserPurchases(userId)
            .then(purchases => {
                dispatch(getPurchasesSuccess(purchases));
            })
            .catch(err => {
                dispatch(getPurchasesFailure(err.message))
            })
    }
}

export function fetchPurchasesPromotions(promotionIds) {
    return function(dispatch) {
        dispatch(getPurchasesPromotionsStarted());
        ZESTY.getBatchPromotions(promotionIds)
            .then(promotions => {
                // promotions.activePromotions.forEach(promotion => promotion.isPurchased = true)
                // promotions.archivePromotions.forEach(promotion => promotion.isPurchased = true)
                dispatch(getPromotionsSuccess(promotions));
            })
            .catch(err => {
                dispatch(getPromotionsFailure(err.message))
            })
    }
}

// export function fetchArchivePurchases(userId, city) {
//     return function(dispatch) {
//         dispatch(getArchivePurchasesStarted());
//         ZESTY.getArchivePurchases(userId)
//             .then(purchases => {
//                 dispatch(getArchivePurchasesSuccess(purchases));
//             })
//             .catch(err => {
//                 dispatch(getArchivePurchasesFailure(err.message))
//             })
//     }
// }

// export function fetchActivePurchases(userId) {
//     return function(dispatch) {
//         dispatch(getActivePurchasesStarted());
//         ZESTY.getActivePurchases(userId)
//             .then(purchases => {
//                 dispatch(getActivePurchasesSuccess(purchases));
//             })
//             .catch(err => {
//                 dispatch(getActivePurchasesFailure(err.message))
//             })
//     }
// }

// export function fetchAd(city, promotionId, type) {
//     return function(dispatch) {
//         dispatch(getAdStarted());
//         ZESTY.getPromotion(city, promotionId)
//             .then(promotion => {
//                 dispatch(getAdSuccess(promotion, type));
//             })
//             .catch(err => {
//                 dispatch(getAdFailure(err.message))
//             })
//     }
// }

const getPurchasesStarted = () => {
    return {
        type: 'GET_PURCHASES'
    }
}

const getPurchasesSuccess = (purchases) => {
    for (let i = 0; i < purchases.active.length; i++) {
        purchases.active[i].RelatedPromotion = JSON.parse(purchases.active[i].RelatedPromotion);

    }
    for (let i = 0; i < purchases.archive.length; i++) {
        purchases.archive[i].RelatedPromotion = JSON.parse(purchases.archive[i].RelatedPromotion);
    }

    purchases.active.sort((a, b) => new Date(b.PurchaseDateTime).getTime() - new Date(a.PurchaseDateTime).getTime())
    purchases.archive.sort((a, b) => new Date(b.PurchaseDateTime).getTime() - new Date(a.PurchaseDateTime).getTime())

    return {
        type: 'GET_PURCHASES_SUCCESS',
        purchases: purchases
    }
}

const getPurchasesFailure = (message) => {
    return {
        type: 'GET_PURCHASES_FAILURE',
        err: message
    }
}

const getPurchasesPromotionsStarted = () => {
    return {
        type: 'GET_PURCHASES_PROMOTIONS'
    }
}

const getPromotionsSuccess = (promotions) => {
    return {
        type: 'GET_PURCHASES_PROMOTIONS_SUCCESS',
        promotions: promotions
    }
}

const getPromotionsFailure = (message) => {

    return {
        type: 'GET_PURCHASES_PROMOTIONS_FAILURE',
        err: message
    }
}

const addToPurchaseList = (purchase) => {

    return {
        type: 'ADD_TO_PURCHASE_LIST',
        purchase: purchase
    }
}

const postPurchaseStarted = () => {
    return {
        type: 'POST_PURCHASE'
    }
}

const postPurchaseFail = (message) => {
    return {
        type: 'POST_PURCHASE_FAIL',
        err: message
    }
}

// const getPromotionsSuccess = (promotions, type) => {
//     return {
//         type: 'GET_PURCHASES_PROMOTIONS_SUCCESS',
//         promotions: promotions,
//         PurchaseType: type
//     }
// }



// const getAdStarted = () => {
//     return {
//         type: 'GET_AD'
//     }
// }

// const getAdSuccess = (ad, type) => {
//     return {
//         type: 'GET_AD_SUCCESS',
//         ad: ad,
//         PurchaseType: type
//     }
// }

// const getAdFailure = (message) => {

//     return {
//         type: 'GET_AD_FAILURE',
//         err: message
//     }
// }