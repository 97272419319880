const initialState = {
    show: false,
    modalType: "error",
    title: "",
    text: "null",
    img: undefined,
    showPaymentModal: false,
    selectedToken: null
}

export const popupModalReducer = (state = initialState, action) => {

    switch (action.type) {

        case "SET_MODAL":
            return {
                ...state,
                show: true,
                modalType: action.modalType,
                title: action.title,
                text: action.text,
                img: action.img
            }

        case "HIDE_MODAL":
            return {
                ...state,
                show: false
            }

        case "SHOW_PAYMENT_METHOD_MODAL":
            return {
                ...state,
                showPaymentModal: true
            }

        case "HIDE_PAYMENT_METHOD_MODAL":
            return {
                ...state,
                showPaymentModal: false
            }

        case "SELECT_TOKEN":
            return {
                ...state,
                selectedToken: action.token
            }
        default:
            return state;
    }
}