import ZESTY from '../api';

export function fetchPromotions(city, limitFlag, name, minAge, tags, lastEvalKey, storePromotions) {

    return function(dispatch) {
        dispatch(getPromotionsStarted());
        ZESTY.getPromotions(city, limitFlag, name, minAge, tags, lastEvalKey)
            .then(res => {
                if(res.hasOwnProperty("errorMessage")){
                    dispatch(getPromotionsFailure(res.errorMessage))
                } else {
                    if(limitFlag && !res.promotions.hasOwnProperty("LastEvaluatedKey")){
                        dispatch(getPromotionsSuccess(res.promotions.Items, storePromotions, false, res.promotions.LastEvaluatedKey, true));
                    } else {
                        dispatch(getPromotionsSuccess(res.promotions.Items, storePromotions, false, res.promotions.LastEvaluatedKey, false));
                    }
                }
            })
            .catch(err => {
                dispatch(getPromotionsFailure(err.message))
            })
    }
}

export function fetchSavedPromotions(advertiserIds, storePromotions) {

    return function(dispatch) {
        dispatch(getSavedPromotionsStarted());
        // ZESTY.getPromotionsByCity(city)
        ZESTY.getPromotionsByAdvertiserIds(advertiserIds)
            .then(res => {
                if(res.hasOwnProperty("errorMessage")){
                    dispatch(getPromotionsFailure(res.errorMessage))
                }else {
                    dispatch(getPromotionsSuccess(res.promotions.Items, storePromotions, true));
                }
            })
            .catch(err => {
                dispatch(getSavedPromotionsFailure(err.message))
            })
    }
}

export function postReport(adId, userId, reportType, reportMessage, city) {
    // console.log(index);
    return function(dispatch) {
        dispatch(postReportStarted());
        ZESTY.postReport(adId, userId, reportType, reportMessage, city)
            .then(res => {
                if(res.hasOwnProperty("errorMessage")){
                    dispatch(getPromotionsFailure(res.errorMessage))
                }else {
                    dispatch(postReportSuccess());
                }
            })
            .catch(err => {
                dispatch(postReportFail(err.message))
            })
    }
}

const getPromotionsStarted = () => {
    return {
        type: 'GET_PROMOTIONS'
    }
}

const getSavedPromotionsStarted = () => {
    return {
        type: 'GET_SAVED_PROMOTIONS'
    }
}

const getPromotionsSuccess = (promotions, storePromotions, liked, lastEvalKey, fetchedAll) => {
    // console.log("FETCHED PROMOTIONS ARE ", promotions)
    // console.log("STORE PROMOTIONS ", storePromotions)

    if (storePromotions === undefined) {
        storePromotions = []
    }

    let fetchedIds = promotions.map(promotion => promotion.PromotionId);
    // console.log("FETCHED IDS ARE", fetchedIds)

    storePromotions = storePromotions.filter(promotion => !fetchedIds.includes(promotion.PromotionId))
        // console.log("STORE PROMOTIONS AFTER FILTERING", storePromotions)

    let promotionsToAdd = storePromotions.concat(promotions)

    // console.log("COMBINED PROMOTIONS ARE ", promotionsToAdd)

    if (liked) {
        return {
            type: 'GET_SAVED_PROMOTIONS_SUCCESS',
            promotions: promotionsToAdd,
            lastEvalKey: lastEvalKey
        }
    } else {
        return {
            type: 'GET_PROMOTIONS_SUCCESS',
            promotions: promotionsToAdd,
            lastEvalKey: lastEvalKey,
            fetchedAll: fetchedAll
        }
    }
}

export const updatePromotion = (updatedPromotion, storePromotions) => {
    let index = storePromotions.findIndex(promotion => promotion.PromotionId === updatedPromotion.PromotionId)
    let newArr = storePromotions.slice(0, index);
    newArr.push(updatedPromotion);
    newArr = newArr.concat(storePromotions.slice(index + 1, storePromotions.length))

    return {
        type: 'UPDATE_PROMOTION_SUCCESS',
        promotions: newArr
    }

}

const getPromotionsFailure = (message) => {
    console.log(message)
    return {
        type: 'GET_PROMOTIONS_FAILURE',
        err: message
    }
}

const getSavedPromotionsFailure = (message) => {

    return {
        type: 'GET_SAVED_PROMOTIONS_FAILURE',
        err: message
    }
}

const postReportStarted = () => {
    // console.log("POST REPORT STARTED");
    return {
        type: 'POST_REPORT'
    }
}

const postReportFail = (message) => {
    return {
        type: 'POST_REPORT_FAIL',
        err: message
    }
}

const postReportSuccess = (index) => {
    return {
        type: 'POST_REPORT_SUCCESS'
    }
}

export const setFetchedLikes = (fetchedLikes) => {
    return {
        type: 'SET_FETCHED_LIKES',
        fetchedLikes: fetchedLikes
    }
}

// export const setTags = (tags) => {

//     return {
//         type: "SET_TAGS",
//         tags: tags
//     }
// }

// export const setNames = (names) => {

//     return {
//         type: "SET_NAMES",
//         names: names
//     }
// }