//external libraries
import React from 'react';
import { useDispatch } from 'react-redux';

//actions
import {setForm} from '../../actions/formActions'

//images
import closeIcon from '../../img_resources/Forms/close.svg'

const Terms = () => {
    const dispatch = useDispatch();
    return(
        <>
            <img src={closeIcon} style={{height:'13px',width:'13px'}} alt="close icon" className="close-form" onClick={()=> dispatch(setForm({},"Hide",""))}/>
            
            <p><strong>Get </strong><strong>Zestty </strong></p>
            <p><strong>User Terms of Service</strong></p>
            <div className="terms">
                <p>By using the Zestty Service, you agree to be bound by these User Terms of Service (&ldquo;<strong>Terms of Service</strong>&rdquo;).</p>
                <p>Please read the following Terms of Service carefully before using the Zestty Service. If you do not agree with the Terms of Service, please do not use the Zestty Service. These Terms of Service apply to any use of the Zestty Service and to the Purchase Agreements made hereunder. By accessing or using the Zestty Service, you confirm your agreement to be bound by these Terms of Service. These Terms of Service expressly supersede prior agreements or arrangements with you. A more detailed description of the Zestty Service and information on system requirements is available at <a href="https://getzestty.com/">GetZestty.com</a>.</p>
                <p>The Terms of Service are concluded between us and the User only, and not with Apple Inc., and Apple Inc. is not responsible for the Zestty App or the content thereof. You agree that Apple Inc., and Apple Inc.&rsquo;s subsidiaries (&ldquo;<strong>Apple</strong>&rdquo;), are third party beneficiaries of the Terms of Service, and that, upon your acceptance of the Terms of Service, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms of Service against you as a third party beneficiary thereof.</p>
                <ol>
                <li><strong> Definitions</strong></li>
                </ol>
                <p>&ldquo;<strong>Partner</strong>&rdquo; means a service partner who has signed a partner agreement with Zestty and who offers its products or services, if applicable, through the Zestty Service.</p>
                <p>&ldquo;<strong>Purchase Agreement</strong>&rdquo; means an agreement for the purchase of Partner&rsquo;s products and services made on an Order. With respect to Orders placed through any other payment method of the User, the Purchase Agreement forms a binding agreement between Partner and the User.</p>
                <p>&ldquo;<strong>Zestty</strong>&rdquo; means: <strong>Peaks Ventures Consulting Ltd.</strong>, 7 Shlonski St., 46767 Herzliya, Israel, if the User is located in Israel.</p>
                <p><strong>&nbsp;</strong>&ldquo;<strong>Zestty App</strong>&rdquo; means a digital application called Get Zestty provided by Zestty for natural and legal persons to order products from Zestty&rsquo;s Partners.</p>
                <p>&ldquo;<strong>Zestty Service</strong>&rdquo; means both Zestty App and/or Zestty website: <a href="https://getzestty.com/">GetZestty.com</a></p>
                <p>&ldquo;<strong>User</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo; means a natural person using the Zestty Service.</p>
                <ol start="2">
                <li><strong> Identity of Zestty</strong></li>
                </ol>
                <ul>
                <li><strong>Peaks Ventures Consulting Ltd.</strong>, 7 Shlonski St., 46767 Herzliya, Israel, if the User is located in Israel,</li>
                <li><strong>Trade name: </strong>&ldquo;Zestty",</li>
                <li><strong>Email address: </strong><a href="mailto:info@Zestty.com">info@Zestty.com</a>.</li>
                </ul>
                <p>*Please note that while the Zestty Service is legally provided to you by Zestty entity, the payment service is arranged by Zestty through a third-party payment service provider. This also means that Zestty is responsible for possible refunds, complaints and other claims related to your transactions in the Zestty Service. You may thus contact Zestty directly at the contact details provided above in case of any dispute or claim related to the Zestty Service.</p>
                <p>Information about the identity and products and services of the Partners will be provided through the Zestty App and/or Zestty website: <a href="https://getzestty.com/">GetZestty.com</a>.</p>
                <p>&nbsp;</p>
                <ol start="3">
                <li><strong> Description of the Zestty Service</strong></li>
                </ol>
                <p>3.1. Zestty provides a platform on which the User can purchase products and services from the Partner of his/her choice.</p>
                <p>3.2. The Partners provide the information about their products and services in the Zestty Service, including information on product prices.</p>
                <p>If you have allergies or other dietary restrictions, please do not purchase products that may contain such allergens as you will not be eligible for a refund. The Partner will provide food-specific information upon request. In cases of an order containing allergens, the issue will be dealt by the providing Zestty Partner on a case by case basis, as they see fit.</p>
                <p>In cases which are unclear to the user, please contact the relevant Partner prior to placing your order.</p>
                <p>The sale and purchase of the Partner&rsquo;s products and services may be subject to additional terms and conditions of the Partners as set out in the Zestty Service.</p>
                <p>When purchasing the Partner&rsquo;s products and services that the User wishes to purchase from the Partner, the User makes a binding order for the purchase of the products and services from the Partner on the terms and conditions presented to the User in the Zestty Service before placing the order (&ldquo;<strong>the</strong> <strong>Order</strong>&rdquo;). After making payment to Zestty, the User and the Partner enter into the Purchase Agreement. After having processed the Order, Zestty will generate a code for the User to be presented to the Zestty Partner upon arrival. Zestty will provide the User with a receipt on behalf of the Partner (unless regulation in the country where the User is located requires that the Partner provides the receipt directly to the User).</p>
                <p>3.3. The Partner selected by the User will provide the products set out in the Order to the User upon validating the code presented by the User. Zestty is not responsible or liable towards the User for the proper execution of the Purchase Agreement by the Partner.</p>
                <p>3.4. After the Order has been placed by the User, the Order cannot be cancelled by the User. You cannot withdraw or cancel an Order for products or services once you have placed it. Prior to placing an Order for a product or service you should carefully review your selection.</p>
                <ol start="4">
                <li><strong> User Accounts</strong></li>
                </ol>
                <p>4.1. You have a limited, non-exclusive, non-transferable, and non-sublicensable right to use the Zestty Service in the form offered to you by us from time to time solely for the purposes set out herein. The license granted to the User for the iOS Zestty App downloaded from Apple&rsquo;s App Store is further limited to a license to use the Zestty App on any Apple-branded products that the User owns or controls and as permitted by the Usage Rules set forth in the App Store Terms of Service and these Terms of Service.</p>
                <p>4.2. In order to use the Zestty Service, the User must create a user account by following the registration instructions in the Zestty Service. The Zestty Service credentials are personal. The User shall ensure that any user account credentials, and equivalent information required to access the user account of the User are kept confidential and used in a secure manner not accessible by third parties. A User may have only one personal user account.</p>
                <p>4.3. Where it is suspected that any unauthorized person has become aware of a User&rsquo;s user account credentials or has access to the user account of the User, the User shall immediately inform Zestty thereof. The User shall be responsible for any use of the Zestty Service and any activity under the user account of the User.</p>
                <p>4.4. In order to use the Zestty Service, you must submit valid credit card or other payment method information to Zestty. Zestty does not store information of your payment instrument, as this is done by a third-party payment service provider used by Zestty. You agree to pay for all purchases arising from your use of the Zestty Service. You must keep the credit card and other payment information that you have submitted to your user account updated.</p>
                <ol start="5">
                <li><strong> Payments</strong></li>
                </ol>
                <p>5.1. The User will pay the purchase price set out in the Order by using the relevant payment functionality of the Zestty Service. Zestty collects all payments from the Users on behalf of the Partner. The User&rsquo;s payment obligation shall arise upon placing an Order through the Zestty Service.</p>
                <p>5.2. The User may fulfil this payment obligation only by using the relevant online payment method provided in the Zestty Service. When the User has paid the purchase price to Zestty, the User has fulfilled its payment obligation towards the Partner.</p>
                <p>5.3. The payment will be automatically charged from the User&rsquo;s credit card, upon processing of the Order. Zestty is entitled to make an authorization hold on behalf of the Partner on the User&rsquo;s credit card once the Order has been placed through the Zestty Service. Zestty uses a third-party payment service provider for processing of payments.</p>
                <p>5.4. In Israel payments are processed by Zestty through third party payment service providers. Zestty is responsible for possible refunds, complaints and other claims related to your transactions in the Zestty Service.</p>
                <ol start="6">
                <li><strong> Zestty credits and tokens</strong></li>
                </ol>
                <p>6.1. The Users of the Zestty Service can obtain Zestty credits or tokens e.g. by inviting new users to sign up to the Zestty Service, as it may be. The User can use Zestty credits and tokens to get a discount on their purchases in the Zestty Service.</p>
                <p>6.2. Zestty may unilaterally determine the conditions applicable to the granting, use and validity of the Zestty credits and tokens.</p>
                <p>6.3. Any signing-up bonus credits or tokens can be used only once.</p>
                <p>6.4. Zestty credits and tokens cannot be exchanged to cash.</p>
                <p>6.5. The Zestty credits and tokens will be nullified if Zestty detects any abuse of Zestty credits or tokens or suspects or detects that Zestty credits or tokens have been granted on incorrect grounds. In such cases, Zestty shall invoice the amount that was paid using such Zestty credits or tokens.</p>
                <ol start="7">
                <li><strong> Collecting an Order at the Partner&rsquo;s point of sale</strong></li>
                </ol>
                <p>7.1. The product/s will be collected at the point of sale of the Partner the User has chosen in connection with the Order. To claim the products at the point of sale of the Partner, the User will receive a code from Zestty that will be validated by the Partner. The Partner or Zestty may set conditions for the identification of the User when collecting the products set out in the Order.</p>
                <p>7.2. The User shall retain the right for the purchased products for 3 days after the payment. However, this obligation is limited to the opening hours of the particular Partner&rsquo;s point of sale and the Order has to be collected before the closing time of said point of sale of the Partner.</p>
                <ol start="8">
                <li><strong> Time estimates</strong></li>
                </ol>
                <p>There is no guarantee that the Order will be available for collection at the exact time of arrival of the User to the Partner&rsquo;s point of sale. Availability of the Partner for providing the product/s may also be affected by factors such as space availability at the point of sale at certain times. The User may be subject to waiting lines prior to receiving the purchased product/s.</p>
                <ol start="9">
                <li><strong> Intellectual Property Rights</strong></li>
                </ol>
                <p>9.1. All Intellectual Property Rights in or related to the Zestty Service and thereto related documentation and all parts and copies thereof shall remain exclusively vested with and be the sole and exclusive property of Zestty and/or its subcontractors/licensors. &ldquo;<strong>Intellectual Property Rights</strong>&rdquo; shall mean copyrights and related rights (including database and catalogue rights and photography rights), patents, utility models, design rights, trademarks, tradenames, trade secrets, know-how and any other form of registered or unregistered intellectual property rights.</p>
                <p>9.2. These Terms of Service do not grant the User any Intellectual Property Rights in the Zestty Service and all rights not expressly granted hereunder are reserved by Zestty and its subcontractors/licensors.</p>
                <p>9.3. Apple shall not be responsible for any investigation, defense, settlement or discharge of any claim that the iOS Zestty App or your use of it infringes any third-party intellectual property right.</p>
                <ol start="10">
                <li><strong> Additional Provisions for use of the Zestty Service</strong></li>
                </ol>
                <p>10.1. The Zestty Service is only available to persons of the age of 18 years or older.</p>
                <p>10.2. The User shall observe all applicable rules and regulations when using the Zestty Service, including the purchase of alcohol and tobacco. To the extent &nbsp;consumption of alcohol is available in the country where the User is located, the User may be refused&nbsp; alcoholic beverages in case he/she is unable to provide a valid photo ID to Zestty&rsquo;s Partner&nbsp; at the point of sale proving that the User is of legal age in the country which the product/s and/or service/s are offered. The User may be refused serving of alcoholic beverages also in other cases stipulated by laws in the country where the User is located (e.g. if the User is showing signs of intoxication).</p>
                <p>10.3. Zestty is constantly developing the Zestty Service and Zestty may change or remove different parts of the Zestty Service, including features, the products and Partners available in the Zestty Service in part or in whole.</p>
                <p>10.4. By using the Zestty Service, the User may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Zestty generally does not review content provided by the Partners. Zestty is not responsible for third parties' (including the Partners') content or information or for any damages arising as a result of the use of or reliance on it.</p>
                <p>10.5. You shall be responsible for obtaining and maintaining any devices or equipment (such as cellular phones) and connections needed for access to and use of the Zestty Service and all charges related thereto.</p>
                <p>10.6. The User will not: (i) use or attempt to use another person&rsquo;s personal Zestty account and/or access another person&rsquo;s personal payment data through the Zestty Service or use another person&rsquo;s personal payment cards when using the Zestty Service, without consent of that other person; (ii) copy, modify or create derivative works of the Zestty Service or any related technology; (iii) reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Zestty Service or any related technology, or any part thereof; (iv) remove any copyright, trademark or other proprietary rights notices contained in or on the Zestty Service; (v) remove, cover or obscure any advertisement included on the Zestty Service; (vi) collect, use, copy, or transfer any information obtained from the Zestty Service without the consent of Zestty; (vii) use bots or other automated methods to use the Zestty Service; (viii) create a Zestty account using a fake identity or an identity of another person; (ix) use a Zestty account using incorrect information, including personal or payment information or (x) access the Zestty Service except through the interfaces expressly provided by Zestty, such as the Zestty App and Zestty Website.</p>
                <p>10.7. Zestty is entitled to remove a User from the Zestty Service with immediate effect and/or refuse or cancel any Orders from a User if: (i) the User abuses the Zestty Service or causes any harm or detriment to the use of the Zestty Service or the Partners or Zestty or Zestty&rsquo;s employees, (ii) Zestty has reasonable belief of fraudulent acts by the User when using the Zestty Service, (iii) the User places a false Order (for example by not paying or by presenting a false code at the Partner&rsquo;s point of sale in order to receive the Order) or otherwise fails to comply with his obligations arising from these Terms of Service; (iv) there is reasonable doubt about the correctness or authenticity of the Order. If Zestty cancels an Order that has already been paid for, Zestty shall transfer that amount into the same user account as the one from which the payment was made by the User.</p>
                <p>10.8. The controller of the personal data collected about Users is Zestty, unless otherwise indicated. Zestty shall process any personal data collected from the User in accordance with <a href="#privacy-statement">Zestty Privacy Statement</a>.</p>
                <p>10.9. The User must comply with applicable third-party terms of agreement when using the Zestty App or the Zestty Service.</p>
                <p>10.10. The User represents and warrants that (i) he/she is not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &ldquo;terrorist supporting&rdquo; country; and (ii) he/she is not listed on any U.S. Government list of prohibited or restricted parties.</p>
                <ol start="11">
                <li><strong> Zestty Non-Discrimination Policy</strong></li>
                </ol>
                <p>Zestty seeks to provide safe, reliable, and high-quality service options to everyone. Zestty and its affiliates therefore prohibit discrimination against Users based on race, religion, national origin, disability, sexual orientation, sex, marital status, gender identity or any other characteristic protected under applicable law. Such discrimination includes, but is not limited to, any User refused to services based on any of these characteristics. Any Partner found to have violated this prohibition will lose access to the Zestty platform. Applicable laws in certain jurisdictions may require and/or allow the provision of services by and for the benefit of a specific category of persons. In such jurisdictions, services provided in compliance with these laws and the relevant applicable terms are permissible under this policy.</p>
                <p><strong>&nbsp;</strong></p>
                <ol start="12">
                <li><strong> Term and termination</strong></li>
                </ol>
                <p>12.1. These Terms of Service are in force as a binding agreement between Zestty and the User until further notice as long as the User is using the Zestty Service.</p>
                <p>12.2. The User can discontinue the use of the Zestty Service at any time.</p>
                <p>12.3 Zestty can discontinue providing the Zestty Service permanently or temporarily at any time.</p>
                <ol start="12">
                <li><strong> Complaints</strong></li>
                </ol>
                <p>12.1. Please note that the Zestty Service may at any time be interrupted or permanently discontinued. The Zestty Service may also be temporarily suspended. Do not use the Zestty Service for backing up any data.</p>
                <p>12.2. The Partner is committed to prepare the Order in accordance with the features ordered by the User and within the time shown on the Zestty Service. If there are any defects in the Order, the User may contact either the customer service of Zestty, acting on behalf of Partner, through the in-app customer support chat or at info@GetZestty.com or the Partner directly.</p>
                <p>12.3. The Partner is solely liable for any defects in the contents and preparation of the Order or other shortcomings in the performance of the Purchase Agreement.</p>
                <p>12.4. The User who has discovered shortcomings in the performance of the Purchase Agreement must submit any complaints to Zestty or the Partner without delay, with clear descriptions of said shortcomings.</p>
                <p>12.5. Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Zestty App. To the extent that any maintenance or support is required by applicable law, we, not Apple, shall be obligated to furnish any such maintenance or support. To the extent any warranty exists under law that cannot be disclaimed, we, not Apple, shall be solely responsible for such warranty. We, not Apple, are responsible for addressing any claims of the User or any third party relating to the iOS Zestty App or the User&rsquo;s possession and/or use of the iOS Zestty App, including, but not limited to: (i) product liability claims; (ii) any claim that the iOS Zestty App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection, privacy, or similar legislation, including in connection with the iOS Zestty App&rsquo;s use of the HealthKit and HomeKit frameworks.</p>
                <ol start="13">
                <li><strong> Applicable Law and Dispute Resolution</strong></li>
                </ol>
                <p>13.1. These Terms of Service shall be governed by and construed in accordance with the laws of Israel.</p>
                <p>13.2. If you are a consumer, please note that you cannot be deprived of the rights granted to you by the mandatory consumer protection laws of your country of domicile.</p>
                <p>13.3. Disputes arising from these Terms of Service shall be resolved by the district court of Tel-Aviv. You may also refer a dispute to the consumer disputes board or other corresponding out-of-court institution in Israel.</p>
                <ol start="14">
                <li><strong> Amendments</strong></li>
                </ol>
                <p>14.1. These User Terms of Service are subject to amendments.</p>
                <p>14.2. Zestty shall publish the amended User Terms of Service at the Zestty Website and shall inform the User that the Zestty Terms of Service have been amended on the Zestty Service or by email to the email address submitted to the Zestty Service by the User.</p>
                <p>14.3 Amendments will be effective upon Zestty's posting of such updated Terms of Service on the applicable Service(s).</p>
                <p>14.4 If the User does not agree to any amended Zestty Terms of Service, he/she shall discontinue the use of the Zestty Service.</p>
                <ol start="15">
                <li><strong> Supplemental terms</strong></li>
                </ol>
                <p>Supplemental terms may apply to certain Zestty Services, such as policies for a particular event, program, activity or promotion, and such supplemental terms will be disclosed to you in separate and specific disclosures or in connection with the applicable Service(s). Supplemental terms are in addition to, and shall be deemed a part of, the Zestty Terms of Service for the purposes of the applicable Zestty Services. Supplemental terms shall prevail over these Zestty Terms in the event of a conflict with respect to the applicable Zestty Services.</p>
                <p>&nbsp;</p>
                <ol start="15">
                <li><strong> Assignment</strong></li>
                </ol>
                <p>15.1. Zestty shall be entitled to assign all or any of its rights or obligations hereunder in whole or part to an affiliate or successor or to a purchaser or acquirer of its business assets relating to Zestty Service without the User&rsquo;s prior consent.</p>
                <p>15.2. The User shall not be entitled to assign any of its rights or obligations hereunder in whole or part.</p>
                <p>*****</p>
                <p>Updated 8.5.2020</p>
                <p id="privacy-statement"><strong>ZESTTY PRIVACY STATEMENT</strong></p>
                <p>Zestty processes personal data of their Users of Zestty&rsquo;s services (&ldquo;Zestty App&rdquo;) and the visitors of the website <a href="https://getzestty.com/">GetZestty.com</a> (&ldquo;Website&rdquo;).</p>
                <p>In this Privacy Statement, the word &ldquo;Zestty Services&rdquo; refers jointly to the Website and the Zestty App. In this Privacy Statement, the word &ldquo;User&rdquo; or &ldquo;you&rdquo; refers jointly to our and our group companies&rsquo; customers, representatives and other authorized users of our customer organizations, potential customers and the users of the Zestty Services.</p>
                <p>Our Privacy Statement explains, for example, the types of personal data we process, how we process the personal data and how you may exercise your rights as a data subject (e.g. right to object, right of access).</p>
                <p>Some of our services might be subject to a separate privacy policy. If a separate privacy policy applies to a particular service, we will post it in connection with the service in question.</p>
                <p>This Privacy Statement may be updated from time to time in order to reflect the changes in data processing practices or otherwise. You can find the current version on the Website. We will not make substantial changes to this Privacy Statement or reduce the rights of the Users under this Privacy Statement without providing a notice thereof.</p>
                <ol>
                <li><strong> DATA CONTROLLER(S)</strong></li>
                </ol>
                <p>This Privacy Statement applies to processing of personal data carried out by Zestty.</p>
                <p>In relation to the processing of personal data of Users in Israel, Zestty is acting as a data controller. This means that Zestty determines the purposes for and means by which personal data is processed.&nbsp; For Users in Israel, please note that the term &ldquo;controller&rdquo;, as used in this Privacy Statement, means the &ldquo;database owner&rdquo; as used in the Israeli Protection of Privacy Law, 5741-1981</p>
                <p>&nbsp;</p>
                <p>Zestty has been responsible for handling all data subject requests and questions relating to the personal data processing of Zestty.</p>
                <ol start="2">
                <li><strong> ZESTTY&rsquo;S CONTACT DETAILS</strong></li>
                </ol>
                <p>Name: Peaks Ventures Consulting Ltd.</p>
                <p>Business ID: &nbsp;514446640</p>
                <p>Correspondence address: 7 Shlonski St., Herzliya, 46767, Israel</p>
                <p>E-mail address: info@GetZestty.com</p>
                <p>Website: GetZestty.com</p>
                <p>Contact person: Mira Peled-Kamar</p>
                <ol start="3">
                <li><strong> PERSONAL DATA PROCESSED AND SOURCES OF DATA</strong></li>
                </ol>
                <p>The personal data collected and processed by us can be divided into two general data categories: User Data and Analytics Data.</p>
                <p><u>User Data</u></p>
                <p>User Data is personal data collected directly from you. We may collect User Data from our Users in a variety of ways, including, when Users register to the Zestty Services, subscribe to a newsletter or fill out a form. Further, please note that we also collect details of any transactions and payments you carry out through the Zestty Services.</p>
                <p><em>User Data that is necessary in order to use the Zestty Services</em></p>
                <p>The following personal data collected and processed by us is necessary in order for a proper performance of the contract between you and us as well as for our legitimate interest whilst fulfilling our contractual obligations towards our Users and for the compliance with our legal obligations.</p>
                <p>When you register to the Zestty Services and create a user account, you need to provide us with the following information:</p>
                <ul>
                <li>full name</li>
                <li>email address</li>
                <li>information relating to your payment instrument(s) such as the number of your payment instrument(s) and the expiration date of your payment instrument(s) (required for the purposes of ordering Products via the Zestty Services, however not stored by Zestty, since Zestty uses a third party payment service provider for processing of payments)</li>
                </ul>
                <p><em>User Data you give us voluntarily</em></p>
                <p>Your user or customer experience may be enhanced by providing us with the following information:</p>
                <p><strong>Additional Account Information:</strong></p>
                <ul>
                <li>a picture</li>
                <li>age</li>
                <li>location data (if you consent to the processing of your location data)</li>
                <li>other information you provide either when creating a user account or later when modifying your account profile</li>
                </ul>
                <p><strong>Other Information </strong></p>
                <p><strong>We may also process other information provided by you voluntarily such as:</strong></p>
                <ul>
                <li>information you provide when rating our services</li>
                <li>marketing opt-ins and opt-outs</li>
                <li>information you provide by phone or in email or chat correspondence with us, including call recordings of your calls with our customer service</li>
                </ul>
                <p>In addition to User Data collected from you, we process certain personal data third party service providers provide about you.</p>
                <p>If you connect or login to your account with Facebook, Facebook shares with us personal information about you such as your profile picture, a sample of your Facebook friends and your Facebook ID.</p>
                <p>If you connect or login to your account with Google, Google shares with us personal information about you such as your profile picture, your age and your email address.</p>
                <p>&nbsp;</p>
                <p><u>Analytics Data</u></p>
                <p>Although we do not normally use Analytics Data to identify you as an individual, you can sometimes be recognized from it, either alone or when combined or linked with User Data. In such situations, Analytics Data can also be considered personal data under applicable laws and we will treat such data as personal data.</p>
                <p>We may automatically collect the following Analytics Data when you visit or interact with the Zestty Services:</p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>Device Information</strong></p>
                <p><strong>We collect the following information relating to the technical device you use when using the Zestty Services:</strong></p>
                <ul>
                <li>device and device identification number, device IMEI</li>
                <li>country</li>
                <li>IP address</li>
                <li>browser type and version</li>
                <li>operating system</li>
                <li>Internet service providers</li>
                <li>advertising identifier of your device</li>
                <li>visitor identifier</li>
                </ul>
                <p><strong>Usage Information </strong></p>
                <p><strong>We collect information on your use of the Zestty Services, such as:</strong></p>
                <ul>
                <li>time spent on the Zestty Services</li>
                <li>interaction with the Zestty Services</li>
                <li>information on your purchases made through the Zestty Services</li>
                <li>the URL of the website you visited before and after visiting the Zestty Services</li>
                <li>the time and date of your visits to the Zestty Services</li>
                <li>the sections of the Zestty Services you visited</li>
                <li>the products you searched for while using the Zestty Services</li>
                </ul>
                <p><u>Cookies</u></p>
                <p>We use various technologies to collect and store Analytics Data and other information when the Users visit the Zestty Services, including cookies and web beacons.</p>
                <p>Cookies are small text files sent and saved on your device that allows us to identify visitors of the Zestty Services and facilitate the use of the Zestty Services and to create aggregate information of our visitors. This helps us to improve the Zestty Services and better serve our Users. The cookies will not harm your device or files. We use cookies to tailor the Zestty Services and the information we provide in accordance with the individual interests of our Users.</p>
                <p>The Users may choose to set their web browser to refuse cookies, or to alert when cookies are being sent. For example, the following links provide information on how to adjust the cookie settings on some popular browsers:</p>
                <p><a href="https://support.apple.com/kb/PH19214?viewlocale=en_US&amp;locale=en_US">Safari</a></p>
                <p><a href="https://support.google.com/chrome/answer/95647?hl=en&amp;p=cpn_cookies">Google Chrome</a></p>
                <p><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">Internet Explorer</a></p>
                <p><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></p>
                <p>Please note that some parts of the Zestty Services may not function properly if use of cookies is refused.</p>
                <p>A web beacon is a technology that allows identifying readers of websites and emails e.g. for identifying whether an email has been read.</p>
                <p><u>Web analytics services</u></p>
                <p>The Zestty Services use Google Analytics and other web analytics services to compile Analytics Data and reports on visitor usage and to help us improve the Zestty Services. For an overview of <a href="http://www.google.com/analytics/#?modal_active=none">Google Analytics</a>, please visit Google Analytics. It is possible to opt-out of Google Analytics with the following browser add-on tool: <a href="https://support.google.com/analytics/answer/181881?hl=en&amp;ref_topic=2919631">Google Analytics opt-out add-on</a>.</p>
                <p><u>Advertising and visitor identifiers</u></p>
                <p>The Zestty Services use pseudonymized identifiers to track and predict your app and service usage and preferences.</p>
                <p>You can opt-out of the use of these identifiers by changing your operating system settings as follows:</p>
                <p>Advertising identifiers can be disabled on iOS mobile devices by turning on the Limit Ad Tracking tab (Settings &rarr; Privacy &rarr; Advertising &rarr; Limit Ad Tracking). For an overview and more information on the advertising identifier, please see <a href="https://support.apple.com/en-us/HT205223">Apple Advertising and Privacy site</a>.</p>
                <ol start="4">
                <li><strong> PURPOSES AND LEGITIMATE GROUNDS FOR THE PROCESSING OF YOUR PERSONAL DATA</strong></li>
                </ol>
                <p><u>Purposes</u></p>
                <p>There are several purposes of the processing of your personal data by Zestty:</p>
                <p><em>To provide the Zestty Services and carry out our contractual obligations (legal ground: performance of a contract and legitimate interest)</em></p>
                <p>Zestty processes your personal data to be able to offer the Zestty Services to you under the contract between you and Zestty.</p>
                <p>We use the data for example to handle your payments or any refunds (where applicable) and to provide our Partners with the information necessary for the claim of your Purchase. If you contact our customer service, we will use the information provided by you to answer your questions or solve your complaint.</p>
                <p><em>For our legal obligations (legal ground: compliance with a legal obligation)</em></p>
                <p>Zestty processes data to enable us to administer and fulfil our obligations under law. This includes data processed for complying with our bookkeeping obligations and providing information to relevant authorities such as tax authorities.</p>
                <p><em>For claims handling and legal processes (legal ground: legitimate interest)</em></p>
                <p>Zestty may process personal data in relation to claims handling, debt collection and legal processes. We may also process data for the prevention of fraud, misuse of our services and for data, system and network security.</p>
                <p><em>For customer communication and marketing (legal ground: legitimate interest)</em></p>
                <p>Zestty processes your personal data to contact you regarding the Zestty Services and to inform you of changes relating to them. Your personal data are also used for the purposes of marketing the Zestty Services to you.</p>
                <p><em>For quality improvement and trend analysis (legal ground: legitimate interest)</em></p>
                <p>We may also process information about your use of the Zestty Services to improve the quality of the Zestty Services e.g. by analyzing any trends in the use of the Zestty Services. In order to ensure that our services are in line with your needs, personal data can be used for things like customer satisfaction surveys. When possible, we will do this using only aggregated, non-personally identifiable data.</p>
                <p><u>Legal grounds for processing</u></p>
                <p>Zestty processes your personal data to perform our contractual obligations towards you and to comply with legal obligations. Furthermore, we process your personal data to pursue our legitimate interest to run, maintain and develop our business and to create and maintain customer relationships. When choosing to use your data on the basis of our legitimate interests, we weigh our own interests against your right to privacy and e.g. provide you with easy to use opt-out from our marketing communications and use pseudonymized or non-personally identifiable data when possible.</p>
                <p>In some parts of the Zestty Services, you may be requested to grant your consent for the processing of personal data. In this event, you may withdraw your consent at any time.</p>
                <p><em>For Users located in Israel: </em>By entering, connecting to, accessing or using the Zestty Services, you agree and consent to the terms and conditions set forth in this Privacy Statement, including to the collection, processing, transfer and use of your personal data which is collected as part of the Zestty Services. If you disagree to any term provided herein, you may not access or use the Zestty Services. You also acknowledge and confirm that you are not required to provide us with your personal data and that such information is voluntarily provided to us.</p>
                <ol start="5">
                <li><strong> TRANSFER TO COUNTRIES OUTSIDE OF ISRAEL</strong></li>
                </ol>
                <p>Zestty stores your personal data primarily within Israel. However, we have service providers and operations in several geographical locations. As such, we and our service providers may transfer your personal data to, or access it in, jurisdictions outside of Israel or the User&rsquo;s domicile.</p>
                <p>We will take steps to ensure that the Users&rsquo; personal data receives an adequate level of protection in the jurisdictions in which they are processed. We provide adequate protection for the transfers of personal data to other countries through a series of agreements with our service providers based on the required relevant laws and regulations More information regarding the transfers of personal data may be obtained by contacting us on any of the addresses indicated above.</p>
                <ol start="6">
                <li><strong> RECIPIENTS</strong></li>
                </ol>
                <p>We only share your personal data within the organization of Zestty if and as far as reasonably necessary for the purposes of this Privacy Statement.</p>
                <p>We do not share your personal data with third parties outside of Zestty&rsquo;s organization unless one of the following circumstances applies:</p>
                <p><em>For the purposes set out in this Privacy Statement and to authorized service providers</em></p>
                <p>To the extent that third parties need access to personal data in order for us to perform the Zestty Services, we provide such third parties with your data. Furthermore, we may provide your personal data to our affiliates or to authorized service providers who perform services for us (including data storage, accounting, sales and marketing) to process it for us and to payment service providers to process your payments to us.</p>
                <p>When data is processed by third parties on behalf of Zestty, Zestty has taken the appropriate contractual and organizational measures to ensure that your data are processed exclusively for the purposes specified in this Privacy Statement and in accordance with all applicable laws and regulations and subject to our instructions and appropriate obligations of confidentiality and security measures.</p>
                <p>Please bear in mind that if you provide personal data directly to a third party, such as through a link in the Zestty Services, the processing is typically based on their policies and standards.</p>
                <p><em>For legal reasons and legal processes</em></p>
                <p>We may share your personal data with third parties outside Zestty if we have a good-faith belief that access to and use of the personal data is reasonably necessary to: (i) meet any applicable law, regulation, and/or court order; (ii) detect, prevent, or otherwise address fraud, crime, security or technical issues; and/or (iii) protect the interests, properties or safety of Zestty, the Users or the public as far as in accordance with the law. When possible, we will inform you about such processing.</p>
                <p><em>For other legitimate reasons</em></p>
                <p>If Zestty is involved in a merger, acquisition or asset sale, we may transfer your personal data to the third party involved. However, we will continue to ensure the confidentiality of all personal data. We will give notice to all the Users concerned when the personal data are transferred or become subject to a different privacy statement.</p>
                <p><em>With your explicit consent</em></p>
                <p>We may share your personal data with third parties outside Zestty when we have your explicit consent to do so. You have the right to withdraw this consent at all times free of charge by contacting us.</p>
                <ol start="7">
                <li><strong> STORAGE PERIOD</strong></li>
                </ol>
                <p>Zestty does not store your personal data longer than is legally permitted and necessary for the purposes of providing the Zestty Services or the relevant parts thereof. The storage period depends on the nature of the information and on the purposes of processing. The maximum period may therefore vary per use.</p>
                <p>Most personal data relating to a User&rsquo;s user account with the Zestty Services will be deleted after a period of 90 days have lapsed after the User has deleted its user account with the Zestty Services. Thereafter, a part of the personal data relating to a User&rsquo;s user account with the Zestty Services may be stored only as long as such processing is required by law or is reasonably necessary for our legal obligations or legitimate interests such as claims handling, bookkeeping, internal reporting and reconciliation purposes. All personal data relating to a User&rsquo;s user account with the Zestty Services will be deleted within a period of 10 years after the User has deleted its user account with the Zestty Services, with the exception of personal data required in certain rare situations such as legal proceedings.</p>
                <p>We will store Analytics Data of Users that do not have an account with the Zestty Services for a period of 90 days.</p>
                <ol start="8">
                <li><strong> YOUR RIGHTS</strong></li>
                </ol>
                <p><em>Right of access</em></p>
                <p>You have the right to access and be informed about your personal data processed by us. We give you the possibility to view certain data through your user account with the Zestty Services or request a copy of your personal data.</p>
                <p><em>Right to withdraw consent</em></p>
                <p>In case the processing is based on a consent granted by the User, the User may withdraw the consent at any time free of charge. Withdrawing a consent may lead to fewer possibilities to use the Zestty Services. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal.</p>
                <p><em>Right to rectify</em></p>
                <p>You have the right to have incorrect or incomplete personal data we have stored about you corrected or completed by contacting us. You can correct or update some of your personal data through your user account in the Zestty Services.</p>
                <p><em>Right to erasure</em></p>
                <p>You may also ask us to delete your personal data from our systems. We will comply with such request unless we have a legitimate ground to not delete the data.</p>
                <p><em>Right to object</em></p>
                <p>You may have the right to object to certain use of your personal data if such data are processed for other purposes than necessary for the performance of the Zestty Services or for compliance with a legal obligation. If you object to the further processing of your personal data, this may lead to fewer possibilities to use the Zestty Services.</p>
                <p><em>Right to restriction of processing</em></p>
                <p>You may request us to restrict processing of personal data for example when your data erasure, rectification or objection requests are pending and/or when we do not have legitimate grounds to process your data. This may however lead to fewer possibilities to use the Zestty Services.</p>
                <p><em>Right to data portability</em></p>
                <p>You have the right to receive the personal data you have provided to us yourself in a structured and commonly used format and to independently transmit those data to a third party.</p>
                <p><em>How to use your rights</em></p>
                <p>The abovementioned rights may be used by sending a letter or an e-mail to us on the addresses set out above, including the following information: the full name, address, e-mail address and a phone number. We may request the provision of additional information necessary to confirm the identity of the User. We may reject or charge requests that are unreasonably repetitive, excessive or manifestly unfounded.</p>
                <ol start="9">
                <li><strong> DIRECT MARKETING</strong></li>
                </ol>
                <p>The User has the right to prohibit us from using the User&rsquo;s personal data for direct marketing purposes, market research and profiling made for direct marketing purposes by contacting us on the addresses indicated above or by using the functionalities of the Zestty Services or the unsubscribe possibility offered in connection with any direct marketing messages.</p>
                <ol start="10">
                <li><strong> LODGING A COMPLAINT</strong></li>
                </ol>
                <p>In case the User considers our processing of personal data to be inconsistent with the applicable data protection laws, the User may lodge a complaint with the local supervisory authority for data protection in Israel.</p>
                <ol start="11">
                <li><strong> INFORMATION SECURITY</strong></li>
                </ol>
                <p>We use administrative, organizational, technical, and physical safeguards to protect the personal data we collect and process. We regularly test the Zestty Services, systems, and other assets for security vulnerabilities.</p>
                <p>Should despite of the security measures, a security breach occur that is likely to have negative effects to the privacy of the Users, we will inform the relevant Users and other affected parties, as well as relevant authorities when required by applicable data protection laws, about the breach as soon as possible.</p>
                <p>&nbsp;</p>
            </div>
        </>
        
    )
}

export default Terms;