import React, {useState, useEffect } from 'react';

//components
import DiscoverAd from './Ad Blocks/DiscoverAd';

import UI from '../ui_functions'

const SelectedDiscoverRow = ({tag, promotions, removeSelectedTag}) => {

    const [promotionsToRender, setPromotionsToRender] = useState([]);

    //filters promotions based on tags
    useEffect(()=>{
        let arr = UI.filterPromotionsByTag(promotions, tag);
        setPromotionsToRender(arr)
    },[promotions])

    

    return(
        <div className="discover-row">
            <div className="flex-row space-between" style={{width:'calc(100vw - 60px)'}}>
                <h2 className="discover-row-tag">#{tag}</h2>
                <div className="discover-back" onClick={()=>removeSelectedTag()}>Back</div>
            </div>
            
            <div className="discover-row-ads-selected">
                {promotionsToRender.length>0?
                promotionsToRender.map(promotion=>
                    <DiscoverAd key={promotion.PromotionId} promotion={promotion} isTagActive={true}/>
                    ):
                    <div></div>}
            </div>
        </div>
        

    )
}


export default SelectedDiscoverRow;