import React, { useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Fade} from 'react-reveal';

import ZESTTY from '../../api/index'

//actions
import {setForm} from '../../actions/formActions'
import {getUserData} from '../../actions/userActions';

import Loader from '../Loader'

const NewPaymentMethod = ({promotion}) => {

    const dispatch = useDispatch();

    const userId = useSelector(store=>store.user.user.username);

    const iframeRef = useRef();

    const [zCreditUrl, setZCreditUrl] = useState("");

    useEffect(()=>{
        ZESTTY.createTokenSession(userId)
            .then(res => setZCreditUrl(res))
            .catch(err => alert(err))
    },[])

    return(
        <>
            
            {
                <Fade bottom>
                    {
                        zCreditUrl===""?
                        <Loader/>:
                        <div className="iframe-wrapper">
                            <iframe 
                                title="payment-form" 
                                id="payment-iframe"
                                src={zCreditUrl}
                                ref={iframeRef}
                                frameBorder="0"
                                scrolling="no"
                                className="payment-iframe"
                                height="auto"
                                width="100vw"
                                onLoad={()=>{
                                    try {
                                        if(iframeRef.current.contentWindow.location.href.includes("/Success")){
                                            dispatch(getUserData(userId))
                                            setTimeout(() => {
                                                if(!!promotion.PromotionId){
                                                    dispatch(setForm(promotion,"PURCHASE", "Purchasing"))
                                                }
                                            }, 1500)
                                        }    
                                    } catch (error) {
                                        console.log(error)
                                    }
                                    
                                }}
                                >
                            </iframe>
                        </div>
                    }
                    
                </Fade>
                // <></>
            }
        </>
    )
}

export default NewPaymentMethod;