// /* global google */
import React from 'react'
import Select from 'react-select'

const createShareLink = (promotion, userId) =>{
    let shareLink = "";
    try{
        shareLink = "https://www.getzestty.com/redirect?path=Promotion&value="+ promotion.PromotionId + "&refId=" + userId+"#" + promotion.PromotionId;
        // shareLink = "https://www.getzestty.com/Promotion/"+ promotion.PromotionId + "?refId=" + userId+"#" + promotion.PromotionId;
    } catch (err){ //getzestty://Advertiser/AdvertiserID?PromotionID=dfsafda&userId=safdaf
    }
    return shareLink;
}

const createShareText = (promotion) => {
    let text="";
    try{
        text = "Check it out! \n"+ promotion.Name +" is offering "+promotion.Promotion + " for only " + promotion.Price;
    } catch (err){

    }
    return text;
}

//calculates the amount of money a user has saved
const calculateAmountSaved = (originalPrice, reducedPrice, usedZcredits, zCreditsAmount) =>{
    let amountSaved = 0;
    if(usedZcredits){
        if(zCreditsAmount >= reducedPrice){
            amountSaved = originalPrice;
        } else {
            amountSaved = zCreditsAmount;
            amountSaved += originalPrice - reducedPrice;
        }
    } else {
        amountSaved = originalPrice - reducedPrice
    }

    return amountSaved;
}

//checks the amount of promotions we have with a tag, and the tag counter from the DB.
//if we have less promotions than the tag count we will return false
const checkFetchPromotionsBasedOnTags = (promotions, tags, limit) => {
    let count = 0;
    count = promotions.filter(promotion=> checkTags(promotion.Tags, tags)).length;
    console.log("RETURNED COUNT IS", count)
    if(count<limit){
        return true // we need to fetch
    } else {
        return false // we dont need to fetch
    }
}

//checks if we have a promotion of an advertiser based on advertiser name
const checkFetchPromotionBasedOnName = (promotions, name) => {
    let promotion = {};
    promotion = promotions.filter(promotion => name.value === promotion.Name);
    if (promotion.hasOwnProperty("Active")){
        return false
    } else {
        return true
    }
}

//checks whether a promotion includes a list of tags
const checkTags = (promotionTags, tagsList) => tagsList.every(tag => promotionTags.includes(tag));

// returns a list of promotions after filtering out the ones that don't meet the filters requirements
// ADD CITY FILTER
const runFilters = (promotions, tagFilter, ageFilter, nameFilter, liveFilter, cityFilter) => {
    let promotionsToRender =[];
    try{
        promotionsToRender = promotions.filter(promotion => promotion.Active);

        if (tagFilter.active) {
            promotionsToRender = promotionsToRender.filter(promotion => checkTags(promotion.Tags, tagFilter.value));
        }
    
        if (ageFilter.active) {
            promotionsToRender = promotionsToRender.filter(promotion => ageFilter.value <= parseInt(promotion.Age));
        }
    
        if (nameFilter.active) {
            promotionsToRender = promotionsToRender.filter(promotion => nameFilter.value === promotion.Name);
        }
    
        if (liveFilter.active) {
            promotionsToRender = promotionsToRender.filter(promotion => promotion.Live);
        }
        if (cityFilter!==""){
            promotionsToRender = promotionsToRender.filter(promotion => cityFilter === promotion.AppName);
        }

    } catch (err){
        console.log(err)
    }

    return promotionsToRender;
}

//any promotion that is out of stock is filtered
const filterOutOfStockAds = (promotions) => {
    let adsToRender = promotions.filter(promotion => parseInt(promotion.Quantity) !== 0);

    return adsToRender;
}

//sleeps for ms amount of time
const sleep = async(ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//returns the promotions that are in the list of liked advertisers
const getLiked = (promotions, likes) => {
    promotions = promotions.filter(promotion => likes.includes(promotion.AdvertiserId));
    return promotions;
}


const getPurchased = (promotions) => {
    promotions = promotions.filter(promotion => true === promotion.isPurchased);
    return promotions;
}


const getTagsFromPromotions = (promotions) => {
    let tagsCount = {}; //this counts the amount of times a tag appeared

    promotions.forEach(promotion => {
        promotion.Tags.forEach(tag => {
            tagsCount.hasOwnProperty(tag) ?
                tagsCount[tag] += 1 :
                tagsCount[tag] = 1;
        });
    })

    // let tagsByOrder = Object.keys(tagsCount);
    // tagsByOrder.sort(function(a, b) { return tagsCount[b] - tagsCount[a] });
    let tagsByOrder = orderTagsByCount(tagsCount);
    return tagsByOrder;
}

//gets the promotions with a tag matching the selected tag
const filterPromotionsByTag = (promotions, tag) =>{
    let arr = promotions.filter(promotion=> promotion.Tags.includes(tag))

    // promotions.forEach(promotion=>{
    //     promotion.Tags.forEach(itm =>{
            
    //         if(itm===tag){
    //             arr.push(promotion);
    //         }
    //     });
    // });

    return arr
}

const orderTagsByCount = (tagsCount) => {
    let tagsByOrder = Object.keys(tagsCount);
    tagsByOrder.sort(function(a, b) { 
        return tagsCount[b] - tagsCount[a] 
    });
    return tagsByOrder;
}

const getNamesFromPromotions = (promotions) => {
    let names = [];
    promotions = promotions.filter(promotion => promotion.Active);
    promotions.forEach(promotion => {
        if(!names.includes(promotion.Name)){
            names.push(promotion.Name)
        }
    });
    return names.sort();
}

const getTimeLeft = (time) => {
    let startTime = new Date(time);
    let endHour = startTime.getHours();
    let timeLabel = " AM"
    if(endHour>12){
        endHour = endHour-12;
        timeLabel = " PM"
    }
    let endMin = startTime.getMinutes();
    if (endMin.toString().length < 2) {
        endMin = "0" + endMin
    }
    return (endHour + ":" + endMin + timeLabel);
}

const generateQRCode = (purchase) => {
    let code ="";
    if(purchase.hasOwnProperty("Code")){
        code = purchase.Code;    
    }
    
    let link = '';
    if (code !== "") {
        link = "https://www.bezestty.com/?Code=" + code;
        //link = "https://www.bezestty.com/?AdId=" + adId.replace("#", "") + "&Token=" + token + "&UserId=" + userId;
    }
    return {link: link, code: code};
}

const prepareSelectorOptions = (arr) => {
    let selectorOptions = [];

    try {
        arr.forEach(item => {
            selectorOptions.push({ value: item, label: item })
        });
    } catch {

    }
    return selectorOptions;
}

const convertNameObjToArr = (names) =>{
    let selectorOptions = [];

    try {
        names.forEach(item => {
            selectorOptions.push(item.Name)
        });
    } catch {

    }
    return selectorOptions;
}

const tagsSelector = (options, multi, name, filterHandler, clearable) => {
    //alert(JSON.stringify(this.props.city));

    const customStyles = {
        option: (provided) => ({
            ...provided,
            color: 'black',
          }),
          multiValue: (provided) => ({
              ...provided,
              backgroundColor: "#564CAC",
              color: 'white',
              borderRadius: '100px'
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: 'white',
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            color: 'white',
          }),
          control: () => ({
            // none of react-select's styles are passed to <Control />
            backgroundColor: '#F6F6F4',
            display: 'flex',
            flexWrap: 'nowrap'
          }),
          dropdownIndicator: () => ({
              display: 'none'
          }),
          indicatorsContainer: () => ({
            display: 'none'
        })
    }

    return(
    <div className="select" id={name} style={{width:"75%"}}>
      <Select 
      menuPlacement="bottom"
      
      styles={customStyles}
      options={options}
      isMulti={multi}
      name={name}//"cityFilter" 
      isSearchable={true} 
      onChange={filterHandler}//{this.updateCityFilter}
      isClearable = {clearable}
      defaultValue={""}
      placeholder="Search"
      />
    </div>
  )
}

const getAddressJson = (lat,lng, google) => {

    var latlng = new google.maps.LatLng(lat, lng);
    var geocoder = new google.maps.Geocoder();
    return new Promise(function(resolve, reject) {
        let response = {};
        // Geocode the address
        geocoder.geocode({ 'latLng': latlng }, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                response.lat = "" + results[0].geometry.location.lat();
                response.lng = "" + results[0].geometry.location.lng();
                response.address = results[0].formatted_address;

                results[0].address_components.forEach(addressComponent => {
                    switch (addressComponent.types[0]) {
                        case "country":
                            response.country = addressComponent.long_name;
                            break;

                        case "locality":
                            response.city = addressComponent.long_name;
                            break;

                        default:
                            break;
                    }

                })
                resolve(response)
            } else {
                alert("Invalid address");
                resolve({ message: 'error' })
            }
        });
    });
}

const getCityCoords = (city, google) => {
    var geocoder = new google.maps.Geocoder();
    return new Promise(function(resolve, reject) {
        let response = {};
        // Geocode the address
        geocoder.geocode({ 'address': city }, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                response.lat = "" + results[0].geometry.location.lat();
                response.lng = "" + results[0].geometry.location.lng();
                resolve(response)
            } else {
                alert("Invalid address while fetching city");
                resolve({ message: 'error' })
            }
        });
    });
}

const getDate = (purchase) =>{
    
    // let date = purchase.PurchaseDateTime.slice(4).split(" ",3);
    let date = purchase.PurchaseDateTime.split("/");
    let year = date[2].split(",")[0];
    let day = date[1]
    let month = getMonthName(date[0])

    return `${month} ${day} ${year}`
}

const getMonthName = (monthNumber) =>{
    switch(parseInt(monthNumber)){
        case 1:
            return "Jan"
        
        case 2:
            return "Feb"
        
        case 3:
            return "Mar"

        case 4:
            return "Apr"

        case 5:
            return "May"

        case 6:
            return "Jun"

        case 7:
            return "Jul"

        case 8:
            return "Aug"

        case 9:
            return "Sep"

        case 10:
            return "Oct"

        case 11:
            return "Nov"

        case 12:
            return "Dec"

        default:
            
            return getMonthName(new Date().getMonth()+1)
    }
}

//determines whether or not a promotion is the last fetched promotion
const checkLastValuatedKey = (promotion, lastEvaluatedKey) => {
    try{
        if(lastEvaluatedKey.hasOwnProperty("PromotionId")){
            if(promotion.PromotionId===lastEvaluatedKey.PromotionId){
                return true
            } else {
                return false
            }
        }
    } catch {
        return false
    }
}

const UI_API = {
    runFilters,
    filterOutOfStockAds,
    getLiked,
    getPurchased,
    getTagsFromPromotions,
    getNamesFromPromotions,
    getTimeLeft,
    generateQRCode,
    sleep,
    prepareSelectorOptions,
    convertNameObjToArr,
    tagsSelector,
    getAddressJson,
    getCityCoords,
    orderTagsByCount,
    checkFetchPromotionsBasedOnTags,
    checkFetchPromotionBasedOnName,
    filterPromotionsByTag,
    getDate,
    checkLastValuatedKey,
    calculateAmountSaved,
    createShareLink,
    createShareText
}

export default UI_API;