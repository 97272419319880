import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

//images
import GoogleIcon from '../img_resources/Sign In Page/google.svg';
import FacebookIcon from '../img_resources/Sign In Page/facebook.svg';
import loginImage from '../img_resources/Sign In Page/login.png'
import loader from '../img_resources/Loaders/purple-loader.svg'

//actions
import {logIn} from '../actions/userActions'
import {setForm} from '../actions/formActions'
import { useHistory, useLocation } from 'react-router-dom';

//components
import Loader from '../components/Loader'

function SignInScreen() {
    const dispatch = useDispatch();

    let history = useHistory();
    let location = useLocation();

    let pathname = location.state || {pathname:"/"};

    if(location.state) {
        window.sessionStorage.setItem("returnUrl", JSON.stringify(location.state));
    }

    const [loadingScreen, setLoadingScreen] = useState(!!location.search.includes('?code=') || false);
    const [provider, setProvider] = useState('');

    const user = useSelector(store=>store.user.user);
    const checkingUser = useSelector(store=>store.user.checkingUser);
    const loading = useSelector(store=>store.user.loading);

    useEffect(()=>{
        if(!!user.username && pathname!=="/SignIn"){
            history.push('/')
        } else if (pathname === "/SignIn") {            
        }
    },[user.username]);

    const onSocialClickHandler = provider => {
        setProvider(provider);
        dispatch(logIn(provider));
        setLoadingScreen(true)

    }

        return(
            <>

                <div className="new-container">
                    {
                        loadingScreen ?
                        <Loader />
                        :
                    
                        <>
                            <img src={loginImage} alt="login" className="login-image"/>
                            <h1 className="login-headline">The best places & deals in <br></br>your city</h1>
                            <p className="no-m">Find the zestiest places to spend your nights!</p>

                            <button
                                className="button google"
                                onClick={() => onSocialClickHandler('Google')}>
                                <div className="login-button-content">
                                    <img className="smaller-icon"
                                        src={provider==="Google"||checkingUser?(loader):(GoogleIcon)}
                                        alt="Google Icon"/>
                                    <p className="text">Continue with Google</p>
                                </div>
                            </button>

                            <button
                                className="button facebook"
                                onClick={() => onSocialClickHandler('Facebook')}>
                                <div className="login-button-content">
                                    <img className="smaller-icon"
                                            src={provider==="Facebook"||checkingUser?(loader):(FacebookIcon)}
                                            alt="Google Icon"/>
                                    <p className="text">Continue with Facebook</p>
                                </div>
                            </button>



                            <p className="terms-link" >
                                By continuing, you agree to Get Zestty's
                                <label
                                    className="terms-highlight"
                                    onClick={()=>{
                                        dispatch(setForm({},"TERMS", ""))
                                    }}
                                    >Terms of Service
                                </label>
                                    and
                                <label
                                    className="terms-highlight"
                                    onClick={()=>{
                                        dispatch(setForm({},"TERMS", ""))
                                    }}
                                    >Privacy Policy
                                </label>
                            </p>
                        </>
                    }
                </div>

            </>
        )

}
export default SignInScreen;
