// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {useSelector} from 'react-redux';

import Loader from './Loader';

const PrivateRoute = ({ component: Component, ...rest }) => {

  const user = useSelector(store=>store.user);
  const isLoggedIn = user.user.hasOwnProperty("username");
  const checkingUser = useSelector(store=>store.user.checkingUser);
  const isVerified = useSelector(store=>store.user.isVerified);

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn? (
          isVerified?
          (<Component {...props} />):
          (<Redirect to={{ pathname: `/Confirm`}} />)
        ) : 
            (<Redirect to={{ pathname: `/SignIn`, state: props.location }} />)
      }
    />
  )
}

export default PrivateRoute
