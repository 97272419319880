/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "getzestty-20200716190909-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1j1xggktjdfpk.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:92bd0cc8-7a98-494b-9ef3-f6d2ac8a88cd",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_VNKhPm7AO",
    "aws_user_pools_web_client_id": "6b1uncdk2e0rh7v19vqh727smh",
    "oauth": {
        "domain": "getzestty.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost:3000/,https://www.getzestty.com/",
        "redirectSignOut": "https://www.getzestty.com/,https://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
