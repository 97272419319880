import React from 'react';
import {NavLink} from 'react-router-dom'

//components
import ReportButton from './Buttons/ReportButton';
import ShareBtn from './Buttons/ShareBtn';


const FeedAdImage = ({promotion}) => {
    
    const imgStyle = {
        backgroundImage: 'url(' + promotion.Image + ')'
    };

    return(  
        <div className="new-feed-ad-img" 
            style={imgStyle}
        >
            
            <NavLink 
                to={{
                    pathname: `/Promotion/${promotion.PromotionId}`,
                    promotion:promotion
                }} 

                className="overlay flipped"/>

            <ReportButton promotion={promotion} />
            
            <ShareBtn promotion={promotion}/>

        </div>              
    )
    
}

export default FeedAdImage;