import React, { useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Fade from 'react-reveal'

//actions
import {setForm, setPayingLoading} from '../../actions/formActions';
import {fetchPurchases} from '../../actions/purchasesActions';
import {setModal} from '../../actions/popupModalActions';

import ZESTTY_API from '../../api/index'
import ZESTTY from '../../ui_functions'

//images
import paymentSeperator from '../../img_resources/Forms/Purchase/payment-seperator.svg'
import wallet from '../../img_resources/Forms/Purchase/wallet-white.svg'
import purchaseLoaderImg from '../../img_resources/Loaders/white-loader.svg'
import ccErrorImg from '../../img_resources/Popup Modal/credit-card-error.svg'
import successImage from '../../img_resources/Forms/Purchase/success.svg'
import { useEffect } from 'react';


const PayButton = ({promotion, useZCredits}) => {

    const dispatch = useDispatch();

    const userId = useSelector(store=>store.user.user.username);
    const refId = useSelector(store=>store.user.refId);
    const purchases = useSelector(store=>store.purchases.active);
    const archivePurchases = useSelector(store=>store.purchases.archive);
    const userName = useSelector(store=>store.user.user.attributes.given_name);
    const email = useSelector(store=>store.user.user.attributes.email);
    const form = useSelector(store=>store.form);
    const selectedToken = useSelector(store=>store.popupModal.selectedToken);
    const zCredits = useSelector(store=>store.user.zCredits);
    const purchaseLoader = useSelector(store=>store.form.loading);

    // const [purchaseLoader, setPurchaseLoader] = useState(null);
    const [err, setErr] = useState(undefined);
    const [loading, setLoading] = useState(true)
    
    const pay = async() =>{
        
        try{
            dispatch(setPayingLoading(true))
            let response = await ZESTTY_API.useToken(
            userId,
            selectedToken.number,
            selectedToken.expDate,
            form.promotion.Price,
            promotion.PromotionId,
            promotion.Promotion,
            promotion.AdvertiserId,
            promotion.AppName,
            promotion,
            userName,
            email,
            useZCredits,
            refId
            );
            console.log(response)
            // if(JSON.parse(response).message==="Transaction Successful"){
            if(response.hasOwnProperty("errorMessage")){
                setErr(response.errorMessage)
                console.log(response.errorMessage)
                dispatch(setPayingLoading(false))
                dispatch(setModal("error", "Payment Failed", "It seems we couldn't charge your credit card", ccErrorImg))
            } else {
                // calculate amount saved
                console.log("calculating amount saved")
                const amountSaved = ZESTTY.calculateAmountSaved(promotion.OriginalPrice, promotion.Price, useZCredits, zCredits)
                console.log("amount saved is ", amountSaved)

                //add amount saved
                await ZESTTY_API.updateAmountSaved(userId, amountSaved)
                
                if(refId!==""){
                    //remove RefId + send credits
                    console.log("transfering credits to ", refId)
                    await ZESTTY_API.fulfilRef(userId,refId)
                }

                dispatch(fetchPurchases(userId));
                dispatch(setModal("success", "Payment Complete!", "We hope you have a Zestty time!", successImage))
                
                dispatch(setPayingLoading(false))
                
                dispatch(setForm({}, "Hide", ""));
                
                
                
                
                // setErr(response.message)
                // setPurchaseLoader(false)
            } 
        } catch (error) {
            setErr(error);
            dispatch(setPayingLoading(false))
            if(error.message==="Failed zCredits Handler"){
                await retryFulFilRef();
            } else if (error.message==="Payment Failed") {
                dispatch(setModal("error", "Payment Failed", "It seems we couldn't charge your credit card", ccErrorImg))
            }
            console.log(error)
        }
        
    }

    const retryFulFilRef = async (count=0) => {
        try{
            return await ZESTTY_API.fulfilRef(userId,refId)
        } catch (error) {
            console.log(error.message);
            if(count<3){
                return await retryFulFilRef(count+1);
            }
        }
    }

    useEffect(()=>{
        ZESTTY.sleep(1000)
            .then(res=> setLoading(false))
    },[])

    const calcPriceToPay = () =>{
        let amount = parseInt(form.promotion.Price);
        if(useZCredits) {
            amount = parseInt(form.promotion.Price) - parseInt(zCredits);
        }
        if(amount < 0) {
            return 0.1
        }
        return amount
    }

    const userAgent = window.navigator.userAgent;

    // if(loading){
    //     return (<></>)
    // } else {
        return(
            <Fade bottom when={!loading}>
                <div 
                    // ref={ref}
                    className={!!selectedToken?"pay-btn":"pay-btn-gray" }
                    // style={
                    //     (userAgent.match(/iPhone/i) || userAgent.match(/Mac/i))?
                    //     {bottom:'0px', paddingBottom:'0px', position:'fixed'}:
                    //     {}
                    // }
                    onClick={()=>{!!selectedToken? pay():console.log("please choose payment method")}}>
                    {
                        purchaseLoader?
                        <>
                            <img src={purchaseLoaderImg} alt="loader" style={{height: '23px', width: 'auto'}}/>    
                        </>:
                        <>
                            <div id="amount-to-pay"><b>₪</b>{calcPriceToPay()}</div>
                            <img src={paymentSeperator} alt="seperator line" className="payment-line-seperator"/>
                            <div><b>Pay</b></div>
                            <img src={wallet} alt="wallet"/>    
                        </>
                    }
                    
                </div>
                
            </Fade>
        )
    // }

    
}

export default PayButton;