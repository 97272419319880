const initialState = {
    city: {
        value: "Tel Aviv-Yafo"
    },
    age: {
        value: undefined,
        active: false
    },
    name: {
        value: undefined,
        active: false
    },
    tag: {
        value: [],
        limit: 0,
        active: false
    },
    like: {
        active: false
    },
    live: {
        active: false,
        value: undefined
    },
    purchase: {
        active: false
    },
    tagsOptions: [],
    allTags: [],
    namesOptions: [],
    citiesOptions: [],
    show: false
}

export const filterReducer = (state = initialState, action) => {

    let active;

    switch (action.type) {


        case "SET_CITY_FILTER":
            return {
                ...state,
                city: {
                    value: action.value
                }
            }

        case "SET_AGE_FILTER":

            action.value !== undefined ? active = true : active = false;

            return {
                ...state,
                age: {
                    value: action.value,
                    active: active
                }
            }

        case "SET_TAG_FILTER":

            action.value.length !== 0 ? active = true : active = false;

            return {
                ...state,
                tag: {
                    value: action.value,
                    active: active,
                    limit: action.limit
                }
            }

        case "SET_NAME_FILTER":

            action.value !== undefined ? active = true : active = false;

            return {
                ...state,
                name: {
                    value: action.value,
                    active: active
                }
            }

        case "TOGGLE_LIVE":

            return {
                ...state,
                live: {
                    active: action.live
                }
            }

        case "TOGGLE_LIKE":

            return {
                ...state,
                like: {
                    active: !state.like.active
                }
            }

        case "TOGGLE_PURCHASE":

            return {
                ...state,
                purchase: {
                    active: !state.like.active
                }
            }

        case "TOGGLE_SHOW_FILTERS":

            return {
                ...state,
                show: !state.show
            }

        case "GET_TAGS_FOR_FILTERS":

            return {
                ...state,
                loading: true
            }

        case "SETTING_TAGS_FOR_FILTERS":
            return {
                ...state,
                allTags: action.tags,
                tagsOptions: action.tagsOptions
            }

        case "GET_TAGS_FOR_FILTERS_FAILURE":

            return {
                ...state,
                err: action.message,
                loading: false
            }

        case "GET_NAMES_FOR_FILTERS":

            return {
                ...state,
                loading: true
            }

        case "SETTING_NAMES_FOR_FILTERS":
            return {
                ...state,
                namesOptions: action.names
            }

        case "GET_NAMES_FOR_FILTERS_FAILURE":

            return {
                ...state,
                err: action.message,
                loading: false
            }

        case "EXTRACT_NAMES_FOR_FILTERS":
            return {
                ...state,
                namesOptions: action.names
            }

        case "GET_CITIES":
            return {
                ...state,
                loading: true
            }

        case "GET_CITIES_SUCCESS":
            return {
                ...state,
                citiesOptions: action.cities,
                loading: false
            }

        case "GET_CITIES_FAILURE":
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}