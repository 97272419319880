import React from 'react';
import Fade from 'react-reveal'

import { useState } from 'react';

import Plus from '../img_resources/FAQ/Plus.svg'

const Question  = ({question, answer}) => {
    
    const [showAnswer, setShowAnswer] = useState(false);

    return(         
        <Fade bottom>
            <div className="question-wrapper" style={{textAlign:'left'}}>
                <div className="flex-row" style={{justifyContent:'space-between',width:'100%'}} onClick={()=>{setShowAnswer(!showAnswer)}}>
                    <p className="question" style={{color:showAnswer?"#f06424":"#564cac"}} ><b>Q:</b> {question}</p>
                    <img src={Plus} alt="plus icon"/>
                </div>
                
                <Fade collapse top when={showAnswer}>
                    <p className="answer"><b>A:</b> {answer}</p>
                </Fade>
                <div className="line-seperator"></div>
            </div>
        </Fade>
    )
}

export default Question;