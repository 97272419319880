//libraries
import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Map, Marker} from 'google-maps-react';
import Fade from 'react-reveal'
import { useLocation } from 'react-router-dom'

//components
import SearchBar from '../components/SearchBar';
import MapAd from '../components/Ad Blocks/MapAd';
import Loader from '../components/Loader';

//actions
import {setLocation} from '../actions/userActions';
import {setCity} from '../actions/filterActions';
import {fetchPromotions} from '../actions/promotionsActions';

//images
import zIcon from '../img_resources/Map View/map-anchor.png';
import userIcon from '../img_resources/Map View/user-location.png';
import noAds from '../img_resources/Shared Components/error.svg'

//inner libraries
import UI from '../ui_functions/index.jsx';

const MapView = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const [showPromotion, setShowPromotion] = useState(false);
    const [promotion, setPromotion] = useState({});
    const [filteredPromotions, setFilteredPromotions] = useState([]);
    const [bounds, setBoundsState] = useState(undefined);
    const [useUserLocation, setUseUserLocation] = useState(false);
    const [attemptedToFetchLocation, setAttemptToFetchLocation] = useState(false);

    const promotions = useSelector(store=>store.promotions.promotions);
    const lastEvaluatedKey = useSelector(store=>store.promotions.lastEvalKey);
    const userLocation = useSelector(store=>store.user.geoLocation);
    const userCity = useSelector(store=>store.user.city);
    const loadingPromotions = useSelector(store=>store.promotions.loading);
    const filters = useSelector(store=>store.filter);
    const google = useSelector(store=>store.google.google)
    const city = useSelector(store=>store.filter.city.value);
    
    //handles promotion and filter changes
    useEffect(()=>{
        if(promotions.length>0){
            setFilteredPromotions(
                UI.filterOutOfStockAds(
                    UI.runFilters(promotions, filters.tag, filters.age, filters.name, filters.live, city)
                )
            )
        }
    },[promotions, filters])

    //handles the fetch of userLocation
    useEffect(()=>{
        if(userLocation.lat===null && !attemptedToFetchLocation){
            setAttemptToFetchLocation(true)
            fetchUserLocation();
        }
        //if user location is set we tell the state to use the user location
        if(userLocation.lat===null){
            setUseUserLocation(false)
        }
        if(userCity!==filters.city.value){
            setUseUserLocation(false)
        } else {
            setUseUserLocation(true)
        }
    },[userLocation])

    useEffect(()=>{
        try{
            if(promotions.length>0 && lastEvaluatedKey.hasOwnProperty("PromotionId")){
                dispatch(fetchPromotions(filters.city.value, true, filters.name, filters.age, filters.tag, lastEvaluatedKey, promotions))
            }
        } catch {

        }
    },[promotions, lastEvaluatedKey])

    const style = {
        width: 'calc(100vw)',
        height: '100%',
        // marginLeft: '-30px',
        borderRadius: '45px 45px 0px 0px',
        position: 'relative',
        zIndex: 50,
        top:0
    }


    const onMarkerClick = (promotion) =>{
        var newBounds = setBounds({Lat: promotion.GeoCode.Lat, Lng: promotion.GeoCode.Lng});
        setPromotion(promotion)
        setShowPromotion(true)
        setBoundsState(newBounds)
    }
        
    const setBounds = (geoCode) => {
        var newBounds = new window.google.maps.LatLngBounds();
        newBounds.extend({lat: parseInt(geoCode.Lat)+0.001, lng: parseInt(geoCode.Lng)+0.001});
        newBounds.extend({lat: parseInt(geoCode.Lat)+0.001, lng: parseInt(geoCode.Lng)+0.001});
        newBounds.extend({lat: parseInt(geoCode.Lat)-0.001, lng: parseInt(geoCode.Lng)+0.001});
        newBounds.extend({lat: parseInt(geoCode.Lat)-0.001, lng: parseInt(geoCode.Lng)-0.001});
        return newBounds;
    }

    const onMapClicked = () => {
        if (showPromotion) {
            setShowPromotion(false)
        }
    };

    const fetchUserLocation = async() => {
        console.log("FETCHING USER LOCATION")
        //gets the users location
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(async(position) => {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            let addressJson = await UI.getAddressJson(lat,lng, google);
            dispatch(setLocation(lat, lng, addressJson.city, addressJson.country));
            dispatch(setCity(addressJson.city));
          });
        } else {
        }
    }


        
        // if(loadingPromotions && promotions.length===0){
        //     return <Loader/>;
        // } else {
            return(
                <Fade bottom>
                    <div className="new-container" id="mapView" style={{height:'80vh', paddingBottom:'0px'}}>
                        {
                            loadingPromotions && promotions.length===0?
                            <Loader/>:
                            <div className="content-wrapper map">
                                <div className="map-top-content">
                                    <div style={{marginLeft:'30px'}}>
                                        <SearchBar pathname={location.pathname}/>
                                    </div>
                                </div>
                                
                                

                                {
                                    showPromotion?
                                    <MapAd promotion={promotion}/>:
                                    <></>
                                }
                                {
                                    filteredPromotions.length>0?
                                    <Map 
                                        google={window.google}           
                                        style={style}
                                        zoom={18} 
                                        mapTypeControl={false}
                                        fullscreenControl={false}
                                        streetViewControl={false}
                                        zoomControl={false}
                                        gestureHandling={'greedy'}
                                        // ref={(ref) => {
                                        //     mapRef = ref;
                                        // }}
                                        initialCenter={{
                                            lat: useUserLocation?userLocation.lat:filteredPromotions[0].GeoCode.Lat,
                                            lng: useUserLocation?userLocation.lng:filteredPromotions[0].GeoCode.Lng,
                                        }}
                                        center={{
                                            lat: showPromotion?promotion.GeoCode.Lat:useUserLocation?userLocation.lat:filteredPromotions[0].GeoCode.Lat,
                                            lng: showPromotion?promotion.GeoCode.Lng:useUserLocation?userLocation.lng:filteredPromotions[0].GeoCode.Lng
                                        }}
                                        bounds= {showPromotion?bounds:setBounds(
                                            useUserLocation?
                                            {Lat: userLocation.lat, Lng: userLocation.lng}:
                                            {Lat: filteredPromotions[0].GeoCode.Lat, Lng: filteredPromotions[0].GeoCode.Lng}
                                            )}
                                        onClick={() => onMapClicked()}
                                        >

                                        {
                                            filteredPromotions.length>0?
                                            filteredPromotions.map(item=>
                                                <Marker 
                                                    key={item.PromotionId}
                                                    onClick={()=>{
                                                        onMarkerClick(item)}}
                                                    name={item.Name} 
                                                    title={item.Name} 
                                                    position={{
                                                        lat: item.GeoCode.Lat,
                                                        lng: item.GeoCode.Lng
                                                    }}
                                                    icon={{
                                                        url: zIcon,
                                                        // anchor: new this.props.google.maps.Point(32,32),
                                                        scaledSize: new google.maps.Size(40,40)
                                                    }}
                                                    />
                                            ):
                                            <></>
                                        }

                                        {
                                            userLocation.lat!==null?
                                                <Marker 
                                                    name={"Your Location"} 
                                                    title={"Your Location"} 
                                                    position={{
                                                        lat: userLocation.lat,
                                                        lng: userLocation.lng
                                                    }}
                                                    icon={{
                                                        url: userIcon,
                                                        // anchor: new this.props.google.maps.Point(32,32),
                                                        scaledSize: new google.maps.Size(32,32)
                                                    }}
                                                    animation={window.google.maps.Animation.BOUNCE}
                                                    />:
                                                <></>
                                        }
                                    </Map>:
                                    <>
                                        <h2 className="no-ads-text" style={{padding:'0px 30px'}}>Oops. It seems we don't have any active promotions in {filters.city.value}</h2>
                                        <img src={noAds} alt="no ads illustration" style={{width:'80vw',height:'auto'}}/>
                                    </>
                                }
                                
                            </div>
                        }
                        
                        

                        
                    </div>
                </Fade>
            )
        // }
}

export default MapView;