import { Auth } from 'aws-amplify';
import ZESTTY from '../api';

export const logIn = (provider) => {
    return function(dispatch) {
        dispatch(logInStarted());
        Auth.federatedSignIn({ provider: provider })
            .then(user => {
                dispatch(logInSuccess(user));
            })
            .catch(err => {
                dispatch(logInFail(err))
            })
    }
}

export const logOut = () => {
    return function(dispatch) {
        dispatch(logOutStarted());
        Auth.signOut()
            .then(data => dispatch(logOutSuccess()))
            .catch(err => dispatch(logOutFail()))
    }
}

export const checkUser = () => {
    return function(dispatch) {
        dispatch(checkUserStarted());
        Auth.currentAuthenticatedUser()
            .then(data => {
                // if (!storeUser.hasOwnProperty("user")) {
                dispatch(logInSuccess(data));
                // }
            })
            .catch(err => dispatch(checkUserFail(err)))
    }
}

export const FetchUserData = (userId, refId) => {
    return function(dispatch) {
        dispatch(fetchUserDataStarted());
        ZESTTY.userHandler(userId, refId)
            .then(data => {
                dispatch(fetchUserDataSuccess(data))
            })
            .catch(err => dispatch(fetchUserDataFail(err)))
    }
}

export const deleteToken = (userId, token) => {
    return function(dispatch) {
        dispatch(deleteTokenStarted());
        ZESTTY.deleteToken(userId, token.number, token.expDate, token.ccType)
            .then(data => {
                let tokensList = [];
                data.Tokens.forEach(tokenString => {
                    let token = {};
                    let tokenArr = tokenString.split("#");

                    token.number = tokenArr[0];
                    token.expDate = tokenArr[1];
                    token.ccType = tokenArr[2];

                    tokensList.push(token)
                })
                dispatch(deleteTokenSuccess(tokensList))
            })
            .catch(err => dispatch(deleteTokenFail(err)))
    }
}

export const getUserData = (userId) => {
    return function(dispatch) {
        dispatch(getUserDataStarted());
        ZESTTY.getUserData(userId)
            .then(data => {
                dispatch(getUserDataSuccess(data))
            })
            .catch(err => {
                dispatch(getUserDataFail(err))
            })
    }
}

export const postLike = (userId, advertiserId) => {
    return function(dispatch) {
        dispatch(postLikeStarted());
        ZESTTY.postLike(userId, advertiserId)
            .then(res => {
                dispatch(postLikeSuccess(advertiserId));
            })
            .catch(err => {
                dispatch(postLikeFail(err))
            })
    }
}

export const deleteLike = (userId, advertiserId) => {
    return function(dispatch) {
        dispatch(deleteLikeStarted());
        ZESTTY.deleteLike(userId, advertiserId)
            .then(res => {
                dispatch(deleteLikeSuccess(advertiserId));
            })
            .catch(err => {
                dispatch(deleteLikeFail(err))
            })
    }
}

export const setFirstTime = (userId, firstTime) => {
    return function(dispatch) {
        dispatch(setFirstTimeStarted());
        ZESTTY.setFirstTime(userId)
            .then(res => {
                dispatch(setFirstTimeSuccess(firstTime));
            })
            .catch(err => {
                dispatch(setFirstTimeFail(err))
            })
    }
}

export const setLocation = (lat, lng, city, country) => {
    return {
        type: "SET_LOCATION",
        lat: lat,
        lng: lng,
        city: city,
        country: country
    }
}

const logInStarted = () => {
    return {
        type: 'LOGIN_STARTED'
    }
}

export const logInSuccess = (user) => {
    if (user === undefined) {
        user = {}
    }
    return {
        type: 'LOGIN_SUCCESS',
        user: user
    }
}

const logInFail = (err) => {
    return {
        type: 'LOGIN_FAILED',
        err: err
    }
}

const logOutStarted = () => {
    return {
        type: 'SIGN_OUT_STARTED'
    }
}

const logOutSuccess = () => {

    return {
        type: 'SIGN_OUT_SUCCESS'
    }
}

const logOutFail = (err) => {
    return {
        type: 'SIGN_OUT_FAIL',
        err: err
    }
}

export const loaded = () => {
    return {
        type: 'loaded',
        loading: false
    }
}

const checkUserStarted = () => {
    return {
        type: 'CHECK_USER_STARTED'
    }
}

const checkUserFail = (err) => {
    return {
        type: 'CHECK_USER_FAILED',
        err: err
    }
}

const fetchUserDataStarted = () => {
    return {
        type: 'FETCHING_USER_DATA_STARTED'
    }
}

const fetchUserDataSuccess = (data) => {
    return {
        type: 'FETCHING_USER_DATA_SUCCESS',
        data: data
    }
}

const fetchUserDataFail = (err) => {
    return {
        type: 'FETCHING_USER_DATA_FAILED',
        err: err
    }
}

const getUserDataStarted = () => {
    return {
        type: 'GET_USER_DATA_STARTED'
    }
}

const getUserDataSuccess = (data) => {
    return {
        type: 'GET_USER_DATA_SUCCESS',
        data: data
    }
}

const getUserDataFail = (err) => {
    return {
        type: 'GET_USER_DATA_FAILED',
        err: err
    }
}

const deleteTokenStarted = () => {
    return {
        type: 'DELETE_TOKEN_STARTED'
    }
}

const deleteTokenSuccess = (tokens) => {
    return {
        type: 'DELETE_TOKEN_SUCCESS',
        tokens: tokens
    }
}

const deleteTokenFail = (err) => {
    return {
        type: 'DELETE_TOKEN_FAILED',
        err: err
    }
}

const postLikeStarted = () => {
    return {
        type: 'POST_LIKE_STARTED'
    }
}

const postLikeSuccess = (advertiserId) => {
    return {
        type: 'POST_LIKE_SUCCESS',
        advertiserId: advertiserId
    }
}

const postLikeFail = (err) => {
    return {
        type: 'POST_LIKE_FAILED',
        err: err
    }
}

const deleteLikeStarted = () => {
    return {
        type: 'DELETE_LIKE_STARTED'
    }
}

const deleteLikeSuccess = (advertiserId) => {
    return {
        type: 'DELETE_LIKE_SUCCESS',
        advertiserId: advertiserId
    }
}

const deleteLikeFail = (err) => {
    return {
        type: 'DELETE_LIKE_FAILED',
        err: err
    }
}

const setFirstTimeStarted = () => {
    return {
        type: 'SET_FIRST_TIME_STARTED'
    }
}

const setFirstTimeSuccess = (firstTime) => {

    return {
        type: 'SET_FIRST_TIME_SUCCESS',
        firstTime: firstTime
    }
}

const setFirstTimeFail = (err) => {
    return {
        type: 'SET_FIRST_TIME_FAIL',
        err: err
    }
}