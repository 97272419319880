import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom'
import Slide from 'react-reveal';


//images
import skip from '../img_resources/instructions/skip.png'

import image1 from '../img_resources/instructions/1.svg'
// import image1 from '../img_resources/instructions/New/Screenshot.png'
import image2 from '../img_resources/instructions/2.svg'
import image3 from '../img_resources/instructions/3.svg'
import image4 from '../img_resources/instructions/4.svg'
import image5 from '../img_resources/instructions/5.svg'

import circle from '../img_resources/instructions/icons/instructions circle.svg'
import highlitedCircle from '../img_resources/instructions/icons/highlighted instructions circle.svg'

import timeIcon from '../img_resources/instructions/icons/time-white.png'
import reportIcon from '../img_resources/instructions/icons/report.svg'
import shareIcon from '../img_resources/instructions/icons/share-white.svg'
import likeIcon from '../img_resources/instructions/icons/heart-white.svg'
import directionsIcon from '../img_resources/instructions/icons/directions-white.svg'
import purchaseIcon from '../img_resources/instructions/icons/purchase-white.svg'
import tagsIcon from '../img_resources/instructions/icons/tags-white.svg'
import qrIcon from '../img_resources/instructions/icons/qr-code-white.png'
import UI from '../ui_functions'

const Instructions = () => {
    
    const [show, setShow] = useState(false)
    const [instructions, setInstructions] = useState({
        step: 1,
        imgSrc: image1,
        title: "Find your Zest!",
        text: "Easily discover countless real-time promotions and great deals in your city.",
        smallText:"The promotions live for only 90 minutes!"
    });

    const userAgent = window.navigator.userAgent;
    const steps = [1,2,3,4,5,6]

    useEffect(()=>{
        setShow(true)
    },[])

    const step1 = {
        step: 1,
        imgSrc: image1,
        title: "Find your Zest!",
        text: "Easily discover countless real-time promotions and great deals in your city.",
        smallText:"The promotions live for only 90 minutes!"
    }

    const step2 = {
        step: 2,
        imgSrc: image2,
        title: "Filter your finds!",
        text: "Use the filter icon to find deals that suit what you're looking for.",
        smallText:"You can filter by city, establishment, age, or tag."
    }

    const step3 = {
        step: 3,
        imgSrc: image3,
        title: "Share with your friends!",
        text: "No one goes out alone, right? \n Bring your friends along and enjoy the deal together.",
        smallText:"You can share the promotion on WhatsApp, Messenger, Twitter or with a link."
    }

    const step4 = {
        step: 4,
        imgSrc: image4,
        title: "Secure your deal!",
        text: "Pay for your deal right away and head out for a Zestty night.",
        smallText:"Act quick before the deal runs out or gets snatched up!"
    }

    const step5 = {
        step: 5,
        imgSrc: image5,
        title: "Scan it, claim it, enjoy it!",
        text: "Head over to the establishment, and claim your deal by showing the QR code that we provided for you.",
        smallText:"Each promotion can only be claimed once per customer."
    }

    const step6 = {
        step: 6,
        imgSrc: "",
        title: "Real quick, Let's help you get around!",
        text: "",
        smallText:""
    }

    const changeStep = (action) =>{
        let nextStep;

        if(action==="increment"){
            nextStep = instructions.step + 1;
        } else {
            nextStep = instructions.step - 1;
        }
        switch (nextStep) {
            case 1:
                setInstructions(step1)
                break;
            case 2:
                setInstructions(step2)
                break;
            case 3:
                setInstructions(step3)
                break;
            case 4:
                setInstructions(step4)
                break;
            case 5:
                setInstructions(step5)
                break;
            case 6:
                setInstructions(step6)
                break;
            default:
                break;
        }
    }

    const rednerImg = (imgSrc) => {
        return (<img className="instructions-image" src={imgSrc} alt="instructions" />)
    }

    

    return(
        <div className="instructions" 
        // style={
        //     window.innerHeight<=600?
        //         {overflow:'auto'}:
        //         {}
        //     }
            >
            <NavLink to="/Feed" className="skip-button" style={{backgroundImage:`url(${skip})`, textDecoration:'none'}} >SKIP</NavLink>
            {
                instructions.step===6?
                <>
                    <div className="spacer"/>
                    <h2 className="instructions-title">{instructions.title}</h2>
                    <div className="icon-instructions-container">
                        <div className="flex-row">
                            <img src={timeIcon} className="icon smaller" alt=""/>
                            <p className="instructions-small-text m-l-10 text-left">Time the promotion runs out</p>
                        </div>
                        <div className="flex-row">
                            <img src={likeIcon} className="icon smaller" alt=""/>
                            <p className="instructions-small-text m-l-10 text-left">Like the promotion to add it to your own personalized feed of your selected places</p>
                        </div> 
                        <div className="flex-row">
                            <img src={directionsIcon} className="icon smaller" alt=""/>
                            <p className="instructions-small-text m-l-10 text-left">Opens the location of the establishment on google maps</p>
                        </div>
                        <div className="flex-row">
                            <img src={tagsIcon} className="icon smaller" alt=""/>
                            <p className="instructions-small-text m-l-10 text-left">What tags are associated with the establishement and promotion</p>
                        </div>
                        <div className="flex-row">
                            <img src={shareIcon} className="icon smaller" alt=""/>
                            <p className="instructions-small-text m-l-10 text-left">Share the promotion with your friends</p>
                        </div>
                        <div className="flex-row">
                            <img src={purchaseIcon} className="icon smaller" alt=""/>
                            <p className="instructions-small-text m-l-10 text-left">Purchase the promotion</p>
                        </div>
                        <div className="flex-row">
                            <img src={qrIcon} className="icon smaller" alt=""/>
                            <p className="instructions-small-text m-l-10 text-left">Opens a QR code for the establishement to scan and give you your goods</p>
                        </div>
                        <div className="flex-row">
                            <img src={reportIcon} className="icon smaller" alt=""/>
                            <p className="instructions-small-text m-l-10 text-left">Report the promotion or establishment</p>
                        </div>
                    </div>
                </>:
                <>
                    <Slide right opposite when={show}>
                        {
                            // show?
                            // rednerImg(instructions.imgSrc)
                            // <></>
                        }
                        <img className="instructions-image" src={instructions.imgSrc} alt="instructions" />
                    </Slide >
                    <Slide right opposite when={!show}>
                        {
                            // show?
                            // rednerImg(instructions.imgSrc)
                            // <></>
                        }
                        <img className="instructions-image" src={instructions.imgSrc} alt="instructions" />
                    </Slide >
                    <h2 className="instructions-title">{instructions.title}</h2>
                    <div className="instructions-text-wrapper">
                    {
                        instructions.step===3?
                        <>
                            <p className="instructions-text" style={{marginBottom:'0px'}}>{instructions.text.split('\n')[0]}</p>
                            <p className="instructions-text" style={{marginTop:'0px'}}>{instructions.text.split('\n')[1]}</p>
                        </>:
                        <p className="instructions-text">{instructions.text}</p>
                    }
                    </div>
                    <p className="instructions-small-text" style={{height:'50px', margin: '0px'}}>{instructions.smallText}</p>
                </>
            }

            <div
                className="instructions-bottom-wrapper"
                style={
                    (userAgent.match(/iPhone/i) || userAgent.match(/Mac/i))?
                    {bottom:'100px'}
                    :{}
                }
            >
                <div 
                    className="instructions-circle-wrapper">
                        {
                            steps.map(step => <img src={step===instructions.step?highlitedCircle:circle} alt="steps indicator" width={6} height={6} />)
                        }
                </div>

                <div className="instructions-buttons-wrapper" >
                    {
                        instructions.step!==1?
                            <div 
                            className="instructions-btn"
                            style={{
                                backgroundColor: 'transparent',
                                color: 'white',
                                border: 'white 2px solid'
                            }}
                            onClick={async ()=>{
                                setShow(!show)
                                // UI.sleep(1000)

                                await changeStep("decrement");

                                // setShow(true)
                            }}
                        >
                            Back
                        </div>:
                        <></>    
                    }
                    {
                        instructions.step!==6?
                        <div 
                            className="instructions-btn"
                            style={instructions.step===1?{width:'100%'}:{}}
                            onClick={async ()=>{
                                setShow(!show)
                                UI.sleep(1000)

                                await changeStep("increment");
                                // setShow(true)
                            }}
                        >
                            Next
                        </div>:
                        <NavLink 
                        to="/Feed" 
                        className="instructions-btn"
                        style={
                            window.innerHeight<=600?
                                {position:'initial', marginBottom:'35px', textDecoration:'none'}:
                                {textDecoration:'none'}
                        }
                        >Get Started</NavLink>
                    }
            </div>
            
                
                {/* <div 
                    className={
                        (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i))?
                        "instructions-next-button iphone":
                        "instructions-next-button"
                    }
                    style={
                        window.innerHeight<=600?
                            {position:'initial', marginBottom:'35px'}:
                            {}
                    }
                    onClick={()=>{
                    incrementStep();
                }}>Next</div> */}
            </div>
        </div>
    )
}

export default Instructions;