import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import {queryStringToJSON, transformQueryPath} from './utils';
import { setForm } from '../actions/formActions';

import partyImg from '../img_resources/Redirect/Party.svg'

export default () => {
    let history = useHistory();
    let location = useLocation();

    const dispatch = useDispatch();

    

    // Sample of ShareLink
    // https://www.getzestty.com/redirect?path=Promotion&value=1234&refId=123e%23awe
    // Final URL 
    // Promotion/1234?refId=123e%23awe

    let locationSearch = `${location.search || ''}${location.hash || ''}`
    let query = locationSearch ? queryStringToJSON(locationSearch) : '';
    const userAgent = window.navigator.userAgent;

    let redirectLocation = transformQueryPath(query);

    useEffect(() => {
        if(userAgent.match(/iPhone/i) || userAgent.match(/Android/i)) {
            dispatch(setForm({}, "REDIRECT", 'Continue with...'))
        } else {
            history.push({pathname: "/" + redirectLocation.path, search: redirectLocation.search});
        }
    }, [dispatch])

    // useEffect(() => {
    //     if(userAgent.match(/iPhone/i) || userAgent.match(/Android/i)) {
    //         // if(userAgent.match(/iPhone/i)) {
    //         //     // navigateAppLink("https://play.google.com/store/apps/details?id=com.zesttygroup.getzestty");
    //         // } else if (userAgent.match(/Android/i)) {
    //         //     window.location = "intent://#Intent;package=com.zesttygroup.getzestty;scheme=getzestty;launchFlags=268435456;end;";
    //         //     window.opener=null;
    //         //     window.open('','_self');
    //         //     window.close();
    //         //     history.push('/');
    //         // } 
    //     } else {
    //         //https://www.getzestty.com/redirect?path=Promotion&value=1234&refId=123e%23awe
    //         // history.push(`/${url}`);
    //         // "/Promotion/" + data.PromotionId + "?refId=" + userData.userId + "#" + data.PromotionId);

    //         history.push('/')
    //     }
    // }, []);

    // const navigateAppLink = link => {
    //     let now = new Date().valueOf();
    //     window.location = `getzestty://${url}`;

    //     // getzestty://Promotion/123

    //     setTimeout(() => {
    //         if(new Date().valueOf() - now > 20000) return;
    //         window.location = link;
    //         window.opener=null;
    //         window.open('','_self');
    //         window.close();
    //         return history.push('/');
    //     }, 5000);
    // }

    // const onModalClickHandler = action => {
    //     // userAgent.match(/iPhone/i) || userAgent.match(/Android/i)
    //     if(action === 'mobile') {
    //         if(userAgent.match(/iPhone/i)) {
    //             // navigateAppLink("https://play.google.com/store/apps/details?id=com.zesttygroup.getzestty");
    //         } else if (userAgent.match(/Android/i)) {
    //             window.location = "intent://#Intent;package=com.zesttygroup.getzestty;scheme=getzestty;launchFlags=268435456;end;";
    //             window.opener=null;
    //             window.open('','_self');
    //             window.close();
    //             history.push('/');
    //         } 
    //     } else {
    //         //https://www.getzestty.com/redirect?path=Promotion&value=1234&refId=123e%23awe
    //         // history.push(`/${url}`);
    //         // "/Promotion/" + data.PromotionId + "?refId=" + userData.userId + "#" + data.PromotionId);

    //         history.push('/')
    //     }
    // }

    // console.log(query);

    return(
        <>
            <img src={partyImg} alt="People partying and getting Zestty"/>
            <h1 className="redirect-title">Get ready to get Zestty!</h1>
        </>
    )
}