import React from 'react';
import UI from '../../ui_functions/index.jsx'

import {NavLink} from 'react-router-dom'

//images
import TimeLeftIcon from '../../img_resources/Discover/time.svg'

const DiscoverAd =({promotion, isTagActive}) => {
    
    const bgImg = {backgroundImage: "url("+ promotion.Image+")"};

    return(
        <>
        {
            isTagActive?
            <NavLink 
                to={{
                    pathname: `/Promotion/${promotion.PromotionId}`,
                    promotion: promotion
                }}  
                className="discover-ad-selected" 
                style={bgImg}>
                <div className="overlay">
                    <div className="flex-row dicover-badges" >

                    <div className="badge first-badge" style={{fontSize:"14px",fontWeight:500, padding:'1.5px 4.5px'}}>
                        <img className="badge-icon" src={TimeLeftIcon} alt="time left icon"/>
                        {UI.getTimeLeft(promotion.ExpTimeStamp)}
                    </div>
                        {
                            promotion.Age==="0"?
                            <></>:
                            <div className="badge" style={{fontSize:"14px",fontWeight:500, padding:'1.5px 4.5px'}}>{promotion.Age + "+"}</div>
                        }
                    </div>
                    <div className="selected-discover-text-wrapper">
                        <div className="purple-line"></div>
                        <div className="selected-discover-name">{promotion.Name}</div>
                        <div className="selected-discover-promotion">{promotion.Promotion}</div>
                        <div className="selected-discover-price-wrapper">
                            <div className="selected-discover-ad-original-price">{promotion.OriginalPrice+"₪"}</div>
                            <div className="selected-discover-ad-price">{"₪"+promotion.Price}</div>
                        </div>
                    </div>
                    
                </div>
            </NavLink>:
            <NavLink to={{
                pathname: `/Promotion/${promotion.PromotionId}`,
                promotion:promotion
                }}  
            className="discover-ad">
                
                <div className="discover-ad-image" style={bgImg}></div>
                
                <div className="discover-info-container">
                    <div className="flex-row hover-badges" style={{top:'-27px'}}>

                        <div className="badge first-badge" style={{fontSize:"14px",fontWeight:500, padding:'1.5px 4.5px'}}>
                            <img className="badge-icon" src={TimeLeftIcon} alt="time left icon"/>
                            {UI.getTimeLeft(promotion.ExpTimeStamp)}
                        </div>
                            {
                                promotion.Age==="0"?
                                <></>:
                                <div className="badge" style={{fontSize:"14px",fontWeight:500, padding:'1.5px 4.5px'}}>{promotion.Age + "+"}</div>
                            }
                    </div>

                    <div className="flex-row space-between" style={{width:'100%'}}>
                        <div className="discover-ad-name">{promotion.Name}</div>
                        <div className="discover-ad-name">{"₪"+promotion.Price}</div>
                    </div>
                    
                    <div className="discover-ad-promotion">{promotion.Promotion}</div>

                </div>

            </NavLink>
        }
        </>   
    )
}

export default DiscoverAd;

// import React from 'react';
// import UI from '../../ui_functions/index.jsx'
// import {connect} from 'react-redux';

// import {resetArchive, openArchivedPurchase} from '../../actions/displayActions';

// import {NavLink} from 'react-router-dom'

// //images
// import TimeLeftIcon from '../../img_resources/Discover/time.svg'

// class DiscoverAd extends React.Component {

//     setArchive = () =>{
//         if(this.props.promotion.isPurchased){
//             if(this.props.archivePurchases.some(promotion => promotion.purchaseId.includes(this.props.promotion.PromotionId))){
//                 this.props.openArchivedPurchase();
//             } else {
//                 this.props.resetArchive();
//             }
//         }
//     }
//     render()
//     {
//         let bgImg = {backgroundImage: "url("+this.props.promotion.Image+")"};
//         return(
//             <>
//             {
//                 this.props.tagActive?
//                 <NavLink 
//                     to={{
//                         pathname: `/Promotion/${this.props.promotion.PromotionId}`,
//                         promotion:this.props.promotion
//                     }}  
//                     className="discover-ad-selected" 
//                     style={bgImg}>
//                     <div className="overlay">
//                         <div className="flex-row dicover-badges" >

//                         <div className="badge first-badge" style={{fontSize:"14px",fontWeight:500, padding:'1.5px 4.5px'}}>
//                             <img className="badge-icon" src={TimeLeftIcon} alt="time left icon"/>
//                             {UI.getTimeLeft(this.props.promotion.ExpTimeStamp)}
//                         </div>
//                             {
//                                 this.props.promotion.Age==="0"?
//                                 <></>:
//                                 <div className="badge" style={{fontSize:"14px",fontWeight:500, padding:'1.5px 4.5px'}}>{this.props.promotion.Age + "+"}</div>
//                             }
//                         </div>
//                         <div className="selected-discover-text-wrapper">
//                             <div className="purple-line"></div>
//                             <div className="selected-discover-name">{this.props.promotion.Name}</div>
//                             <div className="selected-discover-promotion">{this.props.promotion.Promotion}</div>
//                             <div className="selected-discover-price-wrapper">
//                                 <div className="selected-discover-ad-original-price">{this.props.promotion.OriginalPrice+"₪"}</div>
//                                 <div className="selected-discover-ad-price">{"₪"+this.props.promotion.Price}</div>
//                             </div>
//                         </div>
                        
//                     </div>
//                 </NavLink>:
//                 <NavLink to={{
//                     pathname: `/Promotion/${this.props.promotion.PromotionId}`,
//                     promotion:this.props.promotion
//                     }}  
//                 className="discover-ad">
                    
//                     <div className="discover-ad-image" style={bgImg}></div>
                    
//                     <div className="discover-info-container">
//                         <div className="flex-row hover-badges" style={{top:'-27px'}}>

//                             <div className="badge first-badge" style={{fontSize:"14px",fontWeight:500, padding:'1.5px 4.5px'}}>
//                                 <img className="badge-icon" src={TimeLeftIcon} alt="time left icon"/>
//                                 {UI.getTimeLeft(this.props.promotion.ExpTimeStamp)}
//                             </div>
//                                 {
//                                     this.props.promotion.Age==="0"?
//                                     <></>:
//                                     <div className="badge" style={{fontSize:"14px",fontWeight:500, padding:'1.5px 4.5px'}}>{this.props.promotion.Age + "+"}</div>
//                                 }
//                         </div>

//                         <div className="flex-row space-between" style={{width:'100%'}}>
//                             <div className="discover-ad-name">{this.props.promotion.Name}</div>
//                             <div className="discover-ad-name">{"₪"+this.props.promotion.Price}</div>
//                         </div>
                        
//                         <div className="discover-ad-promotion">{this.props.promotion.Promotion}</div>

//                     </div>

//                 </NavLink>
//             }
//          </>   
//         )
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return{
//         resetArchive: () => dispatch(resetArchive()),
//         openArchivedPurchase: () => dispatch(openArchivedPurchase()),
//     }
// }

// const mapStateToProps = (store) => {
//     return{
//         archivePurchases: store.purchases.archivePurchases
//     }
// }

// export default connect (mapStateToProps,mapDispatchToProps) (DiscoverAd);