import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Route,
    Switch
  } from "react-router-dom";

//images
import shareIconWhite from '../../img_resources/Feed/share-white.svg'
import shareIconGrey from '../../img_resources/Promotion Page/share-grey.svg'

//actions
import {setForm} from '../../actions/formActions'

import UI from '../../ui_functions'

const ShareBtn = ({promotion}) => {

    const dispatch = useDispatch();   
    const userId = useSelector(store=>store.user.user.username);

    const share = () => {
        if (navigator.share) {
            navigator.share({
              title: "Share this promotion with your friends!",
              text: UI.createShareText(),
              url: UI.createShareText() + UI.createShareLink(promotion, userId),
            })
              .then(() => console.log('Successful share'))
              .catch((error) => {
                dispatch(setForm(promotion, "SHARE", "Share this promotion with your friends!"));
                console.log('Opening Share Form', error)
              });
        } else {
            dispatch(setForm(promotion, "SHARE", "Share this promotion with your friends!"));
        }
    }

    return(         
        <Switch>
            <Route path="/Promotion">
                <img 
                    className="icon smaller" 
                    src={shareIconGrey}
                    onClick={()=>{
                        share();
                        // dispatch(setForm(promotion, "SHARE", "Share this promotion with your friends!"));
                    }}
                    alt="share icon" />
            </Route>
            <Route path="/">
                <img 
                    src={shareIconWhite}
                    alt="share icon"
                    className="new-share-button" 
                    onClick={(e)=>{
                        e.stopPropagation();
                        share();
                        // dispatch(setForm(promotion, "SHARE", "Share this promotion with your friends!"));
                }}/>
            </Route>
        </Switch>
    )
}

export default ShareBtn;