import React, {useState, useEffect } from 'react';

//components
import DiscoverAd from './Ad Blocks/DiscoverAd';

import UI from '../ui_functions/index.jsx';

const DiscoverRow =({tag, selectTag, promotions, mostPopular}) => {

    const [promotionsToRender, setPromotionsToRender] = useState([]);

    //filters promotions based on tags
    useEffect(()=>{
        let arr = UI.filterPromotionsByTag(promotions, tag);
        setPromotionsToRender(arr)
    },[promotions])
    
    return(
        <>
        {
            promotionsToRender.length > 0?

            <div className="discover-row">
                <div className="flex-row space-between" style={{width:'100%'}} onClick={()=>{selectTag(tag)}}>
                    <h2 className="discover-row-tag">
                        {
                            mostPopular===tag?
                                "Most popular tonight":
                                "#"+tag
                        }
                    </h2>
                    <div className="discover-back">view all</div>
                </div>
                <div className="discover-row-ads">
                {
                    promotionsToRender.map(promotion=>
                        <DiscoverAd key={promotion.PromotionId} promotion={promotion} isTagActive={false} />
                    )
                }
                </div>
            </div>:
            <></>
        }
        </>
        

    )
}

export default DiscoverRow;