import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {queryStringToJSON, transformQueryPath} from './../../screens/utils';

import {setForm} from '../../actions/formActions'

//images
import zesttyIcon from '../../img_resources/Logos/logo-color.svg'
import chromeIcon from '../../img_resources/Logos/chrome.svg'

const RedirectShare = ({promotion}) => {
    let history = useHistory();
    let location = useLocation();
    let dispatch = useDispatch();

    let locationSearch = `${location.search || ''}${location.hash || ''}`
    let query = locationSearch ? queryStringToJSON(locationSearch) : '';
    const userAgent = window.navigator.userAgent;

    let redirectLocation = transformQueryPath(query);

    const onOptionClick = opt => {
        if(opt === 'browser') {
            history.push({pathname: "/" + redirectLocation.path, search: redirectLocation.search});
            dispatch(setForm({}, "Hide",""))
        }
        else {
            if (userAgent.match(/Android/i)) {
                // window.location.href = `intent://#Intent;package=com.zesttygroup.getzestty;scheme=getzestty://${redirectLocation.url};launchFlags=268435456;end;`;
                // window.opener = null;
                // window.open('', '_self');
                // window.close();
                navigateAppLink("https://play.google.com/store/apps/details?id=com.zesttygroup.getzestty", redirectLocation.url);

            } else if (userAgent.match(/iPhone/i)){
                navigateAppLink("https://apps.apple.com/il/app/id1536828440", redirectLocation.url);
            } else {
                navigateAppLink("https://play.google.com/store/apps/details?id=com.zesttygroup.getzestty");
            }
        }
    }

    const navigateAppLink = (link, url) => {
        let now = new Date().valueOf();
        let finalUrl =  `getzestty://${url}`;
        window.location = finalUrl;

        // getzestty://Promotion/123

        setTimeout(() => {
            if(new Date().valueOf() - now > 20000) return;
            window.location = link;
            window.opener= null;
            window.open('','_self');
            window.close();
        }, 5000);
    }

    return(         
        <div className="redirect-wrapper" >

            <div onClick={() => onOptionClick('browser')} className="redirect-row">
                <img src={chromeIcon} alt="App Icon" style={{borderRadius:'48px', width:'40px', height:'40px', marginRight:'15px'}}/>
                Browser
            </div>
            <div onClick={() => onOptionClick('app')} className="redirect-row reversed">
                <img src={zesttyIcon} alt="App Icon" style={{borderRadius:'48px', width:'40px', height:'40px', marginRight:'15px'}}/>

                Get Zestty App
            </div>

        </div>
    )
}


export default RedirectShare;