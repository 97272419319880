import React from 'react';

import {useDispatch} from 'react-redux';

//actions
import {toggleFiltersShow} from '../actions/filterActions';

//images
import filterIcon from '../img_resources/Shared Components/Search Bar/filter.svg'

const Title = ({text, filtersButton}) => {
    const dispatch = useDispatch();
        return(
            <div className="new-header">
                <h2 className="new-header-text">{text}</h2>
                {
                    filtersButton?
                    <div className="new-filter-icon" style={{backgroundImage:`url(${filterIcon})`}} onClick={() => {dispatch(toggleFiltersShow())}}/>    :
                    <></>
                }

            </div>
            
        )
}

export default Title;