import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

//images
import whatsappIcon from '../../img_resources/Forms/Share/whatsapp.svg'
import messengerIcon from '../../img_resources/Forms/Share/messenger.svg'
import twitterIcon from '../../img_resources/Forms/Share/twitter.svg'
import linkIcon from '../../img_resources/Forms/Share/link.svg'

//actions
import {setFormText} from '../../actions/formActions'

import {setModal} from '../../actions/popupModalActions'
import errorImg from '../../img_resources/Popup Modal/credit-card-error.svg'

import UI from '../../ui_functions'

const Share = ({promotion}) => {

    const dispatch = useDispatch();

    const userId = useSelector(store=>store.user.user.username);

    // const createShareLink = (promotion, userId) =>{
    //     let shareLink = "";
    //     try{
    //         shareLink = "https://www.getzestty.com/redirect?path=Promotion&value="+ promotion.PromotionId + "&refId=" + userId+"#" + promotion.PromotionId;
    //         // shareLink = "https://www.getzestty.com/Promotion/"+ promotion.PromotionId + "?refId=" + userId+"#" + promotion.PromotionId;
    //     } catch (err){ //getzestty://Advertiser/AdvertiserID?PromotionID=dfsafda&userId=safdaf
    //     }
    //     return shareLink;
    // }


    // const createShareText = (promotion) => {
    //     let text="";
    //     try{
    //         text = "Check it out! \n"+ promotion.Name +" is offering "+promotion.Promotion + " for only " + promotion.Price;
    //     } catch (err){

    //     }
    //     return text;
    // }

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      };

    const createWhatsappLink = () =>{
        const link = "https://wa.me/?text=Check it out! %0a"+encodeURIComponent(promotion.Promotion) +" for only ₪"+ promotion.Price + " at "+ promotion.Name+" %0a" +encodeURIComponent(UI.createShareLink(promotion, userId))
        console.log(link)
        return link;
    }

    return(         
        <>
        {
            <div className="share-icons-wrapper" style={{paddingBottom:'5vh'}}>
                <a 
                    href={createWhatsappLink()} 
                    className="share-icon-wrapper" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    onClick={(e)=>{e.stopPropagation()}} 
                >
                    <img src={whatsappIcon} alt="whatsapp Icon" className="share-icon" />
                </a>
                <a 
                    href={"fb-messenger://share?link="+encodeURIComponent(UI.createShareLink(promotion, userId))+"&appId=2247582952010425"} 
                    className="share-icon-wrapper" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={(e)=>{e.stopPropagation()}} 
                    >
                    <img src={messengerIcon} alt="messenger Icon" className="share-icon"/>
                </a>
                <a 
                    href={"https://twitter.com/intent/tweet?text="+UI.createShareText(promotion)+"&url="+encodeURIComponent(UI.createShareLink(promotion, userId))} 
                    className="share-icon-wrapper" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={(e)=>{e.stopPropagation()}} 
                    >
                    <img src={twitterIcon} alt="twitter Icon" className="share-icon"/>
                </a>
                <div 
                    className="share-icon-wrapper" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={(e)=>{
                        e.stopPropagation();
                        copyToClipboard(UI.createShareLink(promotion, userId));
                        dispatch(setFormText("Link copied!"))
                    }} 
                    >
                    <img src={linkIcon} alt="link Icon" className="share-icon"/>
                </div>
            </div>
        }
        </>
    )
}


export default Share;