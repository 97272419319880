const initialState = {
    user: {
        // username: "testing"
    },
    likes: [],
    tokens: [],
    geoLocation: {
        lat: null,
        lng: null
    },
    city: null,
    country: null,
    refId: undefined,
    checkingUser: false,
    isVerified: false,
    loading: false,
    alreadyCheckedUser: false,
    loadingData: false,
    likeLoader: false
}

export const userReducer = (state = initialState, action) => {

    switch (action.type) {


        case "LOGIN_STARTED":
            return {
                ...state,
                user: {},
                loading: true
            }

        case "LOGIN_SUCCESS":
            let loading = false;
            if (action.user === {}) {
                loading = true
            }
            return {
                ...state,
                user: action.user,
                err: null,
                loading: loading,
                checkingUser: false
            }

        case "LOGIN_FAILED":
            return {
                ...state,
                err: action.err,
                user: {},
                loading: false
            }

        case "SIGN_OUT_STARTED":
            return {
                ...state,
                loading: true
            }

        case "SIGN_OUT_SUCCESS":
            return initialState


        case "SIGN_OUT_FAIL":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case 'CHECK_USER_STARTED':
            return {
                ...state,
                checkingUser: true,
                loading: true
            }

        case 'CHECK_USER_FAILED':
            return {
                ...state,
                err: action.err,
                loading: false,
                checkingUser: false,
                alreadyCheckedUser: true
            }

        case 'FETCHING_USER_DATA_STARTED':
            return {
                ...state,
                loadingData: true
            }

        case 'FETCHING_USER_DATA_SUCCESS':
            let data = JSON.parse(action.data);
            let tokensList = [];
            data.tokens.forEach(tokenString => {
                    let token = {};
                    let tokenArr = tokenString.split("#");

                    token.number = tokenArr[0];
                    token.expDate = tokenArr[1];
                    token.ccType = tokenArr[2];

                    tokensList.push(token)
                })
                // let isVerified = false;
                // if (data.hasOwnProperty("isVerified"))
            let res = {
                ...state,
                tokens: tokensList,
                likes: data.advertiserIds,
                firstTime: data.firstTime,
                zCredits: data.zCredits,
                refId: data.refId,
                loadingData: false,
                amountSaved: data.amountSaved,
                isVerified: !!data.isVerified ? true : false
            }

            // res = !data.isVerified ? {...res, 'isVerified': data.isVerified } : res;

            // console.log(res)

            return res;

        case 'FETCHING_USER_DATA_FAILED':
            return {
                ...state,
                err: action.err,
                loadingData: false
            }

        case 'GET_USER_DATA_STARTED':
            return {
                ...state,
                loadingData: true
            }

        case 'GET_USER_DATA_SUCCESS':

            let userData = JSON.parse(action.data);
            let userTokensList = [];
            userData.tokens.forEach(tokenString => {
                let token = {};
                let tokenArr = tokenString.split("#");

                token.number = tokenArr[0];
                token.expDate = tokenArr[1];
                token.ccType = tokenArr[2];

                userTokensList.push(token)
            })
            let response = {
                ...state,
                tokens: userTokensList,
                likes: userData.advertiserIds,
                firstTime: userData.firstTime,
                isVerified: !!userData.isVerified ? true : false,
                PhoneNo: !!userData.PhoneNo ? userData.PhoneNo : undefined,
                zCredits: userData.zCredits,
                loadingData: false
            };

            return response

        case 'GET_USER_DATA_FAILED':
            return {
                ...state,
                err: action.err,
                loadingData: false
            }

        case 'DELETE_TOKEN_STARTED':
            return {
                ...state,
                loadingData: true
            }

        case 'DELETE_TOKEN_SUCCESS':
            return {
                ...state,
                tokens: action.tokens,
                loadingData: false
            }

        case 'DELETE_TOKEN_FAILED':
            return {
                ...state,
                err: action.err,
                loadingData: false
            }

        case "POST_LIKE_STARTED":
            return {
                ...state,
                likeLoader: true
            }

        case "POST_LIKE_SUCCESS":
            let newLikeList = state.likes;
            return {
                ...state,
                likes: newLikeList.concat(action.advertiserId),
                likeLoader: false
            }

        case "POST_LIKE_FAILED":
            return {
                ...state,
                err: action.err,
                likeLoader: false
            }

        case "DELETE_LIKE_STARTED":
            return {
                ...state,
                likeLoader: true
            }

        case "DELETE_LIKE_SUCCESS":
            let shortLikeList = state.likes;
            let indexToRemove = shortLikeList.indexOf(action.advertiserId);
            console.log("REMOVING ", shortLikeList[indexToRemove])
            shortLikeList = shortLikeList.slice(0, indexToRemove).concat(shortLikeList.slice(indexToRemove + 1, shortLikeList.length))
            return {
                ...state,
                likes: shortLikeList,
                likeLoader: false
            }

        case "DELETE_LIKE_FAILED":
            return {
                ...state,
                err: action.err,
                likeLoader: false
            }

        case "SET_FIRST_TIME_STARTED":
            return {
                ...state
            }

        case "SET_FIRST_TIME_SUCCESS":
            return {
                ...state,
                firstTime: action.firstTime
            }

        case "SET_FIRST_TIME_FAIL":
            return {
                ...state,
                err: action.err
            }

        case "SET_LOCATION":
            return {
                ...state,
                geoLocation: {
                    lat: action.lat,
                    lng: action.lng
                },
                city: action.city,
                country: action.country
            }

        default:
            return state;
    }
}