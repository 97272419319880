//libraries
import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

//components
import FeedAdFooter from '../FeedAdFooter';
import FeedAdImage from '../FeedAdImage';

//actions
import {fetchPromotions} from '../../actions/promotionsActions';

import UI from '../../ui_functions'

const FeedAd = ({promotion}) => {
   
    const dispatch = useDispatch();

    const lastEvaluatedKey = useSelector(store=>store.promotions.lastEvalKey);
    const promotions = useSelector(store=>store.promotions.promotions);
    const filters = useSelector(store=>store.filter);

    //fetches the next batch of promotions
    const onChange = (isVisible) => {
        if(isVisible){
            dispatch(fetchPromotions(filters.city.value, true, filters.name, filters.age, filters.tag, lastEvaluatedKey, promotions))
        }
    }

    return(
        <>
        {
            <VisibilitySensor onChange={onChange} active={UI.checkLastValuatedKey(promotion, lastEvaluatedKey)}>
                <div className="new-feed-ad">
                    <Fade duration={750}>
                        <FeedAdImage key={promotion.PromotionId} promotion={promotion}/>
                    </Fade>
                    
                    <Fade bottom delay={250}>
                        <FeedAdFooter key={"footer-"+promotion.PromotionId} promotion={promotion}/>
                    </Fade>
                </div>
            </VisibilitySensor>
        }
        </>
    )
    
}

export default FeedAd;