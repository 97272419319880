//libraries
import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
// import Lottie from 'react-lottie';
import Fade from 'react-reveal'

//components
import Title from '../components/Title';
import PurchasedAd from '../components/Ad Blocks/PurchasedAd';
import Loader from '../components/Loader';

//actions

//images
import cartoonFood from '../img_resources/Purchases/cute food.svg'

const PurchasedFeed =() => {

    const activePurchases = useSelector(store=>store.purchases.active)
    // const archivePurchases = useSelector(store=>store.purchases.archive)
    const purchasesLoading = useSelector(store=>store.purchases.loading)


    //   if(purchasesLoading){
    //     return <Loader/>;
    // } else {
        return(
            <Fade bottom>
                <div className="new-container" id="purchased">
                    {
                        purchasesLoading?
                        <Loader/>:
                        <div className="content-wrapper" style={{display:'flex', flexDirection:'column'}}>
                            {
                                activePurchases.length>0?
                                    <Title text={"Your purchases"}/>:
                                    <></>
                            }
                            {
                                activePurchases.length>0?
                                    activePurchases.map(purchase=>(
                                        <PurchasedAd key={purchase.RelatedPromotion.PromotionId} promotion={purchase.RelatedPromotion} active={true} purchase={purchase}/>
                                    )):
                                    <>
                                        <img src={cartoonFood} alt="presents" className="not-active-purchases"/>
                                        <h2 className="instructions-title" style={{width:'90vw'}}>It seems you don't have any purchases to collect</h2>
                                        <NavLink to={'/Feed'} className="feed-button" style={{textDecoration:"none", marginTop:'30px'}}>
                                            Find Zestty Deals!
                                        </NavLink>
                                    </>
                            }
                        </div>
                    }
                    
                </div>
            </Fade>
        )
    // }
}
export default PurchasedFeed;