export function setModal(modalType, title, text, img) {
    return {
        type: "SET_MODAL",
        modalType: modalType,
        title: title,
        text: text,
        img: img
    }
}

export function hideModal() {
    return {
        type: "HIDE_MODAL"
    }
}

export function showPaymentModal() {
    return {
        type: "SHOW_PAYMENT_METHOD_MODAL"
    }
}

export function hidePaymentModal() {
    return {
        type: "HIDE_PAYMENT_METHOD_MODAL"
    }
}

export function selectToken(token) {
    return {
        type: "SELECT_TOKEN",
        token: token
    }
}