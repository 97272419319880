import React from 'react';

function queryStringToJSON(qs) {
    qs = qs;

    var pairs = qs.split('&');
    var result = {};
    pairs.forEach(function(p) {
        var pair = p.split('=');
        var key = pair[0];
        var value = decodeURIComponent(pair[1] || '');

        if( result[key] ) {
            if( Object.prototype.toString.call( result[key] ) === '[object Array]' ) {
                result[key].push( value );
            } else {
                result[key] = [ result[key], value ];
            }
        } else {
            result[key] = value;
        }
    });

    return JSON.parse(JSON.stringify(result));
};

const transformQueryPath = query => {
    let url = '';
    let path = '';
    let search = '';
    if(query) {
        path = `${query["?path"] || '' }${query["?path"] && '/' || ''}${query["value"] || ''}`;
        delete query["?path"];
        delete query["value"];

        let queryKeys = Object.keys(query);
        search += `${queryKeys.reduce((acc, currKey, index) => {
            acc += `${currKey}=${query[currKey]}${index < queryKeys.length - 1 ? '&' : ''}`;
            return acc;
        }, '?')}`;

        url = path + search;
    }
    
    return {url, path, search};
}

export {queryStringToJSON, transformQueryPath};