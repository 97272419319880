import React, {useState} from 'react';
import {NavLink} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import {Auth} from 'aws-amplify'


//images
// import WhiteLogo from '../img_resources/Logos/logo-white.png'
import colorLogo from '../img_resources/Logos/logo-color.png'
import colorLogoNew from '../img_resources/Logos/GZ-Logo.png'
import menuIcon from '../img_resources/Shared Components/Side Menu/menu.svg'
import logOutIcon from '../img_resources/Shared Components/Side Menu/logout.svg'
import instructionsIcon from '../img_resources/Shared Components/Side Menu/instructions.svg'
import contactIcon from '../img_resources/Shared Components/Side Menu/contact.svg'
import FAQIcon from '../img_resources/Shared Components/Side Menu/FAQ.svg'
import closeIcon from '../img_resources/Shared Components/Side Menu/close.svg'
import filtersIcon from '../img_resources/Shared Components/Side Menu/filters-grey.svg'
import userIcon from '../img_resources/Shared Components/Side Menu/user-menu.svg'
import historyIcon from '../img_resources/Shared Components/Side Menu/history.svg'
import ccIcon from '../img_resources/Shared Components/Side Menu/payment-method.svg'

//actions
import {setForm} from '../actions/formActions'
import {toggleFiltersShow} from '../actions/filterActions'
import {logOut} from '../actions/userActions'
import { showPaymentModal } from '../actions/popupModalActions';

const Header =() => {

    const dispatch = useDispatch()

    const [showMenu, setShowMenu] = useState(false);

    const user = useSelector(store=>store.user.user);

    const getProfileImg = () =>{
        try{
            if(user.username.includes("acebook")){
                return JSON.parse(user.user.attributes.picture).data.url
            } else {
                return user.attributes.picture
            }
        } catch {
            return userIcon
        }
    }

    return(
        <>
            <div className="banner" >
                {/* <img src={(colorLogo)} alt="Zesty Logo" className="login-logo"/> */}
                <img src={(colorLogoNew)} alt="Zesty Logo" className="login-logo"/>
                <img src={(menuIcon)} alt="menu" className="menu-btn" onClick={()=>{setShowMenu(true)}}/>
            </div>
            <div className="menu-overlay" style={showMenu?{width:'100vw'}:{width:'0px'}} onClick={()=>{setShowMenu(false)}}>
                <div className="menu" style={showMenu?{width:'68.5vw', padding:'19vh 50px'}:{width:'0px', padding:'25vh 0px'}}>
                    <img src={closeIcon} alt="close-menu" className="menu-close" onClick={()=>{setShowMenu(false)}} />
                    {
                        user.hasOwnProperty("username")?
                        <img src={getProfileImg()} alt="user profile" className="menu-user-profile-img"/>:
                        <></>
                    }
                    
                    <NavLink 
                        to={`/Instructions`} 
                        className="menu-option" 
                        onClick={()=> {
                            setShowMenu(false)
                        }}
                        >
                        <img src={instructionsIcon} alt="instructions icon" className="menu-option-icon"/>
                        <div>Instructions</div>
                    </NavLink>
                    
                    {
                        user.hasOwnProperty("username")?
                        <div className="menu-option" onClick={() => {dispatch(toggleFiltersShow())}}>
                            <img src={filtersIcon} alt="Filter icon" className="menu-option-icon"/>
                            <div>Preferences</div>
                        </div>:
                        <></>
                    }

                    {
                        user.hasOwnProperty("username")?
                        <NavLink 
                            to={`/Profile`} 
                            className="menu-option" 
                            onClick={()=> {
                                setShowMenu(false)
                            }}
                            >
                            <img src={userIcon} alt="user profile icon" className="menu-option-icon"/>
                            <div>Profile</div>
                        </NavLink>:
                        <></>
                    }

                    {
                        user.hasOwnProperty("username")?
                        <NavLink 
                            to={`/PurchaseHistory`} 
                            className="menu-option" 
                            onClick={()=> {
                                setShowMenu(false)
                            }}
                            >
                            <img src={historyIcon} alt="history icon" className="menu-option-icon"/>
                            <div>History</div>
                        </NavLink>:
                        <></>
                    }

                    {
                        user.hasOwnProperty("username")?
                        <button className="menu-option" onClick={()=>{dispatch(showPaymentModal())}}>
                            <img src={ccIcon} alt="payment icon" className="menu-option-icon"/>
                            <div>Payment Methods</div>
                        </button>:
                        <></>
                    }
                    
                    <NavLink 
                        to={`/FAQ`} 
                        className="menu-option" 
                        onClick={()=> {
                            setShowMenu(false)
                        }}
                        >
                        <img src={FAQIcon} alt="FAQ icon" className="menu-option-icon"/>
                        <div>FAQ</div>
                    </NavLink>
                    
                    <a className="menu-option" href={"mailto:info@getzestty.com?Subject=New%20Message%20from%20Get%20Zestty"}>
                        <img src={contactIcon} alt="contact icon" className="menu-option-icon"/>
                        <div>Contact Us</div>
                    </a>
                    
                    {
                        user.hasOwnProperty("username")?
                        <div className="menu-option" onClick={() => {Auth.signOut()}}>
                            <img src={logOutIcon} alt="Logout icon" className="menu-option-icon"/>
                            <div>Logout</div>
                        </div>:
                        <></>
                    }
                    
                </div>
            </div>
            
            
        </>
        
    )
}

export default Header;