import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

//actions
import {deleteToken} from '../actions/userActions';
import {selectToken} from '../actions/popupModalActions';

//images
import visa from '../img_resources/Forms/Purchase/visa.png'
import masterCard from '../img_resources/Forms/Purchase/mastercard.svg'
import amex from '../img_resources/Forms/Purchase/amex.png'
import diners from '../img_resources/Forms/Purchase/diners.jpg'
import trash from '../img_resources/Forms/Purchase/trash.svg'
import trashWhite from '../img_resources/Forms/Purchase/trash-white.svg'

import blackLoader from '../img_resources/Loaders/black-loader.svg'

// const Token = ({token, selectToken, selected}) => {
const Token = ({token}) => {

    const dispatch = useDispatch();

    const userId = useSelector(store=>store.user.user.username);
    const userLoader = useSelector(store=>store.user.loadingData);
    
    const selectedToken = useSelector(store=>store.popupModal.selectedToken);

    const ccImgSelector = (ccType) => {
        switch (ccType) {
            case "VISA":
                return visa
                
            case "MASTERCARD":
                return masterCard

            case "AMEX":
                return amex

            case "DINERS":
                return diners

            case "MAESTRO":
                return "MAESTRO LOGO"
            
            default:
                break;
        }
    }
    
    return(
        <div key={"token-"+token.number} className="cc-container" 
            style={{backgroundColor:selectedToken===token?"#564CAC":""}} 
            onClick={()=>{
                    if(selectedToken===token){
                        dispatch(selectToken(null))
                    } else {
                        dispatch(selectToken(token))
                    }
                }}> 

            <div className="cc-type-container">
                <img 
                    src={ccImgSelector(token.ccType)} 
                    alt="credit card type"
                    className="cc-type"/>
            </div>
            

            <div className="cc-details">
                <p className="four-digits" style={{color:selectedToken===token?"white":"black"}} > 
                    **** - {token.number}
                </p>
                <p className="exp-date" style={{color:selectedToken===token?"white":"#AEAEAE"}}>
                    {token.expDate}
                </p>
            </div>
            
            <img 
                src={
                    userLoader?
                    blackLoader:
                        selectedToken===token?
                        trashWhite:
                        trash
                }
                alt="delete payment method" 
                className="delete-cc" 
                onClick={(e)=>{
                    e.stopPropagation(); 
                    dispatch(deleteToken(userId, token))
                }} 
            />
        </div>
    )
}

export default Token;
