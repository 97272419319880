import React from 'react';

//Components
import LikeBtn from './Buttons/LikeBtn';
import DirectionsBtn from './Buttons/DirectionsBtn';
import PurchaseBtn from './Buttons/PurchaseBtn';

//images 
import TagsIcon from '../img_resources/Feed/tags.svg'
import TimeLeftIcon from '../img_resources/Feed/time-white.svg'
import LiveIcon from '../img_resources/Feed/live.svg'

import UI from '../ui_functions/index.jsx'

const FeedAdFooter = ({promotion}) => {
    
        return(
            <div className="new-feed-ad-footer">
                <div className="flex-row hover-badges">
                    <div className="badge first-badge">
                        <img className="badge-icon" src={TimeLeftIcon} alt="time left icon"/>
                            {UI.getTimeLeft(promotion.ExpTimeStamp)}
                        </div>
                        {
                            promotion.Age===0?
                                <></>:
                                <div className="badge">{promotion.Age + "+"}</div>
                        }
                </div>
                {
                    promotion.Live?
                    <img className="live-indicator" src={LiveIcon} alt="live icon"/>:
                    <></>    
                }
                
                <div className="flex-row space-between">
                    <h3 className="new-ad-name">{promotion.Name}</h3>
                    <div className="flex-row">
                        <LikeBtn promotion={promotion}/>    
                        <DirectionsBtn address={promotion.Address} />
                        {/* <PurchaseBtn promotion={promotion} purchase={{}}/> */}
                        <PurchaseBtn promotion={promotion}/>
                    </div>
                </div>

                <p className="new-promotion-text">{promotion.Promotion}</p>

                <div className="flex-row" style={{alignItems:"center", marginTop:'11px'}}>
                    <img src={TagsIcon} alt="tags icon" className="new-icon"/>
                    <div className="new-feed-ad-tags">
                        {
                            promotion.Tags.map(tag => (
                                <div key={promotion.PromotionId+tag} className="new-feed-tag">{tag}</div>
                            ))
                        }
                    </div>
                </div>

                <div className="flex-row space-between" style={{alignItems:"center", marginTop:'11px'}}>
                    <div className="flex-row">
                        <p className="amount-left">{promotion.Quantity} Left</p>
                        <div className="amount-left-bar-wrapper">
                            <div className="amount-left-fill" 
                                style={window.innerWidth>=768?
                                {width:(((promotion.Quantity/promotion.StartingQuantity))*72)+'px'}:
                                {width:(((promotion.Quantity/promotion.StartingQuantity))*20)+'vw'}}/>
                        </div>
                    </div>
                    <div className="flex-row">
                        
                        {/* <p className="new-original-price">{"₪"+promotion.OriginalPrice}</p> */}

                        <p className="new-original-price-percent">{"SAVE "+(100-Math.round(100*promotion.Price/promotion.OriginalPrice))+"%"}</p>

                        <p className="new-price">{"₪"+promotion.Price}</p>
                    </div>
                </div>

            </div>
        )
}

export default FeedAdFooter;