import React from 'react';
import Fade from 'react-reveal'
//images
import FAQimg from '../img_resources/FAQ/FAQ.jpg'

import {FAQs} from '../consts/FAQ'
import Question from '../components/Question';

const FAQ  = () => {

    return(         
        <Fade bottom>
        {
            <div className="new-container" id="faq">
                <div className="content-wrapper" >
                    <img style={{width:"80%", height:"auto"}} src={FAQimg} alt="FAQ"/>
                    {
                        FAQs.map(QNA=>(
                            <Question question={QNA.question} answer={QNA.answer}/>
                        ))
                    }
                </div>
            </div>
        }
        </Fade>
    )
}

export default FAQ;