import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {NavLink} from 'react-router-dom'
import Lottie from 'react-lottie';
import Fade from 'react-reveal'

//components
import Title from '../components/Title';
import FeedAd from '../components/Ad Blocks/FeedAd';
import Loader from '../components/Loader';

//actions
import {fetchPromotions, fetchSavedPromotions} from '../actions/promotionsActions'

//images
import EmptyLikesAnimation from '../img_resources/Animations/Growth Animation.json'

import UI from '../ui_functions/index.jsx';

const LikedFeed = (props) => {

    const dispatch = useDispatch();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: EmptyLikesAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    const [filteredPromotions, setFilteredPromotions] = useState([]);
    const [marginTop, setMarginTop] = useState('15vh');
    const [lastScrollY, setLastScrollY] = useState(0);

    const promotions = useSelector(store=>store.promotions.promotions);
    const likeList = useSelector(store=> store.user.likes);
    const loadingPromotions = useSelector(store=>store.promotions.loading);
    const fetchedLikes = useSelector(store=> store.promotions.fetchedLikes);
    const loadingLikes = useSelector(store=>store.user.loading);

    const filters = useSelector(store=>store.filter);

    useEffect(()=>{
        setFilteredPromotions(
            UI.filterOutOfStockAds(
                UI.runFilters(
                    UI.getLiked(promotions, likeList),
                    filters.tag, 
                    filters.age, 
                    filters.name, 
                    filters.live,
                    ""
                )
            )
        )
    }, [promotions, filters, likeList])

    useEffect(()=> {
        if(!fetchedLikes){
            dispatch(fetchSavedPromotions(likeList, promotions))
        }
    },[])

    // handles the Y-axis scroll of the page
    const handleScroll = e => {
        
        const currentScrollY = e.target.scrollTop;

        if (lastScrollY > currentScrollY && marginTop!=='15vh') {
            setMarginTop('15vh')
        } else {
            setMarginTop('0vh')
        }
        setLastScrollY(currentScrollY)
    };
    
    // if((loadingPromotions || loadingLikes) && promotions.length===0){
    //     return <Loader/>;
    // } else {
        return(
            <Fade bottom>
                <div className="new-container" id="liked" style={{marginTop:marginTop}}>
                    {
                        (loadingPromotions || loadingLikes) && promotions.length===0?
                        <Loader/>:
                        <div className="content-wrapper">
                            <Title/>
                            {
                                filteredPromotions.length>0?
                                filteredPromotions.map(promotion=>
                                    <FeedAd key={promotion.PromotionId} promotion={promotion}/>
                                    ):
                                <div className="empty-feed-background">
                                    <div className="spacer"></div>
                                    <Lottie options={defaultOptions} width={"100vw"} height={"auto"}/>
                                    {/* <img src={EmptyLikesAnimation} alt="discovering" className="instructions-image"/> */}
                                    <h2 className="instructions-title" style={{width:'90vw'}}>It seems you haven't liked anything yet</h2>
                                    
                                    <NavLink to={'/Feed'} className="instructions-next-button" style={{color:"white", backgroundColor:"#564CAC", textDecoration:"none"}}>
                                        Check out the feed!
                                    </NavLink>
                                </div>
                            }
                        </div>
                    }
                    
                </div>
            </Fade>
        )
    // }
    
}

export default LikedFeed;