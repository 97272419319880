import React from 'react'

//images
import welcomeImage from '../img_resources/instructions/New/welcome.svg'
import scroll from '../img_resources/instructions/New/scroll.svg'
import search from '../img_resources/instructions/New/search.svg'

const welcometoGetZestty = () => {
    return(
        <>
            <img src={welcomeImage} alt="welcome" className="welcome-img"/>
            <h1 className="instructions-title-new">Welcome to Get Zestty!</h1>
            <p className="instructions-text-new">Let's show you around with a quick guide.</p>
        </>
    )
}

const scrollThrough = () => {
    return(
        <>
            <div className={"flex-col"}>
                <img src={scroll} alt="scroll" className="instructions-img"/>
                <h1 className="instructions-title-new">Scroll through countless deals!</h1>
                <p className="instructions-text-new">Easily discover countless real-time promotions and great deals in your city.</p>
            </div>
            
        </>
    )
}

const searchDeals = () => {
    return(
        <>
            <div className={"flex-col"}>
                <img src={search} alt="search" className="instructions-img"/>
                {/* <h1 className="instructions-title-new">Scroll through countless deals!</h1> */}
                <p className="instructions-text-new">Search based on what you are looking for. You can filter by city, tags, establishment name and even age.</p>
            </div>
            
        </>
    )
}

const instructionsSteps = [
    {
        selector: '', 
        content: welcometoGetZestty()
    },
    {
        selector: '.new-feed-ad', 
        content: scrollThrough()
    },
    {
        selector: '.new-search-div', 
        content: searchDeals()
    },
    {
        selector: '.new-ad-name', 
        content: "This indicates who's offering the deal"
    },
    {
        selector: '.new-share-button', 
        content: 'Share with your friends to earn Z-Credits! Z-credits can be used to further reduce the prices of deals.'
    },
    {
        selector: '.test-purchase-btn', 
        content: 'Secure your deal and head out to get Zestty!'
    },
    {
        selector: '#purchases-link', 
        content: 'To find your purchases and have them scanned, you can visit your active purchases page.'
    },
    {
        selector: '.like-btn', 
        content: 'Enjoyed yourself? Save the business and get notified when the post new deals!'
    }
]


export default instructionsSteps