//external libraries
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Rotate from 'react-reveal'
import {NavLink} from 'react-router-dom'

//components
import Token from '../components/Token'
import NewTokenBtn from '../components/Buttons/NewTokenBtn'

//actions
import {hidePaymentModal, selectToken} from '../actions/popupModalActions'

const PaymentMethodsPopupModal = (props) => {
    const dispatch = useDispatch();

    const popupModal = useSelector(store=>store.popupModal);
    const tokens = useSelector(store=>store.user.tokens);
    const selectedToken = useSelector(store=>store.popupModal.selectedToken);
    const form = useSelector(store=>store.form);

    useEffect(()=>{
        
    },[])

    return( 
        <div className="popup-modal-wrapper" style={{display:popupModal.showPaymentModal?"flex":"none", justifyContent:"center"}}>
            <Rotate bottom when={popupModal.showPaymentModal} collapse>
                <div className="popup-modal" style={{maxHeight:"50vh",justifyContent:"flex-start", overflow:"auto" }}>
                    {
                        tokens.length>0?
                        <h1 className="popup-modal-title" >Select Payment Method</h1>:
                        <></>
                    }
                    
                    {
                        tokens.map(token=>(
                            <Token key={"token-"+token.number} token={token} />
                        ))
                    }
                    
                    <h2 className="form-second-title" style={{textAlign:"center"}}>Add New Payment Method</h2>
                    <NewTokenBtn promotion={form.promotion}/>
                    
                    <button 
                        className={!!selectedToken?"popup-modal-btn":"popup-modal-btn-gray" }
                        onClick={()=>{
                            !!selectedToken?
                            dispatch(hidePaymentModal()):
                            // setshowPaymentMethodModal(false):
                            console.log("Please select token")
                        }}
                    >
                        Continue
                    </button>
                    <button 
                        className="popup-modal-cancel" 
                        onClick={()=>{
                            dispatch(selectToken(null));
                            dispatch(hidePaymentModal());
                        }}
                    >
                        Cancel
                    </button>
                </div>    
            </Rotate>
        </div>  
        
    )
}

export default PaymentMethodsPopupModal;