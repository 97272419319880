import React, { useState } from 'react';
// import { PayPalButton } from 'react-paypal-button-v2';
import {useDispatch, useSelector} from 'react-redux';
import {Fade, Flip} from 'react-reveal';

//components
import Token from '../Token'
import PayButton from '../Buttons/PayButton';
import NewTokenBtn from '../Buttons/NewTokenBtn';

//images
import ccIcon from '../../img_resources/Forms/Purchase/cc-icon.svg'
import enabledIcon from '../../img_resources/Forms/Purchase/enabled.png'

//actions
import { showPaymentModal } from '../../actions/popupModalActions';

const Payment = ({promotion}) => {

    const dispatch = useDispatch();

    const zCredits = useSelector(store=>store.user.zCredits);
    const selectedToken = useSelector(store=>store.popupModal.selectedToken)
    const [useZCredits, setZCreditsFlag] = useState(false)

    const userAgent = window.navigator.userAgent;
    
    return(
        <>
            
            {
                <Fade bottom>
                    <div className="payment-methods-wrapper">
                        <h2 className="form-second-title">Payment Method</h2>
                        {/* <div className="flex-row space-between" style={{marginBottom:'15px'}}> */}
                        <div className="flex-row space-between" style={{marginBottom:'45px'}
                                // (userAgent.match(/iPhone/i) || userAgent.match(/Mac/i))?
                                // :
                                // {paddingBottom:'0vh'}
                            }>
                            
                            <div className="payment-selector-button-wrapper" onClick={()=>{dispatch(showPaymentModal())}}>
                                
                                <img src={ccIcon} alt="credit card icon" className={!!selectedToken?"cc-icon":"cc-icon"}/>
                                {
                                    !!selectedToken?
                                    <img src={enabledIcon} alt="enabled icon" className="tick"/>:
                                    <></>
                                }
                                
                            </div>
                            
                            <div className="payment-selector-button-wrapper" 
                                style={{justifyContent:"space-around",borderColor:useZCredits?"#564CAC":"#CECECE", alignItems:"flex-start", padding:"5px 20px"}} 
                                onClick={()=>{setZCreditsFlag(!useZCredits)}}>

                                <p className="z-credits-text">Use Z-credits</p>
                                <p className="z-credits-value">{zCredits}</p>
                                {
                                    <Fade left when={!!useZCredits} collapse>
                                        <img src={enabledIcon} alt="enabled icon" className="tick"/>
                                    </Fade>
                                }
                            </div>

                        </div>
                        <PayButton promotion={promotion} selectedToken={selectedToken} useZCredits={useZCredits}/>
                    </div>
                </Fade>
            }
        </>
    )
}

export default Payment;