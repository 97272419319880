import React, {useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";


import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect
  // Link,
} from "react-router-dom";

//screens
import Discover from './Discover';
import Feed from './Feed';
import AdPage from './AdPage';
import LikedFeed from './LikedFeed';
import PurchasedFeed from './PurchasedFeed';
import SignInScreen from './SignInScreen';
import MapView from './MapView';
import Instructions from './Instructions';
import Profile from './Profile';
import Purchase from './Purchase';
import Success from './Success';
import PurchaseHistory from './PurchaseHistory';
import FAQ from './FAQ';
import ConfirmPhone from './ConfirmPhone';
import RedirectShare from './RedirectShare';

import PrivateRoute from '../components/PrivateRoute';

const Routes = ( ) =>{
    const user = useSelector(store=>store.user);
    const location = useLocation();

    const [checkedURL, setCheckedURL] = useState(false);

    //handles query parameters if they exist
    const checkReferralId = (returnUrl) => {
        if(!checkedURL){
          // const queryString = require('query-string');
          let url = decodeURIComponent(returnUrl.search);
          // console.log(returnUrl);
          let params = url.split("refId=")
          try {
              let referralId = params[1];
              if(referralId.length>0){
                  // console.log("STORING REF ID ", referralId)
                  window.sessionStorage.setItem("refId", referralId+returnUrl.hash);
              }
              setCheckedURL(true)
          } catch (error) {
              // let referralId = window.sessionStorage.getItem("refId");
              // console.log("FETCHED FROM LOCAL STORAGE", referralId)
          }
        }
    }

    const redirectAfterHandleQueryParams = (path) => {
        let returnUrl = JSON.parse(window.sessionStorage.getItem("returnUrl"))
        if(returnUrl) {
          checkReferralId(returnUrl);
        }
        path = returnUrl?.pathname || path;
        // console.log('Test Path', path);
        // console.log("Redirecting to ", path)
        // console.log("VERIFIED IS ", user.isVerified)
        return <Redirect to={path}/>
      }

    return (
      <Switch>
        <Route exact path="/SignIn">
        {
            // user.hasOwnProperty("username")?
            // <Redirect to={'/Feed'}/>:
            <SignInScreen/>
          }
        </Route>

        <PrivateRoute path="/Discover" component={Discover}/>

        <PrivateRoute path="/Map" component={MapView}/>

        <PrivateRoute path="/Feed" component={Feed}/>

        <PrivateRoute path="/Saved" component={LikedFeed}/>

        <PrivateRoute path="/Purchases" component={PurchasedFeed}/>

        <PrivateRoute path="/Instructions" component={Instructions}/>

        <PrivateRoute path="/Profile" component={Profile}/>

        <PrivateRoute path="/PurchaseHistory" component={PurchaseHistory}/>

        <Route path="/Purchase" component={Purchase}/>

        <PrivateRoute path="/Promotion/:id" component={AdPage}/>

        <Route path="/Success" component={Success}/>

        <Route path="/FAQ" component={FAQ}/>

        <Route path="/Confirm" component={ConfirmPhone}/>

        <Route path="/redirect" component={RedirectShare} />

        <Route exact path="/">
          {redirectAfterHandleQueryParams('/Feed')}
        </Route>

      </Switch>
    )
  }

  export default Routes;