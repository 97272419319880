import React from 'react';


//images
import approved from '../img_resources/Forms/Purchase/credit-card-approved.svg'

const Success = () => {

    return(
        <>
            <div className="cover">
                <img src={approved} alt="login" className="login-image" style={{marginTop:'20px'}}/>
                <h1 style={{maxWidth:"270px"}} className="login-headline">Payment Method Approved!</h1>
            </div>                
        </>
    )
}

export default Success;
