import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

//actions
import {setTagFilter, toggleFiltersShow} from '../actions/filterActions'

//images
import magnifyingGlassIcon from '../img_resources/Shared Components/Search Bar/magnifying-glass.svg'
import filterIcon from '../img_resources/Shared Components/Search Bar/filter.svg'

import UI from '../ui_functions/index.jsx';

const SearchBar = ({pathname}) => {
    const dispatch = useDispatch();

    const allTags = useSelector(store=>store.filter.allTags);
    const tagsOptions = useSelector(store=>store.filter.tagsOptions);
    
    // const handleScroll = () => {
    // const lastScrollY = this.state.lastScrollY; 
    // let currentScrollY;
    // if(this.props.pathname.toLowerCase().includes("feed")){
    //     currentScrollY = document.getElementById('feed').scrollTop;
    // } else if (this.props.pathname.toLowerCase().includes("map")){
    //     currentScrollY = document.getElementById('mapView').scrollTop;
    // }

    // if (currentScrollY > lastScrollY) {
    //     this.setState({ slide: '-75px' });
    // } else {
    //     this.setState({ slide: '0px' });
    // }
    // this.setState({ lastScrollY: currentScrollY });
    // }

    //Text
    const updateTextFilter = (selectedTags) => {
        let tags = [];
        let tagsCountLimit;
        if(selectedTags!==null){
            tagsCountLimit = allTags[selectedTags[0].value];
        }
        try{
            selectedTags.forEach(tag => {
                tags.push(tag.value)
                if(tagsCountLimit > allTags[tag.value]){
                    tagsCountLimit = allTags[tag.value]
                }
            });
            console.log("FINAL", tagsCountLimit)
        } catch {
        }
        dispatch(setTagFilter(tags, tagsCountLimit));
    }

    return(
        <div 
            className={
                pathname.toLowerCase().includes("map")?
                    "new-search-div search-on-map":
                    "new-search-div"
            } 
            style={
                pathname.toLowerCase().includes("map")?
                    {
                        // transform: `translate(0, ${this.state.slide})`,
                        transition: 'transform 0.5s ease',
                        zIndex: 100,
                        top: '35px',
                        position: 'absolute'
                    }:
                    {
                        // transform: `translate(0, ${this.state.slide})`,
                        transition: 'transform 0.5s ease',
                    }
            }
        >
            <img src={magnifyingGlassIcon} alt="search icon" className="icon"/>

            {
                UI.tagsSelector(
                    UI.prepareSelectorOptions(tagsOptions), 
                    true, 
                    "TagsBox",
                    updateTextFilter, 
                    true
                )
            }

            <div className="new-filter-icon" style={{backgroundImage:`url(${filterIcon})`}} onClick={() => {dispatch(toggleFiltersShow())}}/>
        </div>
    )
    
}

export default SearchBar;

// import React from 'react';
// import {connect} from 'react-redux';

// //actions
// import {setTagFilter, toggleFiltersShow} from '../actions/filterActions'

// //images
// import magnifyingGlassIcon from '../img_resources/Shared Components/Search Bar/magnifying-glass.svg'
// import filterIcon from '../img_resources/Shared Components/Search Bar/filter.svg'

// import UI from '../ui_functions/index.jsx';

// class SearchBar extends React.Component {
//     constructor(props){
//         super(props);
//         this.state={
//             slide: 0,  // How much should the Navbar slide up or down
//             lastScrollY: 0,  // Keep track of current position in state
//         }
//     }

//     componentDidMount() {
//         // When this component mounts, begin listening for scroll changes
//         // if(this.props.pathname.toLowerCase().includes("feed")){
//         //     document.getElementById('feed').addEventListener('scroll', this.handleScroll);
//         // } else if (this.props.pathname.toLowerCase().includes("map")){
//         //     document.getElementById('mapView').addEventListener('scroll', this.handleScroll);
//         // }
        
//         // switch(this.props.pathname){
//         //     case "FEED":
//         //         document.getElementById('feed').addEventListener('scroll', this.handleScroll);
//         //         break;
//         //     case "MAP":
//         //         document.getElementById('mapView').addEventListener('scroll', this.handleScroll);
//         //         break;
//         //     default:
//         //         break;
//         // }
        
//     }
    
//     componentWillUnmount() {
//         // If this component is unmounted, stop listening
//         // if(this.props.pathname.toLowerCase().includes("feed")){
//         //     document.getElementById('feed').removeEventListener('scroll', this.handleScroll);
//         // } else if (this.props.pathname.toLowerCase().includes("map")){
//         //     document.getElementById('mapView').removeEventListener('scroll', this.handleScroll);
//         // }
    
//     }
    
//     handleScroll = () => {
//     const lastScrollY = this.state.lastScrollY; 
//     let currentScrollY;
//     if(this.props.pathname.toLowerCase().includes("feed")){
//         currentScrollY = document.getElementById('feed').scrollTop;
//     } else if (this.props.pathname.toLowerCase().includes("map")){
//         currentScrollY = document.getElementById('mapView').scrollTop;
//     }

//     if (currentScrollY > lastScrollY) {
//         this.setState({ slide: '-75px' });
//     } else {
//         this.setState({ slide: '0px' });
//     }
//     this.setState({ lastScrollY: currentScrollY });
//     }

//     //Text
//     updateTextFilter = (selectedTags) => {
//         let tags = [];
//         let tagsCountLimit;
//         if(selectedTags!==null){
//             tagsCountLimit = this.props.allTags[selectedTags[0].value];
//         }
//         try{
//             selectedTags.forEach(tag => {
//                 tags.push(tag.value)
//                 if(tagsCountLimit > this.props.allTags[tag.value]){
//                     tagsCountLimit = this.props.allTags[tag.value]
//                 }
//             });
//             console.log("FINAL", tagsCountLimit)
//         } catch {
//         }
//         this.props.setTagFilter(tags, tagsCountLimit);
//     }

//     render(){
//         return(
//             <div 
//                 className={
//                     this.props.pathname.toLowerCase().includes("map")?
//                         "new-search-div search-on-map":
//                         "new-search-div"
//                 } 
//                 style={
//                     this.props.pathname.toLowerCase().includes("map")?
//                         {
//                             transform: `translate(0, ${this.state.slide})`,
//                             transition: 'transform 0.5s ease',
//                             zIndex: 100,
//                             top: '35px',
//                             position: 'absolute'
//                         }:
//                         {
//                             transform: `translate(0, ${this.state.slide})`,
//                             transition: 'transform 0.5s ease',
//                         }
//                 }
//             >
//                 <img src={magnifyingGlassIcon} alt="search icon" className="icon"/>

//                 {
//                     UI.tagsSelector(
//                         UI.prepareSelectorOptions(this.props.tagsOptions), 
//                         true, 
//                         "TagsBox",
//                         this.updateTextFilter, 
//                         true
//                     )
//                 }

//                 <div className="new-filter-icon" style={{backgroundImage:`url(${filterIcon})`}} onClick={() => {this.props.toggleFiltersShow()}}/>
//             </div>
//         )
//     }
// }

// const mapStateToProps = (store) => {
//     return {
//         tagsOptions: store.filter.tagsOptions,
//         allTags: store.filter.allTags,
//         promotions: store.promotions.promotions
//         // display: store.display.display
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return{
//         setTagFilter:  (tag, limit) => dispatch(setTagFilter(tag, limit)),
//         toggleFiltersShow: () => dispatch(toggleFiltersShow())
//     }
// }

// export default connect (mapStateToProps, mapDispatchToProps) (SearchBar);