import React from 'react';
import { useDispatch } from 'react-redux';

//images
import plusIcon from '../../img_resources/Forms/Purchase/plus.svg'

//actions
import {setForm} from '../../actions/formActions'
import {hidePaymentModal} from '../../actions/popupModalActions'

const NewTokenBtn =({promotion}) => {

    const dispatch = useDispatch();

    return(
        <div className="cc-container" style={{justifyContent:'center'}} onClick={()=>{
                dispatch(hidePaymentModal())
                dispatch(setForm(promotion,"NEW PAYMENT METHOD", "New payment method"))

            }} >
            <img src={plusIcon} alt="plus icon" className="new-payment-icon"/>
        </div>
    )
}

export default NewTokenBtn;

