import ZESTTY_API from '../api';
import ZESTTY_FUNCTIONS from '../ui_functions'

export function fetchCities(city) {
    return function(dispatch) {
        dispatch(getCitiesStarted());
        ZESTTY_API.getCities(city)
            .then(cities => {
                dispatch(getCitiesSuccess(cities));
            })
            .catch(err => {
                dispatch(getCitiesFailure(err.message))
            })
    }
}

export const fetchTags = (city) => {
    return function(dispatch) {
        dispatch(getTagsStarted());
        // ZESTY.getPurchases(userId)
        ZESTTY_API.getTags(city)
            .then(tags => {
                let tagsOptions = ZESTTY_FUNCTIONS.orderTagsByCount(tags)
                dispatch(getTagsSuccess(tags, tagsOptions));
            })
            .catch(err => {
                dispatch(getTagsFailure(err.message))
            })
    }
}

export const fetchNames = (city) => {
    return function(dispatch) {
        dispatch(getNamesStarted());
        // ZESTY.getPurchases(userId)
        ZESTTY_API.getNames(city)
            .then(names => {
                dispatch(getNamesSuccess(names));
            })
            .catch(err => {
                dispatch(getNamesFailure(err.message))
            })
    }
}

export const setCity = (city) => {
    return {
        type: 'SET_CITY_FILTER',
        value: city
    }
}

export const setName = (name) => {
    return {
        type: 'SET_NAME_FILTER',
        value: name
    }
}

export const setAge = (age) => {
    return {
        type: 'SET_AGE_FILTER',
        value: age
    }
}

export const setTagFilter = (tag, limit) => {
    return {
        type: 'SET_TAG_FILTER',
        value: tag,
        limit: limit
    }
}

export const setLive = (live) => {
    return {
        type: 'TOGGLE_LIVE',
        live: live
    }
}

export const toggleLike = () => {
    return {
        type: 'TOGGLE_LIKE'
    }
}

export const togglePurchase = () => {
    return {
        type: 'TOGGLE_PURCHASE'
    }
}

export const toggleFiltersShow = () => {
    return {
        type: 'TOGGLE_SHOW_FILTERS'
    }
}


export const setNamesOptions = (names) => {

    return {
        type: "EXTRACT_NAMES_FOR_FILTERS",
        names: names
    }
}

export const setCitiesOptions = (cities) => {

    return {
        type: "EXTRACT_CITIES_FOR_FILTERS",
        cities: cities
    }
}

const getTagsStarted = (tags) => {
    return {
        type: "GET_TAGS_FOR_FILTERS"
    }
}

const getTagsSuccess = (tags, tagsOptions) => {
    return {
        type: "SETTING_TAGS_FOR_FILTERS",
        tags: tags,
        tagsOptions: tagsOptions
    }
}

const getTagsFailure = (err) => {
    return {
        type: "GET_TAGS_FOR_FILTERS_FAILURE",
        message: err
    }
}

const getNamesStarted = (names) => {
    return {
        type: "GET_NAMES_FOR_FILTERS"
    }
}

const getNamesSuccess = (names) => {
    return {
        type: "SETTING_NAMES_FOR_FILTERS",
        names: names
    }
}

const getNamesFailure = (err) => {
    return {
        type: "GET_NAMES_FOR_FILTERS_FAILURE",
        message: err
    }
}

const getCitiesStarted = () => {
    return {
        type: 'GET_CITIES'
    }
}

const getCitiesSuccess = (cities) => {

    return {
        type: 'GET_CITIES_SUCCESS',
        cities: cities
    }
}

const getCitiesFailure = (message) => {

    return {
        type: 'GET_CITIES_FAILURE',
        err: message
    }
}