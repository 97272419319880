import React from 'react';
import {NavLink} from 'react-router-dom'

//images
import timeLeftIcon from '../../img_resources/Map View/time-color.svg'

//inner libraries
import UI from '../../ui_functions/index.jsx'
import { Fade } from 'react-reveal';

const MapAd = ({promotion}) => {

    return(
        <>
            <Fade bottom>
                <div className="selected-ad-map-preview" >
                    <NavLink to={{
                        pathname: `/Promotion/${promotion.PromotionId}`,
                        promotion: promotion
                        }}  
                        className="all-over"/>
                        <div className="map-img-thumbnail" style={{backgroundImage:"url("+ promotion.Image+")"}}></div>
                        
                        <div className="map-ad-info">
                            <div className="flex-row space-between">
                                
                                <div className="map-promotion-text">{promotion.Promotion}</div>
                                <div className="flex-row">
                                    <div className="map-original-price">{"₪"+promotion.OriginalPrice}</div>
                                    <div className="map-price">{"₪"+promotion.Price}</div>
                                </div>
                            </div>

                            <div className="flex-row space-between" style={{marginTop:'5px'}}>
                                <div className="map-promotion-name">{promotion.Name}</div>
                                <div className="flex-row">
                                    <div className="map-badge">
                                        <div className="map-badge-background"></div>
                                        <p className="map-badge-text">{promotion.Age}+</p>
                                    </div>
                                    
                                    <div className="map-badge">
                                        <div className="map-badge-background"></div>
                                        <img className="badge-icon" src={timeLeftIcon} alt="time left icon"/>
                                        
                                        <p className="map-badge-text">{UI.getTimeLeft(promotion.ExpTimeStamp)}</p>
                                    </div>
                                </div>
                                
                            </div>
                            
                            
                            <div className="map-tags">
                                {
                                    promotion.Tags.map(tag => (
                                        <div key={promotion.PromotionId+tag} className="map-tag">{tag}</div>
                                    ))
                                }
                            </div>
                        
                        </div>
                        
                </div>
            </Fade>
        </>
    )
}
export default MapAd;