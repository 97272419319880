import React from 'react';
import {useSelector} from 'react-redux';

//images
import TagsIcon from '../img_resources/Discover/tags.svg'

const PopularTags =({selectTag, selectedTag, shadow}) => {

    const tagsByOrder = useSelector(store=>store.filter.tagsOptions);

    let highlightTag = {backgroundColor: '#564CAC', color: 'white'}
    // let noHighlight = {}

    return(
        <div className="popular-tags" 
            style={{
                transition: 'all 1s ease',
                boxShadow:shadow?'0px 3px 6px #00000024':''
            }}
            >
            <img src={TagsIcon} alt="tags icon" className="new-icon" style={{marginRight:'5px'}}/>
            {
                tagsByOrder.map(tag=>
                    <p 
                        key={"pop-tag-"+tag}
                        className="discover-tag" 
                        onClick={()=>{selectTag(tag)}} 
                        style ={selectedTag===tag? highlightTag: {}}>
                            {tag}
                    </p>
                )
            }
            <div style={{minWidth:'25px',height:'1px'}}></div>
        </div>
    )
}

export default PopularTags;