//external libraries
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Flip from 'react-reveal'
import {NavLink} from 'react-router-dom'

//components

//images
import {hideModal} from '../actions/popupModalActions'

const PopupModal = (props) => {
    const dispatch = useDispatch();

    const popupModal = useSelector(store=>store.popupModal);

    const [showModal, setShow] = useState(false);

    useEffect(()=>{
        setShow(popupModal.show)
    },[popupModal.show])

    return( 
        <div className="popup-modal-wrapper" style={{display:popupModal.show?"flex":"none"}}>
            <Flip when={showModal}>
                <div className="popup-modal">
                    <h1 className="popup-modal-title" >{popupModal.title}</h1>
                    <img className="popup-modal-img" src={popupModal.img} alt={popupModal.title}/>
                    <p className="popup-modal-text" >{popupModal.text}</p>
                    {
                        popupModal.modalType==='success'?
                        <NavLink 
                            to={`/Purchases?purchased=true`} 
                            className="popup-modal-btn" 
                            style={{textDecoration:'none'}}
                            onClick={()=>dispatch(hideModal())}
                            >
                            Go to Purchases
                        </NavLink>:
                        <button 
                            className="popup-modal-btn" 
                            onClick={()=>{
                                dispatch(hideModal())
                                if(popupModal.title==="Coming Soon"){
                                    window.location.replace("https://www.zesttygroup.com/get-zestty");
                                }
                            }}
                        >
                            Continue
                        </button>
                    }
                    
                </div>    
            </Flip>
        </div>       
        
    )
}

export default PopupModal;