import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Fade from 'react-reveal'

//components
import LikeBtn from '../components/Buttons/LikeBtn';
import DirectionsBtn from '../components/Buttons/DirectionsBtn';
import PurchaseBtn from '../components/Buttons/PurchaseBtn';
import ShareBtn from '../components/Buttons/ShareBtn';
import ReportButton from '../components/Buttons/ReportButton';
import SingleAdGoogleMaps from '../components/SingleAdGoogleMaps';
import Loader from '../components/Loader';

//images
import timeLeftIcon from '../img_resources/Promotion Page/time-color.svg'
import TagsIcon from '../img_resources/Promotion Page/tags.svg'

//actions
// import {fetchSocialImages} from '../actions/displayActions'

//inner libraries
import UI from '../ui_functions'
import ZESTTY_API from '../api'
import { setForm } from '../actions/formActions';

const AdPage = (props) => {
    
    const dispatch = useDispatch()

    const promotionId = props.location.pathname.split("/")[2];
    
    const [promotion, setPromotion] = useState({});
    // const [purchase, setPurchase] = useState({})
    const [isLoadingPromotion, setLoadingPromotion] = useState(true);
    const [error, setError] = useState(undefined);
    
    const promotions = useSelector(store=>store.promotions.promotions);
    
    useEffect(()=>{
        if(props.location.promotion===undefined){
            
            let filterResult = promotions.filter(itm=> itm.PromotionId===promotionId);
            if(filterResult.length>0)
            {
                setPromotion(filterResult[0])
                setLoadingPromotion(false)
            }

            getPromotionFromServer()
        } else {

            setPromotion(props.location.promotion)
            getPromotionFromServer()
        }
    },[])

    //fetches the promotion from the server and updates the state with the most updated version of the promotion
    const getPromotionFromServer = () => {
        ZESTTY_API.getPromotion(promotionId)
            .then(res=> {
                setPromotion(res.promotion.Item)
                setLoadingPromotion(false)
            })
            .catch(err => setError(err))
    }

        // if(!promotion.hasOwnProperty("PromotionId")){
        //     return <Loader/>;
        // } else {
            return(
                <div className="new-container">
                    {
                        !promotion.hasOwnProperty("PromotionId")?
                        <Loader/>:
                        <div className="content-wrapper">
                            <div className="flex-row space-between" style={{width:'100%', marginBottom:'15px',marginTop:'15px'}}>
                                <div className="single-ad-name">{promotion.Name}</div>
                                <ReportButton promotion={promotion} />
                            </div>
                            
                            <Fade>
                                <div className="single-ad-img" style={{backgroundImage: 'url(' + promotion.Image + ')'}}/>
                            </Fade>

                            {/* <PurchaseBtn promotion={promotion} purchase={purchase}/> */}
                            <PurchaseBtn promotion={promotion}/>
                            
                            
                                <div className="flex-row space-between" style={{width:'100%', marginBottom:'15px',marginTop:'15px'}}>
                                    
                                    <div className="flex-row">
                                        <div className="single-ad-badge" style={{marginLeft:'0px'}} >
                                            <div className="single-ad-badge-background"></div>
                                            <img className="badge-icon" src={timeLeftIcon} alt="time left icon"/>
                                            
                                            <p className="single-ad-badge-text">{UI.getTimeLeft(promotion.ExpTimeStamp)}</p>
                                        </div>

                                        <div className="single-ad-badge">
                                            <div className="single-ad-badge-background"></div>
                                            <p className="single-ad-badge-text">{promotion.Age} +</p>
                                        </div>
                                    </div>

                                    <div className="flex-row">
                                        <LikeBtn promotion={promotion} />
                                        <div style={{width:'10px'}}></div>
                                        {/* <DirectionsBtn address={promotion.Address}/> */}
                                        <ShareBtn promotion={promotion}/>
                                    </div>
                                </div>

                                <div className="flex-row" style={{width:'100%'}}>
                                    <p className="single-ad-promotion">{promotion.Promotion}</p>
                                </div>

                                <div className="flex-row">
                                    <p className="promotion-description">{promotion.PromotionDescription}</p>
                                </div>

                                <div className="new-feed-ad-tags" style={{marginTop:'15px', alignItems:'center', width:'100%', marginLeft:'0px'}}>
                                    <img src={TagsIcon} alt="tags icon" className="new-icon" style={{marginRight:'5px'}}/>
                                    {
                                        promotion.Tags.map(tag=>
                                            <p key={tag} className="discover-tag">
                                                {tag}
                                            </p>
                                        )
                                    }
                                </div>

                                <div className="flex-row space-between" style={{alignItems:"baseline", marginTop:'11px', width:'100%'}}>
                                    <div className="flex-row">
                                        <p className="amount-left-single-ad">{promotion.Quantity} Left</p>
                                        <div className="amount-left-bar-wrapper-single-ad">
                                            <div className="amount-left-fill-single-ad" 
                                                style={window.innerWidth<=768?
                                                {width:(((promotion.Quantity/promotion.StartingQuantity))*150)+'px'}:
                                                {width:(((promotion.Quantity/promotion.StartingQuantity))*35)+'vw'}}/>
                                        </div>
                                    </div>
                                    <div className="flex-row" style={{alignItems:'baseline', fontWeight:600}}>
                                        <p className="new-original-price" style={{fontSize:'16px'}}>{"₪"+promotion.OriginalPrice}</p>
                                        <p className="new-price" style={{fontSize:'24px'}}>{"₪"+promotion.Price}</p>
                                    </div>
                                </div>
                                
                                <div className="flex-row" style={{width: '100%', marginTop:'15px', marginBottom: '15px', alignItems:'flex-end', marginLeft:'-10px'}}>
                                    
                                    <DirectionsBtn address={promotion.Address} />

                                    {/* <img src={DirectionsIcon} alt="directions icon" className="icon" style={{width:'auto',marginRight:'10px'}}/> */}
                                    <div className="flex-col" style={{textAlign:'left'}}>
                                        {promotion.Address}
                                        {/* {
                                            promotion.Address.split(",").map(addressPart=>
                                                <div key={addressPart} className="single-ad-address-new">{addressPart}</div>
                                                )
                                        } */}
                                    </div>
                                </div>

                                <div className="map-test">
                                    <SingleAdGoogleMaps 
                                        geoLocation={{lat: promotion.GeoCode.Lat, lng: promotion.GeoCode.Lng}}/>
                                </div>

                                {/* {
                                    this.props.socialImages.length>0 && this.props.socialImages!=="loading"?
                                    <div className="social-images-wrapper">
                                        {this.props.socialImages.map(socialImg=>(
                                            <div className="social-image" style={{backgroundImage:"url("+socialImg+")"}}></div>
                                        ))}
                                    </div>:
                                    <></>
                                } */}

                                {/* <div onClick={()=>{
                                    dispatch(setForm(promotion, "FEEDBACK", "How was the service?"))
                                }}>
                                    TEST ME
                                </div> */}
                            
                            <div className="spacer"/>

                        </div>
                    }
                    
                </div>

            )
        // }
}

export default AdPage;