//libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import {
    Route,
    Switch
  } from "react-router-dom";

//actions
import {setForm} from '../../actions/formActions';

//images
import reportIconWhite from '../../img_resources/Feed/report-white.svg'
import reportIconGray from '../../img_resources/Promotion Page/report-grey.svg'

const ReportButton = ({promotion}) => {
    const dispatch = useDispatch();

    return(  
        <Switch>
            <Route path="/Promotion">
                <img 
                    className="single-ad-report-btn"
                    onClick={(e)=>{
                        e.stopPropagation();
                        dispatch(setForm(promotion, "REPORT", "Reporting"));
                    }}
                    src={reportIconGray}
                    alt="report icon">
                </img>  
            </Route>
            <Route path="/">
                <img 
                    className="feed-ad-report-btn"
                    onClick={(e)=>{
                        e.stopPropagation();
                        dispatch(setForm(promotion, "REPORT", "Reporting"));
                    }}
                    src={reportIconWhite}
                    alt="report icon">
                </img>  
            </Route>
        </Switch>
    )
    
}

export default ReportButton;