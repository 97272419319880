//USER RELATED FUNCTIONS

//gets the user data or creates the user if he does not exist in our tables
const userHandler = async(userId, refId) => {
    let body = {};
    body = userId ? {...body, 'userId': userId } : body;
    body = refId ? {...body, 'refId': refId } : body;
    body = JSON.stringify(body)

    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: body
    });
    let userData = await response.json();
    return userData.body;
}

//confirm phone number
const confirmPhoneHandler = async(userId, phone, code) => {
    let body = {};
    body = userId ? {...body, 'UserId': userId } : body;
    body = phone ? {...body, 'PhoneNo': phone } : body;
    body = code ? {...body, 'Code': code } : body;
    body = JSON.stringify(body)
    console.log("VERIFYING with body", body)

    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/phone-verify`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: body
    });
    let res = await response.json();
    return res;
}

//sets FirstTime to false
const setFirstTime = async(userId) => {
    let body = {};
    body = userId ? {...body, 'UserId': userId } : body;
    body = JSON.stringify(body)

    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/firsttime`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: body
    });
    let res = await response.json();
    return res;
}

//Gets the data of the user from our get zestty users table
const getUserData = async(userId) => {
    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/${userId}`, {
        method: "GET",
        headers: { 'Content-Type': 'application/json' }
    });

    const data = await response.json();
    try {
        return (data.body);
    } catch {
        throw Error(data.error)
    }

}

//Uploades a like to the tables on the server
const postLike = async(userId, advertiserId) => {
    const res = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/like`, {
        method: 'POST',
        body: JSON.stringify({
            userId: userId,
            advertiserId: advertiserId

        }),
        headers: { 'Content-Type': 'application/json' }
    });
    const resData = await res.json();
    return resData;
}

//Removes a like from the tables on the server
const deleteLike = async(userId, advertiserId) => {
    const res = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/like`, {
        method: 'DELETE',
        body: JSON.stringify({
            "userId": userId,
            "advertiserId": advertiserId
        }),
        headers: { 'Content-Type': 'application/json' }
    });
    const resData = await res.json();
    return resData;
}

//Removes a refId from the user if it has been used, or is irrelevant anymore
const fulfilRef = async(userId, refId) => {
    const res = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/zcredits/add`, {
        method: 'POST',
        body: JSON.stringify({
            "userId": userId,
            "refId": refId,
        }),
        headers: { 'Content-Type': 'application/json' }
    });
    const resData = await res.json();
    return resData;
}

//Updates the amount of money saved by the user after making a purchase
const updateAmountSaved = async(userId, amountSaved) => {
    const res = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/amountsaved`, {
        method: 'POST',
        body: JSON.stringify({
            "userId": userId,
            "amountSaved": amountSaved
        }),
        headers: { 'Content-Type': 'application/json' }
    });
    const resData = await res.json();
    return resData;
}

//https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/zcredits/add

//PROMOTIONS RELATED FUNCTIONS

//gets promotions from DB with filters
const getPromotions = async(city, limitFlag, name, minAge, tags, lastEvalKey) => {
    let body = {}
    body = city ? {...body, 'City': city } : body;
    body = name.active ? {...body, 'Name': name.value } : body;
    body = minAge.active ? {...body, 'MinAge': minAge.value } : body;
    body = tags.active ? {...body, 'Tags': tags.value } : body;
    body = lastEvalKey ? {...body, 'LastEvaluatedKey': lastEvalKey } : body;
    body = limitFlag ? {...body, 'LimitFlag': limitFlag } : body;
    body = JSON.stringify(body)

    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/promotions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    });

    const promotionsData = await response.json();
    // console.log(promotionsData)
    try {
        return (promotionsData);
    } catch {
        throw Error(promotionsData.error)
    }

}

//gets promotions from DB with filters
const getPromotionsByAdvertiserIds = async(advertiserIds) => {
    let body = {
        "AdvertiserIds": advertiserIds
    }
    body = JSON.stringify(body)

    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/promotions/by-advertiser`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    });

    const promotionsData = await response.json();

    try {
        return (promotionsData);
    } catch {
        throw Error(promotionsData.error)
    }

}

//Posts a report of a promotion
const postReport = async(promotionId, userId, reportType, reportMessage, city) => {
    const res = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/report`, {
        // const res = await fetch(`https://9xffydd7q7.execute-api.us-east-2.amazonaws.com/dev/map-ads/report`, {
        method: 'POST',
        body: JSON.stringify({
            "PromotionId": promotionId,
            "UserId": userId,
            "ReportType": reportType,
            "Message": reportMessage,
            "City": city

        }),
        headers: { 'Content-Type': 'application/json' }
    });
    const resData = await res.json();
    return resData;
}

//gets the purchases based on userId
const getUserPurchases = async(userId) => {
    const purchasesResponse = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/purchases/all?userId=${userId}`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    });
    const purchasesData = await purchasesResponse.json();

    let purchases = {};

    purchases.active = purchasesData.active.Items;
    purchases.archive = purchasesData.archive.Items;

    try {
        return await purchases;
    } catch (err) {
        console.log(err)
        console.log(purchasesData.error)
        throw Error(purchasesData.error)
    }
}

//creates a token of a customers credit card and stores it in our DB
const createTokenSession = async(userId) => {
    let url = 'https://pci.zcredit.co.il/webcheckout/api/WebCheckout/CreateSession';
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            "Key": "413629910a0f5e41077f67f706028d3ef1ab36b8005c90774b6705aaf2915356", //PROD
            // "Key": "a908debc4f05f424e8fee6fa92fb4e74e309c66a0dde138504eb9c722a799c5e", // TESTING
            "Local": "En",
            "UniqueId": userId,
            "SuccessUrl": window.location.origin + "/Success",
            "CancelUrl": "",
            "CallbackUrl": "https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/tokens",
            "PaymentType": "validate",
            "CreateInvoice": "false",
            "AdditionalText": "",
            "ShowCart": "false",
            "Installments": {
                Type: "none",
                MinQuantity: "1",
                MaxQuantity: "1"
            },
            "Customer": {
                "Email": "",
                "Name": "",
                "PhoneNumber": "",
                "Attributes": {
                    "HolderId": "none", //this.props.userId ,
                    "Name": "none",
                    "PhoneNumber": "none",
                    "Email": "none"
                }
            },
            "CartItems": [{
                "Amount": "0.1",
                "Currency": "ILS",
                "Name": "",
                "Description": "",
                "Quantity": 1,
                "Image": ""
            }]
        }),
        headers: { 'Content-Type': 'application/json; charset= utf-8' }
    })
    const resData = await res.json();
    // console.log(resData.Data.SessionUrl)
    return await resData.Data.SessionUrl;
}

//Removes a customers credit card from our tables
const deleteToken = async(userId, cardNum, expDate, cardType) => {
    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/users/tokens`, {
        method: "DELETE",
        body: JSON.stringify({
            "UserId": userId,
            "CardNum": cardNum,
            "ExpDate": expDate,
            "CardType": cardType
        }),
        headers: { 'Content-Type': 'application/json' }
    });

    const tokens = await response.json();
    try {
        console.log("RESPONSE ", tokens)
        return (tokens);
    } catch {
        throw Error(tokens.error)
    }

}

//Uses the token of the credit card to charge the customers credit card
const useToken = async(userId, ccNum, expDate, price, promotionId, promotion, advertiserId, city, relatedPromotion, userName, email, useZcredits, refId) => {
    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/purchases`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "UserId": userId,
            "CcNum": ccNum,
            "ExpDate": expDate,
            "Price": price,
            "PromotionId": promotionId,
            "Promotion": promotion,
            "AdvertiserId": advertiserId,
            "AppName": city,
            "Currency": "ILS", //NEED TO CHANGE TO GET FROM PROMOTION
            "RelatedPromotion": JSON.stringify(relatedPromotion),
            "UserName": userName,
            "Email": email,
            "UseZcredits": useZcredits,
            "RefId": refId
        })
    });

    const res = await response.json();
    try {
        console.log("RESPONSE ", res)
        return (res);
    } catch {
        throw Error(res.error)
    }
}

//gets the list of cities with active promotions to pick from
const getCities = async() => {

    let citiesList = [];

    const citiesResponse = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/promotions/cities`, {
        method: "get",
        headers: { 'Content-Type': 'application/json' }
    });
    const citiesData = await citiesResponse.json();

    try {

        for (let i = 0; i < citiesData.cities.length; i++) {
            citiesList.push(citiesData.cities[i].AppName);
        }

        return citiesList;
    } catch {
        throw Error(citiesData.error)
    }

}

//gets the list of tags from promotions based on city
const getTags = async(city) => {

    const tagsResponse = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/promotions/tags?city=${city}`, {
        method: "get",
        headers: { 'Content-Type': 'application/json' }
    });
    const tagsData = await tagsResponse.json();

    try {
        return tagsData.Tags;
    } catch {
        throw Error(tagsData.error)
    }

}

//gets the list of tags from promotions based on city
const getNames = async(city) => {

    const tagsResponse = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/promotions/names?city=${city}`, {
        method: "get",
        headers: { 'Content-Type': 'application/json' }
    });
    const namesData = await tagsResponse.json();

    try {
        return namesData;
    } catch {
        throw Error(namesData.error)
    }

}

//gets the promotion from db NEED TO CONNECT TO NEW ENDPOINT
const getPromotion = async(promotionId) => {
    promotionId = promotionId.replace("#", "");
    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/promotions/verify?PromotionId=${promotionId}`, {
        method: "get",
        headers: { 'Content-Type': 'application/json' }
    });

    const promotion = await response.json();
    try {
        return (promotion);
    } catch {
        throw Error(promotion.error)
    }

}

const getPurchase = async(userId, promotionId) => {
    promotionId = promotionId.replace("#", "");
    const response = await fetch(`https://879ze0lx3d.execute-api.us-east-2.amazonaws.com/dev/map-ads/purchases?userId=${userId}&promotionId=${promotionId}`, {
        method: "get",
        headers: { 'Content-Type': 'application/json' }
    });

    const purchase = await response.json();
    try {
        return (purchase.body);
    } catch {
        throw Error(purchase.error)
    }

}

const getBatchPromotions = async(promotionIds) => {
    // promotionIds = promotionIds.replace("#", "");
    const response = await fetch(`https://9xffydd7q7.execute-api.us-east-2.amazonaws.com/dev/map-ads/batch-promotions`, {
        method: "post",
        body: JSON.stringify({
            ActivePurchasesPromotionIds: promotionIds.active,
            ArchivePurchasesPromotionIds: promotionIds.archive
        }),
        headers: { 'Content-Type': 'application/json' }
    });

    const promotions = await response.json();
    try {
        return (promotions);
    } catch {
        throw Error(promotions.error)
    }

}

const checkPurchase = async(userId, promotionId) => {
    promotionId = promotionId.replace("#", "");
    const response = await fetch(`https://9xffydd7q7.execute-api.us-east-2.amazonaws.com/dev/map-ads/check-purchase-exist?PromotionId=` + promotionId + "&UserId=" + userId, {
        method: "get",
        headers: { 'Content-Type': 'application/json' }
    });

    const purchase = await response.json();
    try {
        return (purchase);
    } catch {
        throw Error(purchase.error)
    }

}

const uploadSocialImage = async(promotionId, userId, img) => {
    console.log("ADID ", promotionId, " USERID ", userId)
    promotionId = promotionId.replace("#", "")
    const res = await fetch(`https://9xffydd7q7.execute-api.us-east-2.amazonaws.com/dev/map-ads/upload-social-image`, {
        method: 'POST',
        body: JSON.stringify({
            "PromotionId": promotionId,
            "UserId": userId,
            "base64String": img
        }),
        headers: { 'Content-Type': 'application/json' }
    });
    const resData = await res.json();
    let url = "https://zestty-social.s3.us-east-2.amazonaws.com/" + resData.fileName + ".jpeg";
    return url;
}

const postSocialImage = async(promotionId, userId, advertiserId, img) => {
    const res = await fetch(`https://9xffydd7q7.execute-api.us-east-2.amazonaws.com/dev/map-ads/social-images`, {
        method: 'POST',
        body: JSON.stringify({
            "PromotionId": promotionId,
            "UserId": userId,
            "AdvertiserId": advertiserId,
            "Image": img
        }),
        headers: { 'Content-Type': 'application/json' }
    });
    const resData = await res.json();
    return resData;
}

const getSocialImages = async(promotionId) => {
    console.log("FETCHING SOCIAL IMAGE FOR", promotionId)
    const res = await fetch(`https://9xffydd7q7.execute-api.us-east-2.amazonaws.com/dev/map-ads/social-images/get`, {
        method: 'POST',
        body: JSON.stringify({
            "PromotionId": promotionId
        }),
        headers: { 'Content-Type': 'application/json' }
    });
    const resData = await res.json();
    console.log("RESPONSE IS ")
    console.log(resData)
    return resData.images;
}

const ZESTTY_API = {
    userHandler,
    confirmPhoneHandler,
    getUserData,
    getPromotions,
    getUserPurchases,
    getPromotionsByAdvertiserIds,
    fulfilRef,
    updateAmountSaved,
    setFirstTime,

    postLike,
    deleteLike,

    createTokenSession,
    deleteToken,
    useToken,

    postReport,

    getPurchase,
    checkPurchase,

    uploadSocialImage,
    postSocialImage,
    getSocialImages,
    getBatchPromotions,

    getCities,
    getTags,
    getNames,
    getPromotion,


}

export default ZESTTY_API;