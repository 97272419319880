const initialState = {
    action: "Hide",
    text: "",
    promotion: {},
    QR: "null",
    err: "null",
    code: "",
    loading: false
}

export const formReducer = (state = initialState, action) => {

    switch (action.type) {

        case "SET_FORM":
            return {
                ...state,
                promotion: action.promotion,
                action: action.action,
                text: action.text
            }

        case "SET_FORM_LOADING":
            return {
                ...state,
                loading: action.loading
            }

        case "SET_FORM_TEXT":
            return {
                ...state,
                text: action.text
            }

        case "SET_QR":
            return {
                ...state,
                QR: action.QR
            }

        case "SET_CODE":
            return {
                ...state,
                code: action.code
            }

        default:
            return state;
    }
}