//libraries
import React from 'react';
import {Link} from 'react-router-dom'

//components
import DirectionsBtn from '../Buttons/DirectionsBtn'
import PurchaseBtn from '../Buttons/PurchaseBtn'
import LikeBtn from '../Buttons/LikeBtn';

import UI from '../../ui_functions'

const PurchasedAd = ({promotion, active, purchase}) => {

    return(  
        <>
            <div 
                className="flex-row ad-container-form" 
                style={{width:'calc(100vw - 30px)', maxWidth: '630px' ,marginLeft:'-15px'}} 
            >
                <Link 
                to={{
                    pathname: `/Promotion/${promotion.PromotionId.replace("#","")}`,
                    state: { promotion: promotion, purchase: purchase }
                }}
                className="purchased-ad-link"
                
                />
                    <div className="form-img" style={{backgroundImage: "url("+ promotion.Image+")"}}/>

                    <div className="flex-col" style={{width:'100%', padding:'0px 15px'}}>
                        <div className="flex-row space-between">
                            <div className="form-promotion-text">{promotion.Promotion}</div>
                            <div className="flex-row" style={{alignItems:'baseline'}}>
                                {/* <div className="form-original-price">₪{promotion.OriginalPrice}</div> */}
                                <div className="form-price" >₪{promotion.Price}</div>
                            </div>
                        </div>
                        <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                            <div className="flex-col" style={{maxWidth:'calc(100% - 21px)'}}>
                                <div className="map-promotion-name" style={{fontSize:'15px', width:'100%', textAlign:'left'}}>{promotion.Name}</div>
                                {/* <div className="address-text" style={{width:'100%', textAlign:'left'}}>{this.props.promotion.Address}</div> */}
                                <div className="purchase-date-text" style={{textAlign:'center'}}>{UI.getDate(purchase)}</div>
                            </div>
                            <div className="flex-row">
                                <DirectionsBtn address={promotion.Address} />
                                {/* <PurchaseBtn promotion={this.props.promotion} purchase={this.props.purchase}/> */}
                                {
                                    active?
                                    <PurchaseBtn promotion={promotion}/>:
                                    <LikeBtn promotion={promotion}/>
                                }
                                
                            </div>
                        </div>
                    </div>
            </div>
            
        </>
    )
}

export default PurchasedAd;