const initialState = {
    active: [],
    archive: [],
    err: "null",
    loading: false
}

export const purchasesReducer = (state = initialState, action) => {

    switch (action.type) {

        case "GET_PURCHASES":

            return {
                ...state,
                loading: true
            }

        case "GET_PURCHASES_SUCCESS":

            return {
                ...state,
                active: action.purchases.active,
                archive: action.purchases.archive,
                loading: false
            }

        case "GET_PURCHASES_FAILURE":

            return {
                ...state,
                err: action.err,
                loading: false
            }


        case "ADD_TO_PURCHASE_LIST":
            let purchases = state.activePurchases;
            return {
                ...state,
                activePurchases: purchases.concat(action.purchase),
                loading: false
            }

        case "GET_PURCHASES_PROMOTIONS":
            return {
                ...state,
                loading: true
            }

        case "GET_PURCHASES_PROMOTIONS_SUCCESS":
            let promotions = action.promotions;

            return {
                ...state,
                archivePromotions: promotions.archivePromotions,
                activePromotions: promotions.activePromotions,
                loading: false
            }

        case "GET_PURCHASES_PROMOTIONS_FAILURE":

            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "POST_PURCHASE":

            return {
                ...state,
                loading: true
            }

        case "POST_PURCHASE_FAILURE":

            return {
                ...state,
                err: action.err,
                loading: false
            }

            // let response = {};
            // if (action.PurchaseType === "archive") {
            //     // promotions.index = state.archiveAds.length;
            //     response = {
            //         ...state,
            //         archivePromotions: promotions,
            //         loading: false
            //     }
            // } else if (action.PurchaseType === "active") {
            //     // promotions.index = state.activeAds.length;
            //     response = {
            //         ...state,
            //         activePromotions: promotions,
            //         loading: false
            //     }
            // }
            // return response

            // case "GET_ACTIVE_PURCHASES":

            //     return {
            //         ...state,
            //         loading: true
            //     }

            // case "GET_ACTIVE_PURCHASES_SUCCESS":

            //     return {
            //         ...state,
            //         activePurchases: action.purchases,
            //         loading: false
            //     }

            // case "GET_ACTIVE_PURCHASES_FAILURE":

            //     return {
            //         ...state,
            //         err: action.message,
            //         loading: false
            //     }

            // case "GET_ARCHIVE_PURCHASES":

            //     return {
            //         ...state,
            //         loading: true
            //     }

            // case "GET_ARCHIVE_PURCHASES_SUCCESS":

            //     return {
            //         ...state,
            //         archivePurchases: action.purchases,
            //         loading: false
            //     }

            // case "GET_ARCHIVE_PURCHASES_FAILURE":

            //     return {
            //         ...state,
            //         err: action.message,
            //         loading: false
            //     }

            // case "GET_AD":
            //     return {
            //         ...state,
            //         loading: true
            //     }

            // case "GET_AD_SUCCESS":
            //     let ad = action.ad;

            //     ad.isPurchased = true;
            //     let res = {};
            //     if (action.PurchaseType === "archive") {
            //         ad.index = state.archiveAds.length;
            //         res = {
            //             ...state,
            //             archiveAds: state.archiveAds.concat(ad),
            //             loading: false
            //         }
            //     } else if (action.PurchaseType === "active") {
            //         ad.index = state.activeAds.length;
            //         res = {
            //             ...state,
            //             activeAds: state.activeAds.concat(ad),
            //             loading: false
            //         }
            //     }
            //     return res

            // case "GET_AD_FAILURE":

            //     return {
            //         ...state,
            //         err: action.message,
            //         loading: false
            //     }



        default:
            return state;
    }
}