import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Fade from 'react-reveal'

//images
import noPromotions from '../img_resources/Shared Components/error.svg'
import loader from '../img_resources/Loaders/purple-loader.svg'

//components
import Title from '../components/Title';
import FeedAd from '../components/Ad Blocks/FeedAd';
import SearchBar from '../components/SearchBar';

//actions
// import {fetchPromotions} from '../actions/promotionsActions'

import UI from '../ui_functions/index.jsx';
import Loader from '../components/Loader';

const Feed = (props) => {

    // const dispatch = useDispatch();

    const [filteredPromotions, setFilteredPromotions] = useState([]);
    const [marginTop, setMarginTop] = useState('15vh');
    const [lastScrollY, setLastScrollY] = useState(0);

    const city = useSelector(store=>store.filter.city.value);
    const promotions = useSelector(store=>store.promotions.promotions);
    const loadingPromotions = useSelector(store=>store.promotions.loading);

    const filters = useSelector(store=>store.filter);

    useEffect(()=>{
        setFilteredPromotions(
            UI.filterOutOfStockAds(
                UI.runFilters(promotions, filters.tag, filters.age, filters.name, filters.live, city)
            )
        )
    }, [promotions, filters])

    // handles the Y-axis scroll of the page
    const handleScroll = e => {
        
        const currentScrollY = e.target.scrollTop;

        if (lastScrollY > currentScrollY && marginTop!=='15vh') {
            setMarginTop('15vh')
        } else {
            setMarginTop('0vh')
        }
        setLastScrollY(currentScrollY)
    };
    
    // if(loadingPromotions && promotions.length===0){
    //     return <Loader/>;
    // } else {
        return(
                <Fade bottom>
                    {/* <div className="new-container" id="feed" onScroll={handleScroll} style={{marginTop:marginTop}}> */}
                    <div className="new-container" id="feed" style={{marginTop:marginTop}}>
                        {
                            loadingPromotions && promotions.length===0?
                            <Loader/>:
                            <div className="content-wrapper" >
                                <Title text={"What's Zestty?"}/>
                                <SearchBar pathname={props.location.pathname}/>
                                {
                                    filteredPromotions.length>0?
                                        filteredPromotions.map(promotion=>
                                            <FeedAd key={promotion.PromotionId} promotion={promotion}/>
                                        ):
                                    <>
                                        <h2 className="no-ads-text" style={{marginTop:"10px"}}>Oops. It seems we don't have any active promotions in {city}. Try searching in another city.</h2>
                                        <img src={noPromotions} alt="no promotions illustration" style={{width:'80vw',height:'auto'}}/>
                                    </>
                                }
                                {
                                    filteredPromotions.length>0 && loadingPromotions?
                                    <img className="icon smaller" src={loader} alt="loader" style={{marginTop:'25px'}}/>:
                                    <></>
                                }
                                <div className="spacer"></div>
                            </div>
                            
                        }
                        
                    </div>
                </Fade>
        )
    // }
    
}

export default Feed;