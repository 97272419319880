import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Fade from 'react-reveal'
import { useHistory, useLocation, Redirect } from 'react-router-dom';

import ZESTTY from '../api/index'

//components
import Title from '../components/Title'
import PurchasedAd from '../components/Ad Blocks/PurchasedAd';
import Token from '../components/Token';
import NewTokenBtn from '../components/Buttons/NewTokenBtn';

//images
import userIcon from '../img_resources/Shared Components/Side Menu/user-menu.svg'
import saveMoney from '../img_resources/Profile/save-money.svg'
import zCreditsIcon from '../img_resources/Profile/coin.svg'

const Profile = (props) => {
    
    // const dispatch = useDispatch();
    
    const tokens = useSelector(store=>store.user.tokens);
    const zCredits = useSelector(store=>store.user.zCredits);
    const amountSaved = useSelector(store=>store.user.amountSaved);
    const user = useSelector(store=>store.user);
    const email = useSelector(store=>store.user.user.attributes.email);
    const isLoggedIn = user.user.hasOwnProperty("username");
    
    const [selectedToken, setSelectedToken] = useState(null);

    let history = useHistory();
    let location = useLocation();

    let {from} = location.state || {from: {pathname:"/"}};
    
    const selectToken = (token) => {
        //if we have a token selected we will deselect it and remove it from the state, otherwise we will insert it into the state
        if(selectedToken===token){
            setSelectedToken(null);
        } else {
            //when selecting a credit card, we will hide the form
            setSelectedToken(token);
        }
    }

    const getProfileImg = () =>{
        try{
            if(user.user.username.includes("acebook")){
                return JSON.parse(user.user.attributes.picture).data.url
            } else {
                return user.user.attributes.picture
            }
        } catch {
            return userIcon
        }
    }

    if(isLoggedIn){
        return(
            <Fade bottom>
                <div className="new-container" id="feed">
                    <div className="content-wrapper profile-wrapper">
                        <Title text={`What's up ${user.user.attributes.given_name}?`}/>
                        <img src={getProfileImg()} alt="user profile" className="profile-image"/>
                        <div className="flex-row space-between">
                            <div className="flex-col w-100" style={{alignItems:"center"}}>
                                <p className="money-saved">₪{amountSaved}</p>

                                <div className="block-title">
                                    Saved
                                </div>
                            

                                <img src={saveMoney} alt="Saving money" className="save-money-image"/>

                            </div>
                        </div>
                        <div className="block-title">
                            Zestty Credits
                        </div>
                        
                        <div className="flex-row" style={{justifyContent:"center"}}>
                            <img src={zCreditsIcon} alt="zestty credits" className="zcredits-icon"/>
                            <div className="datablock">
                                <p className="data">{zCredits}</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Fade>
        )
    } else {
        return <Redirect to={{ pathname: `/SignIn`, state: { from: location } }} />
    }
        

}

export default Profile;