//libraries
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Route, Switch } from "react-router-dom";

//actions
import {setForm, setQR, setCode} from '../../actions/formActions';

//inner libraries
import ZESTTY_BL from '../../ui_functions/index'

//images
import QRIcon from '../../img_resources/Purchases/qr-code.svg'
import Post from '../../img_resources/Feed/post.svg'
// import purchaseIcon from '../../img_resources/icons/purchase-bag.svg'
import loader from '../../img_resources/Loaders/purple-loader.svg'
import whiteLoader from '../../img_resources/Loaders/white-loader.svg'


const PurchaseBtn = ({promotion}) => {
    const dispatch = useDispatch();

    const [purchase, setPurchase] = useState({});
    const [purchaseType, setPurchaseType] = useState("");
    const [isPurchaseSet, setPurchaseFlag] = useState(false);

    const userId = useSelector(store=>store.user.user.username);
    const activePurchases = useSelector(store=>store.purchases.active);
    const archivePurchases = useSelector(store=>store.purchases.archive);
    const isLoadingPurchases = useSelector(store=> store.purchases.loading);

    const openQRCode = () =>{
        console.log(purchase)
        let purchaseData = ZESTTY_BL.generateQRCode(purchase)
        dispatch(setQR(purchaseData.link));
        console.log(purchaseData)
        dispatch(setCode(purchaseData.code));
        dispatch(setForm(promotion, "QR", "Scan Your Purchase"));
    }

    useEffect(()=>{
        if(!isLoadingPurchases){
            checkPurchases();
        }
    }, [isLoadingPurchases])

    const checkPurchases = () =>{
        const purchaseId = promotion.PromotionId + "#" + userId

        //here we will check the active purchases if we haven't set the purchase
        if(activePurchases.length>0 && !isPurchaseSet){
            let filterResult = activePurchases.filter(itm=> itm.purchaseId===purchaseId);
            if(filterResult.length>0 ){
                setPurchase(filterResult[0])
                setPurchaseFlag(true)
                setPurchaseType("active")
            } 
        }

        //here we will check the archive purchases
        if(archivePurchases.length>0 && !isPurchaseSet){
            let filterResult = archivePurchases.filter(itm=> itm.purchaseId===purchaseId);
            // console.log(archivePurchases.indexOf(itm=>itm.purchaseId===purchaseId))

            if(filterResult.length>0){
                setPurchase(filterResult[0])
                setPurchaseFlag(true)
                setPurchaseType("archive")
            }
        }
        
    }
        
    // if(isLoadingPurchases){
        // return <img className="icon smaller" src={loader} alt="loader" />
    // } else {
        return(
            <Switch>
                <Route path="/Promotion">
                    {
                        <div className="purchase-btn-wrapper">
                            <div className="single-ad-purchase" 
                                onClick={
                                    purchase.hasOwnProperty("purchaseId")?
                                        purchaseType==="archive"?
                                            (e)=> {
                                                e.stopPropagation();
                                                console.log("SHARING EXPERIENCE")
                                            }:
                                            (e)=>{
                                                e.stopPropagation();
                                                openQRCode();
                                        }:
                                            (e)=>{
                                                e.stopPropagation();
                                                dispatch(setForm(promotion, "PURCHASE", "Purchasing"));
                                            }
                                }
                            >
                                {
                                    isLoadingPurchases?
                                        <img className="icon smaller" src={whiteLoader} alt="loader" />:
                                        purchase.hasOwnProperty("purchaseId")?
                                            purchaseType==="archive"?
                                                "Share your experience": // archived purchase
                                                "Scan now!"              // active purchase
                                            :"Get this deal now!"        // no purchase
                                }

                            </div>
                        </div>
                        
                    }
                </Route>
                <Route path="/">
                    {
                        isLoadingPurchases?
                        <img className="icon smaller" src={loader} alt="loader" />:
                            purchase.hasOwnProperty("purchaseId")?
                            <img className="icon smaller"
                                src={purchaseType==="archive"?Post:QRIcon} 
                                alt={purchaseType==="archive"?"Post Icon":"QR icon"}
                                onClick={
                                        purchaseType==="archive"?
                                        (e)=>{
                                            e.stopPropagation();
                                            console.log("SHARING EXPERIENCE")
                                        }:
                                        (e)=>{
                                            e.stopPropagation();
                                            openQRCode();
                                        }
                                    }
                            />:
                            <div className="test-purchase-btn"
                            onClick={(e)=>{
                                    e.stopPropagation();
                                    dispatch(setForm(promotion, "PURCHASE", "Purchasing"));
                                }   
                            }>
                                BUY
                            </div>
                        
                    }
                </Route>
            </Switch>
        )
    // }  
    
}

export default PurchaseBtn;