export function setForm(promotion, action, text) {
    return {
        type: "SET_FORM",
        promotion: promotion,
        action: action,
        text: text
    }
}

export function setPayingLoading(status) {
    return {
        type: "SET_FORM_LOADING",
        loading: status
    }
}

export function setFormText(text) {
    return {
        type: "SET_FORM_TEXT",
        text: text
    }
}

export function setQR(link) {
    return {
        type: "SET_QR",
        QR: link
    }
}

export function setCode(code) {
    return {
        type: "SET_CODE",
        code: code
    }
}