import React, {useState} from 'react';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';

//actions
import {postReport} from '../../actions/promotionsActions';
import {setForm} from '../../actions/formActions'


const Report =() => {

    const dispatch = useDispatch();
    
    const form = useSelector(store=>store.form);
    const userId = useSelector(store=>store.user.user.username);

    const [reportType, setReportType] = useState("");
    
    const options = [
        {
          value: "Innappropriate", label: "Inappropriate Content"
        },
        {
          value: "Spam", label: "Spam Content"
        },
        {
          value: "Inaccurate", label: "Not Accurate Location"
        },
        {
            value: "Discount no Given", label: "Didn't get promotion"
        }
    ];

    const handleSubjectChange = selectedOption => {
        setReportType(selectedOption.value);
      };

    const Selector = (options) => {
        const customStyles = {
            valueContainer: (provided) => ({
                ...provided,
                paddingLeft: '0px',
            }),
            control: () => ({
                // none of react-select's styles are passed to <Control />
                border: '#CECECE solid',
                display: 'flex',
                flexWrap: 'nowrap',
                borderWidth: '0px 0px 1px 0px',
                width: '100%'
            }),
            indicatorSeparator: () => ({
                // none of react-select's styles are passed to <Control />
                display: 'none',
            })
        }
        return (
            <div className="" style={{width:"100%"}}>
                <Select 
                styles={customStyles}
                options={options}
                name="reportReason" 
                placeholder="Reason"
                onChange={handleSubjectChange}
                />
            </div>
        )
    }


    const postReportFlow = () => {
        dispatch(postReport(
            form.promotion.PromotionId, 
            userId, 
            reportType, 
            document.getElementById("reportMessage").value,
            form.promotion.AppName
            ));
            //close the form;
            dispatch(setForm(form.promotion,"REPORT SUCCESS","Thank you for helping us make a difference, we will further examine the incident with  "+form.promotion.Name))
    }

    
    return(
        <div className="report-form">
            {/* <div className="report-form-label">Reason</div> */}
            {Selector(options)}
            {/* <div className="report-form-label m-t-10">Message</div> */}
            <textarea className="report-message" placeholder="Your message" name="message" id="reportMessage" cols="25" rows="5"></textarea>

            <div className="report-submit-button" 
            onClick={()=>{postReportFlow()}
                }>Send Report</div>

        </div>
    )
}

export default Report;