import React from 'react';
import {useDispatch} from 'react-redux';
import { NavLink } from 'react-router-dom';

//images
import successImage from '../../img_resources/Forms/Purchase/success.svg'

//actions
import {setForm} from '../../actions/formActions'


function PurchaseSuccess() {
    const dispatch = useDispatch();
    
    return(      
        <>
            <img src={successImage} alt="login" className="login-image"/>
            <h1 style={{maxWidth:"270px"}} className="login-headline">We hope you have a Zestty time!</h1>
            <NavLink 
                to={'/Purchases'} 
                className="instructions-next-button" 
                style={{color:"white", backgroundColor:"#564CAC", textDecoration:"none", position: 'initial'}} 
                onClick={()=>{dispatch(setForm({}, "Hide", ""))}}
            >
                View Purchases
            </NavLink>
            <div style={{width:'100vw'}}></div>
        </>
    )
}
export default PurchaseSuccess;