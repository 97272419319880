import React from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {postLike, deleteLike} from '../../actions/userActions';
import {setFetchedLikes, fetchSavedPromotions} from '../../actions/promotionsActions';

//images
import heartHollowIcon from '../../img_resources/Feed/heart-hollow.svg'
import heartFullIcon from '../../img_resources/Feed/like-full.svg'
import loader from '../../img_resources/Loaders/purple-loader.svg'

const LikeBtn = ({promotion}) => {
    const dispatch = useDispatch();

    const userId = useSelector(store=>store.user.user.username);
    const likes = useSelector(store=>store.user.likes);
    const likeLoader = useSelector(store=>store.user.likeLoader);
    const promotions = useSelector(store=>store.promotions.promotions);
    
    return(                    
        <>
        {
            likeLoader?
                <img className="icon smaller" src={loader} alt="loader" />:
                <img 
                className="icon smaller like-btn" 
                src={likes.includes(promotion.AdvertiserId)?
                    heartFullIcon: 
                    heartHollowIcon} 
                alt="like icon" 
                onClick={
                    !likes.includes(promotion.AdvertiserId)?
                    ()=>{
                        dispatch(postLike(userId, promotion.AdvertiserId));
                        dispatch(fetchSavedPromotions([promotion.AdvertiserId], promotions))
                    }:
                    ()=>{
                        dispatch(deleteLike(userId, promotion.AdvertiserId));
                    }
                }
            /> 
        }
        </>
    )
    
}

export default LikeBtn;