import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useHistory, useLocation} from 'react-router-dom'

import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'

//images
import step1Image from '../img_resources/Confirmation/phone-confirmation.svg'
import step2Image from '../img_resources/Confirmation/code-confirmation.png'
import loaderImage from '../img_resources/Loaders/white-loader.svg'
import phoneError from '../img_resources/Popup Modal/phone-error.svg'
import backArrow from '../img_resources/Confirmation/back.svg'

//actions
import {setForm} from '../actions/formActions'
import {getUserData, setFirstTime} from '../actions/userActions'
import {setModal} from '../actions/popupModalActions'

//components
import Title from '../components/Title';
import Loader from '../components/Loader'

import ZESTTY_API from '../api'

const ConfirmPhone = () => {
    const dispatch = useDispatch();
    
    const [phoneNumber, setPhoneNumber] = useState()
    const [validNumber, setValidNumber] = useState(false)
    const [resentCode, setResentCode] = useState(false)
    // const [stage, setStage] = useState("Code")
    const [stage, setStage] = useState("Phone")
    const [stageChanged, setStageChanged] = useState(false)
    const [awaitingResponse, setAwaitingResponse] = useState(false)


    const input1 = React.createRef();
    const input2 = React.createRef();
    const input3 = React.createRef();
    const input4 = React.createRef();
    
    const [code1, setCode1] = useState(undefined)
    const [code2, setCode2] = useState(undefined)
    const [code3, setCode3] = useState(undefined)
    const [code4, setCode4] = useState(undefined)
    
    const userId = useSelector(store=>store.user.user.username);
    const user = useSelector(store=>store.user);
    const loading = useSelector(store=>store.user.loadingData);

    let history = useHistory();
    let location = useLocation();

    let {from} = location.state || {from: {pathname:"/"}};

    //handles phone submission - blocks the function if it awaiting a response from the server
    const phoneSubmitHandler = async(event) => {
        event.preventDefault();
        if(awaitingResponse){

        } else {
            return await submitPhone(false);
        }
        
    }

    //breaks up the code from 1 input field and spreads it across the other inputs
    const breakUpCode = () => {
        if (code1.length>1){
            switch(code1.length){
                case 4:
                    setCode4(code1[code1.length-1])
                    setCode1(code1.slice(0,code1.length-1))
                    break;
                
                case 3:
                    setCode3(code1[code1.length-1])
                    setCode1(code1.slice(0,code1.length-1))
                    break;

                case 2:
                    setCode2(code1[code1.length-1])
                    setCode1(code1.slice(0,code1.length-1))
                    break;

                default:
                    break;
            }
        }
    }

    //submits the code and/or the phone number
    const submitPhone = async(resendCode) => {
        let code = undefined;
        if(!resendCode){
            code = !!code1? `${code1}${code2}${code3}${code4}` : undefined;
        }
        
        setAwaitingResponse(true);
        //sends the phone number and the code to the backend
        const res = await ZESTTY_API.confirmPhoneHandler(userId,phoneNumber,code)
        
        setAwaitingResponse(false);
        
        //if the user is now verified we redirect to the feed
        if(res.isVerified){
            dispatch(getUserData(userId))
            // dispatch(setFirstTime(false));
            console.log("PUSHING FROM TOP")
            return (<Redirect to={'/Feed'}/>)
            //if we have an error, we show the error
        } else if (!!res.errorMessage) {
            console.log(res.errorMessage)
            dispatch(setModal("error","Phone Number Exists", "Oops. It seems that phone number is already connected to a different account", phoneError))
            //if the user is not verified and there is no error, we move to the next stage (The code stage)
        } else {
            setStage("Code")
        }
    }

    //sets the classname of the submit phone number button
    const submitPhoneButtonClass = () => {
        if(validNumber){
            return true
        }
        return false
    }

    //sets the classname of the submit code button
    const submitCodeButtonClass = () => {
        let code = `${code1}${code2}${code3}${code4}`;
        if(code.length===4){
            return true
        }
        return false
    }

    //clears the code that is currently inputed
    const clearCode = (event) => {
        event.preventDefault();
        
        setCode1("");
        setCode2("");
        setCode3("");
        setCode4("");

        input1.current.focus();
    }

    //if all 4 code input boxes have values, we will send the code to ther server
    useEffect(()=>{
        if(code1 && code2 && code3 && code4){
            submitPhone(false)
        }
        //if all 4 digits are in the first input box - we will copy and paste it into the other inputs
        if(code1){
            if(code1.length>1){
                console.log("breaking up code")
                breakUpCode()
            }
        }
    },[code1,code2, code3, code4])

    //on stage change we change the focus to be on the first input box
    useEffect(()=>{
        
        if(stage==="Code" && !stageChanged){
            input1.current.focus();
            setStageChanged(true)
        }
    },[stage])

    //checks if the phone number is a valid phone number
    useEffect(()=>{
        setValidNumber(isValidPhoneNumber(phoneNumber))
    }, [phoneNumber])

    //if the user has been verified, we will redirect to the page that initially sent him to our website
    useEffect(()=>{
        if(user.isVerified && from.pathname!=="/SignIn"){
            // console.log("PUSHING FROM BOT", window.sessionStorage.getItem("returnUrl"))
            // history.push(window.sessionStorage.getItem("from")? window.sessionStorage.getItem("from"): "/Feed")
            history.push('/')
        }
    }, [user.isVerified])

    

    // if(loading){
    //     return( <Loader/> )
    // } else {
        return(
            <>
            {
                !!userId?
                    !!user.isVerfied?
                    <Redirect to={'/Feed'}/>:
                    <div className="confirm-container" id="phone-confirm">
                        <Title text={`Welcome ${user.user.attributes.given_name}!`}/>
                        {
                            loading?
                            <Loader/>:
                            <>
                                {
                                    stage==="Code"?
                                    <button className="phone-back-button" onClick={()=>{setStage("Phone")}}>
                                        Back
                                        <img src={backArrow} alt="back arrow" className="phone-back-arrow"/>
                                    </button>:
                                    <></>
                                }
                                <img src={stage==="Phone"?step1Image:step2Image} alt="phone" style={{width:"auto", maxWidth:"680px", height:"260px"}}/>
                                {
                                    stage==="Phone"?
                                    <h1 className="login-headline">Your Phone Number</h1>:
                                    <p className="phone-text-message">A verification code has been sent to <span className="highlighted-text">{phoneNumber}</span></p>
                                }
                                

                            {
                                stage==="Phone"?
                                    <form className='phone-form' onSubmit={phoneSubmitHandler}>
                                        <PhoneInput
                                            style={{width:'85%', marginBottom:'15px'}}
                                            flags={flags}
                                            defaultCountry="IL"
                                            withCountryCallingCode
                                            international
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            onChange={setPhoneNumber}
                                            name="phoneNo"
                                            />
                                    <button disabled={!validNumber}
                                        className={
                                            submitPhoneButtonClass()?
                                                "button purple":
                                                "button gray"
                                            }
                                        >
                                        {
                                            awaitingResponse?
                                            <img src={loaderImage} alt="loader" className="icon smaller"/>:
                                            <p>Submit</p>
                                        }
                                    </button>
                                    {
                                        stage==="Code"?
                                            <button
                                                className={!resentCode?"button orange":"button gray"}
                                                disabled={resentCode}
                                                onClick={() => {
                                                    submitPhone();
                                                    setResentCode(true)
                                                }}>
                                                <p>Resend Code</p>
                                            </button>
                                            :
                                        <></>
                                    }
                                    
                                </form>:
                                    <form className='phone-form' onSubmit={phoneSubmitHandler}>
                                        <div className="flex-row" style={{display:"relative", paddingBottom:"10px"}}>
                                            <input type="number" ref={input1} className="confirm-digit" autoComplete="new-off" onChange={(e) => {setCode1(e.target.value); input2.current.focus()}} value={code1}/>
                                            <input type="number" ref={input2} className="confirm-digit" autoComplete="new-off" onChange={(e) => {setCode2(e.target.value); input3.current.focus()}} value={code2}/>
                                            <input type="number" ref={input3} className="confirm-digit" autoComplete="new-off" onChange={(e) => {setCode3(e.target.value); input4.current.focus()}} value={code3}/>
                                            <input type="number" ref={input4} className="confirm-digit" autoComplete="new-off" onChange={(e) => {setCode4(e.target.value); input4.current.blur()}} value={code4}/>
                                            <button className="clear-btn" onClick={clearCode}>clear</button>
                                        </div>
                                        <button
                                            className={
                                                submitCodeButtonClass()?
                                                    "button purple":
                                                    "button gray"
                                                }
                                            disabled={!submitCodeButtonClass()}
                                            >
                                            {
                                                awaitingResponse?
                                                <img src={loaderImage} alt="loader" className="icon smaller"/>:
                                                <p>Submit</p>
                                            }
                                            
                                        </button>
                                    
                                        <button
                                            className={!resentCode?"button orange":"button gray"}
                                            disabled={resentCode}
                                            onClick={() => {
                                                submitPhone(true);
                                                setResentCode(true)
                                            }}>
                                            <p>Resend Code</p>
                                        </button>
                                    </form>
                            }
                            <p className="terms-link" >
                                By continuing, you agree to Get Zestty's
                                <label 
                                    className="terms-highlight" 
                                    onClick={()=>{
                                        dispatch(setForm({},"TERMS", ""))
                                    }}
                                    >Terms of Service
                                </label>
                                    and
                                <label 
                                    className="terms-highlight" 
                                    onClick={()=>{
                                        dispatch(setForm({},"TERMS", ""))
                                    }}
                                    >Privacy Policy
                                </label>
                            </p>
                            </>
                        }
                        </div>:
                <Redirect to={'/SignIn'}/>
            }
        </>
    )
    // }
    
    
}
export default ConfirmPhone;