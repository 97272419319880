import React from 'react';
import {useSelector} from 'react-redux';

//images
import loginImage from '../img_resources/Sign In Page/login.svg'

//actions
import { useHistory, useLocation, Redirect } from 'react-router-dom';

const Purchase = (props) => {
    
    const user = useSelector(store=>store.user.user);
    const isLoggedIn = user.hasOwnProperty("username");

    console.log(isLoggedIn)

    // const dispatch = useDispatch();

    let history = useHistory();
    let location = useLocation();

    let {from} = location.state || {from: {pathname:"/"}};
    console.log(history)
    console.log(location)
    console.log("THIS IS THE FROM ",from)

    if(isLoggedIn){
        return(
            <>
                <div className="post-purchase">
                    <img src={loginImage} alt="login" className="login-image"/>
                    {"We hope you have fun! " + props.params.get('UserId')}
                </div>                
            </>
        )
    } else {
        return <Redirect to={{ pathname: `/SignIn`, state: { from: location } }} />
    }
        

}

export default Purchase;