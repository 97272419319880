//libraries
import React from 'react';

const FormAd = ({promotion}) => {
    return(  
        <>
            <div className="flex-row ad-container-form">
                <div className="form-img" style={{backgroundImage: "url("+promotion.Image+")"}}/>

                <div className="flex-col" style={{width:'100%', padding:'0px 15px'}}>
                    <div className="flex-row space-between">
                        <div className="flex-col">
                            <div className="form-promotion-text">{promotion.Promotion}</div>
                            <div className="map-promotion-name" style={{fontSize:'15px', width:'100%', textAlign:'left', marginTop: '5px'}}>{promotion.Name}</div>
                        </div>
                        
                        <div className="form-price-wrapper">
                            <div className="form-original-price">₪{promotion.OriginalPrice}</div>
                            <div className="form-price" >₪{promotion.Price}</div>
                        </div>
                    </div>

                    
                </div>
                
            </div>
        </>
    )
}

export default FormAd;