export const FAQs = [
    { question: "What is Get Zestty?🎉", answer: "Get Zestty is an app that brings you the best deals in real-time, making your experience of going out fun and easy. \nYou can explore countless deals in any way you'd like. Whether you prefer to scroll through our feed, or discover new places in our discover page, we've got you covered. \nP.S. you can also search for deals around you on our map." },
    { question: "Why should I use Get Zestty?🥳", answer: "The deals on Get Zestty are exclusive to our platform only, and we guarantee the best prices with our partners. \nGo out for less." },
    { question: "How does it work?🎁", answer: "It's actually quite simple, all you need to do is follow 3 simple steps: \n1) Find the deal you want 🔍 \n2) Buy it quickly before it runs out 💵 \n3) Show your code at the venue🤳 \nMost importantly, have fun! 🥳" },
    { question: "What payment options do we accept?💳", answer: "Currently, we are only accepting credit cards, however, we are working on accepting more payment methods for your convenience." },
    { question: "Where do you store my credit card information?🔒", answer: "We don't save your payment information. We use a third party service to better encrypt and protect your sensitive information." },
    { question: "What are Z-Credits?💰", answer: "Z-Credits are tokens you can use to lower the prices of promotions even further! \nYou can get Z-Credits from inviting friends to our platform and from sharing deals. \nRemember, you only get Z-Credits after your friends have purchased a deal you've sent them, so make sure they use your links!" },
    { question: "What happens when I like a deal?❤️", answer: "When you like a deal, you're actually liking the venue. \nSo what this means is that from now on, this venue and all of their deals will be in your personal saved feed. \nWe'll also make sure to send you a message when your favorite places post a new deal so you never miss out again!" },
    { question: "I liked a deal, but more deals were liked, what's going on?💕", answer: "Well, we work a bit different than traditional platforms, when you like a promotion on Get Zestty, we save the business that uploaded that promotion for you. \nWhen you like and save a deal, you're actually saving the venue that posted that deal. \nSo now you can see all of the deals that venue on your saved feed! \nSo go on and see if you can find something for everyone from the same promoter." },
    { question: "Sharing is caring!🎉👨👩👫👩👨🎉", answer: "Remember to always share promotions with your friends before going out to earn Z-Credits!" },
    { question: "What does the purple bar mean?", answer: "It indicates how many more times this promotion can be purchased. Be sure to grab your promotion before it runs out." },
    { question: "I can't see any active promotions in my city. What should I do?", answer: "Perhaps there are no businesses that are currently promoting anything in your city right now, check out another city." }
]