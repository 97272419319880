import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Fade from 'react-reveal'

//images
import noAds from '../img_resources/Shared Components/error.svg'

//actions
import {setTagFilter, setName} from '../actions/filterActions';

//components
import Title from '../components/Title';
import DiscoverRow from '../components/DiscoverRow';
import SelectedDiscoverRow from '../components/SelectedDiscoverRow';
import PopularTags from '../components/PopularTags';
import Loader from '../components/Loader'

import UI from '../ui_functions/index.jsx';



const Discover = (props) => {

    const dispatch = useDispatch();

    const promotions = useSelector(store=>store.promotions.promotions);
    const filters = useSelector(store=>store.filter);
    const tagsByOrder = useSelector(store=>store.filter.tagsOptions);
    const city = useSelector(store=>store.filter.city.value);
    const loadingPromotions = useSelector(store=>store.promotions.loading);

    const [filteredPromotions, setFilteredPromotions] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    
    const [popularTagsShadow, setShadow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    //handles promotion changes
    useEffect(()=>{
        dispatch(setName(undefined))
        dispatch(setTagFilter([]))
        if(promotions.length>0){
            setFilteredPromotions(
                UI.filterOutOfStockAds(
                    UI.runFilters(promotions, filters.tag, filters.age, filters.name, filters.live, city)
                )
            )

        }
    },[dispatch, promotions])

    //handles filter changes
    useEffect(()=>{
        if(promotions.length>0){
            setFilteredPromotions(
                UI.filterOutOfStockAds(
                    UI.runFilters(promotions, filters.tag, filters.age, filters.name, filters.live, city)
                )
            )

        }
    },[filters, selectedTag])

    //sets the selected tag
    const selectTag = (tag) => {
        if(selectedTag===tag){
            setSelectedTag(null)
        }
        else {
            console.log("setting selection to ", tag)
            setSelectedTag(tag)
        }
        
    }

    // removes the selected tag
    const removeSelectedTag = () => {
        setSelectedTag(null)
    }

    // handles the Y-axis scroll of the page
    const handleScroll = e => {
        
        const currentScrollY = e.target.scrollTop;

        if (lastScrollY > 5) {
            setShadow(true)
        } else {
            setShadow(false)
        }
        setLastScrollY(currentScrollY)
    };

    // if(loadingPromotions){
    //         return <Loader/>;
    //     } else {
            return(

                <Fade bottom>
                    <div className="new-container" id="discover" onScroll={handleScroll}>
                        {
                            loadingPromotions && promotions.length===0?
                            <Loader/>:
                            <div className="content-wrapper">
                            
                                <Title text={"Discover "+ city} filtersButton={true}/>

                                <PopularTags selectTag={selectTag} selectedTag={selectedTag} shadow={popularTagsShadow}/>

                                {
                                    filteredPromotions.length===0?
                                    <>
                                        <h2 className="no-ads-text" style={{marginTop:"10px"}}>Oops. It seems we don't have any active promotions in {city}</h2>
                                        <img src={noAds} alt="no ads illustration" style={{width:'80vw',height:'auto'}}/>
                                    </>:
                                    <></>
                                }

                                {
                                    selectedTag===null?
                                        tagsByOrder.map(tag=>
                                            //need to add view all
                                            <DiscoverRow key={"row-"+tag} promotions={filteredPromotions} tag={tag} selectTag = {selectTag} mostPopular={tagsByOrder[0]}/>
                                        ):
                                        <SelectedDiscoverRow promotions = {filteredPromotions} tag={selectedTag} removeSelectedTag={removeSelectedTag} />
                                }
                            </div>
                        }
                        
                    </div>
                </Fade>
            )
        // }
}

export default Discover;