import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

//images
import Lame from '../../img_resources/Forms/Feedback/Service/lame.svg'
import Ehhhhh from '../../img_resources/Forms/Feedback/Service/ehhh.svg'
import Aok from '../../img_resources/Forms/Feedback/Service/a-ok.svg'
import Groovy from '../../img_resources/Forms/Feedback/Service/groovy.svg'
import RockOn from '../../img_resources/Forms/Feedback/Service/rock-on.svg'


const Feedback = ({promotion}) => {

    const dispatch = useDispatch();

    const userId = useSelector(store=>store.user.user.username);

    return(         
        <>
        {
            <div className="share-icons-wrapper" style={{paddingBottom:'5vh'}}>
                <div 
                    className="feedback-icon-wrapper" 
                    >
                    <img src={Lame} alt="link Icon" className="feedback-icon"/>
                    <label htmlFor="Lame">Lame</label>
                </div>
                <div 
                    className="feedback-icon-wrapper" 
                    >
                    <img src={Ehhhhh} alt="link Icon" className="feedback-icon"/>
                    <label htmlFor="Ehhh">Ehhh</label>
                </div>
                <div 
                    className="feedback-icon-wrapper" 
                    >
                    <img src={Aok} alt="link Icon" className="feedback-icon"/>
                    <label htmlFor="Aok">A-Ok</label>
                </div>
                <div 
                    className="feedback-icon-wrapper" 
                    >
                    <img src={Groovy} alt="link Icon" className="feedback-icon"/>
                    <label htmlFor="Groovy">Groovy</label>
                </div>
                <div 
                    className="feedback-icon-wrapper" 
                    >
                    <img src={RockOn} alt="link Icon" className="feedback-icon"/>
                    <label htmlFor="RockOn">Rock On!</label>
                </div>
            </div>
        }
        </>
    )
}


export default Feedback;