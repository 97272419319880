const initialState = {
    google: undefined
}

export const googleReducer = (state = initialState, action) => {

    switch (action.type) {

        case "SET_GOOGLE":
            return {
                ...state,
                google: action.google
            }

        default:
            return state;
    }
}