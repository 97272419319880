//libraries
import React, {useState, useEffect} from "react";
import {connect, useSelector, useDispatch} from 'react-redux';
import Select from 'react-select';
import Fade from 'react-reveal/Fade';

//actions
import {setCity,setAge, setName, setLive,toggleFiltersShow} from '../actions/filterActions';

//images
import closeIcon from '../img_resources/Preferences Screen/close.svg';
// import checkmarkIcon from '../img_resources/Preferences Screen/checkmark.png';
import preferencesImg from '../img_resources/Preferences Screen/preferences.png'

import UI from '../ui_functions'

const Filters = (props) =>{
  const dispatch = useDispatch();

  const age = useSelector(store=>store.filter.age);
  const city = useSelector(store=>store.filter.city);
  const name = useSelector(store=>store.filter.name);
  const live = useSelector(store=>store.filter.live);

  const names = useSelector(store=>store.filter.namesOptions);
  const cities = useSelector(store=>store.filter.citiesOptions);

  const show = useSelector(store=>store.filter.show);
  const [btnActive, setBtnActive] = useState(false);

  const [ageState, setAgeState] = useState(age.value);
  const [cityState, setCityState] = useState(city.value);
  const [nameState, setNameState] = useState(name.value);
  const [liveState, setLiveState] = useState(live.value);
  
  useEffect(()=>{
    setAgeState(age.value)
    setCityState(city.value)
    setNameState(name.value)
    // setLiveState(age.value)
  },[age, city, name, live])

  useEffect(()=>{
    let flag = false;
    if(city.value!==cityState){
      setBtnActive(true)
      flag = true;
    }
    if(name.value!==nameState)
    {
      setBtnActive(true)
      flag = true;
    }
    //age
    if(age.value!==ageState)
    {
      setBtnActive(true)
      flag = true;
    }
    //live
    // if(live.active!==liveState)
    // {
    //   console.log("live is different")
    //   console.log(live.active)
    //   console.log(liveState)
    //   setBtnActive(true)
    // }
    if(!flag){
      setBtnActive(false)
    }
    
  }, [ageState, cityState, nameState])

  //Age
  const ageFilterHandler = (e) => {
    e.preventDefault();
    let newAge = document.getElementById('age-field').value;
    if(newAge!==""){
      setAgeState(parseInt(newAge))
    } else {
      setAgeState(undefined)
    }
  }

  const incrementAge = ()=>{
    document.getElementById('age-field').value = ageState+1;
    let newAge = document.getElementById('age-field').value;
    if(newAge!==""){
      setAgeState(parseInt(newAge))
    } 
  }

  const decrementAge=()=>{
    document.getElementById('age-field').value = ageState-1;
    let newAge = document.getElementById('age-field').value;
    if(newAge!==""){
      setAgeState(parseInt(newAge))
    } 
  }

  //City
  const cityFilterHandler = (selectedOption) => {
    setCityState(selectedOption.value)
  }

  //Name
  const nameFilterHandler = (selectedOption) => {
      try{
        setNameState(selectedOption.value);
      } catch{
        setNameState(undefined);
      }
  }

  const updateFilters = () =>{
    //city
    if(city.value!==cityState){
      console.log("STATE CITY", cityState)
      dispatch(setCity(cityState));
    }
    //name
    if(name.value!==nameState)
    {
      try{
        dispatch(setName(nameState));
      } catch{
        dispatch(setName(undefined))
      }
    }
    //age
    if(age.value!==ageState)
    {
      dispatch(setAge(ageState))
    }
    //live
    if(live.active!==liveState)
    {
      dispatch(setLive(liveState)); 
    }
  }

  const Selector = (options, multi, name, filterHandler, clearable) => {
    const customStyles = {
        valueContainer: (provided) => ({
          ...provided,
          paddingLeft: '0px',
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
          border: '#CECECE solid',
          display: 'flex',
          flexWrap: 'nowrap',
          borderWidth: '0px 0px 1px 0px'
        })

    }
    let disabled = false;
    if(window.location.href.includes("Discover") && (name==="TagsBox" || name === "nameFilter")){
      disabled = true;
    }

    return(
      <div className="select" id={name} style={{width:"100%"}}>
        <Select 
        menuPlacement="bottom"
        
        styles={customStyles}
        options={options}
        isMulti={multi}
        name={name}//"cityFilter" 
        isSearchable={true} 
        onChange={filterHandler}//{this.updateCityFilter}
        isClearable = {clearable}
        isDisabled = {disabled}
        defaultValue={name==="cityFilter"?{value:city.value,label:city.value}:""}
        />
      </div>
    )
  }

  return(
    <>
      <Fade top duration={750} when={show} >
        <div className={"filters"} style={show?{zIndex:4}:{zIndex:0}}>
        <div className="content-wrapper">
          <div className="filter-section-title-row">
            <div className="filter-title">Preferences</div> 
            <img src={closeIcon} alt="close Icon" onClick={() => {dispatch(toggleFiltersShow())}} style={{height:'13px',width:'13px'}}/>
          </div>
          
          <img src={preferencesImg} alt="preferences illustration" className="preferences-img"/>

          <div className="filter-type-title">City</div>
          
          {
            show?
              Selector(UI.prepareSelectorOptions(cities), false, "cityFilter",cityFilterHandler, false):
              <></>
          }

          <div className="filter-type-title">By Name</div>
          
          {Selector(UI.prepareSelectorOptions(UI.convertNameObjToArr(names)), false, "nameFilter", nameFilterHandler, true)}

          <div className="filter-type-title">Min. Age</div>
          <div className="filters-row">
            <form id="AgeForm" onSubmit={ageFilterHandler} className="age-form center">
              <div className="age-control"
              style={{color:ageState!==undefined?"#564CAC":"#ced4da"}} 
              onClick={
                ageState!==undefined?
                  ()=> decrementAge():
                  ()=> function(){}}
              >
              -
              </div>

              <input className="age-box" id="age-field" type="number"  name="ageFilter" onChange={ageFilterHandler}/>
              
              <div className="age-control"
              style={{color:ageState!==undefined?"#564CAC":"#ced4da"}}  
              onClick={
                ageState!==undefined?
                  ()=> incrementAge():
                  ()=> function(){}}
              >
              +
              </div>
            </form>
            
            {/* <div className="flex-row live-filter-wrapper">
              <div className="checkmark-wrapper" onClick={()=>{this.setState({live:!this.state.live})}}>
              {
                this.state.live?
                  <img className="checkmark-icon" src={checkmarkIcon} alt="checkmark icon"/>:
                  <></>
              }  
              </div>
              
              <div className="filter-type-title" style={{marginTop:'15px'}}>Show only promotions with "live" images</div>
            </div> */}
            


          </div>
          <div 
            className="apply-filters-btn" 
            style={btnActive?{backgroundColor:"#564CAC"}:{backgroundColor:"#CECECE"}} 
            onClick={btnActive?
              ()=>{
                updateFilters();
                dispatch(toggleFiltersShow());
              }:
              ()=>{}
            }>Apply</div>
        </div>  
      </div>
      </Fade>
    </>
  );
}


export default Filters;