import React, { useEffect, useState } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import {GoogleApiWrapper} from 'google-maps-react'

import imageCompression from 'browser-image-compression';

//CSS
import './App.css';
import 'react-phone-number-input/style.css'

//components
import Navbar from './components/Navbar';
import Form from './screens/Form';
import PopupModal from './screens/PopupModal';
import PaymentMethodsPopupModal from './screens/PaymenthMethodsPopupModal';
import Filters from './screens/Filters';
import Header from './components/Header';
import instructionsSteps from './components/InstructionSteps'

//actions
import {logInSuccess, checkUser, setLocation, FetchUserData, setFirstTime} from './actions/userActions';
import {setCity, fetchTags, fetchNames, fetchCities} from './actions/filterActions'
import { fetchPromotions, fetchSavedPromotions } from './actions/promotionsActions';
import { fetchPurchases } from './actions/purchasesActions';
import {setGoogle} from './actions/googleActions';

import UI from './ui_functions/index.jsx';

//AWS
import { Hub } from 'aws-amplify'
import Amplify from 'aws-amplify';
import config from './aws-exports';

import comingSoon from './img_resources/Popup Modal/coming-soon.svg';

// import scrollIcon from './img_resources/instructions/New/scroll.svg'
// import instructionsScreenshot from './img_resources/instructions/New/Screenshot.png'
import Tour from 'reactour'
import Routes from './screens/Routes';

// import Loader from './components/Loader';

//configures login redirects
const configureOAuthAWS = () =>{
  // copied from serviceWorker.js to know if it is localhost or not
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  // by default, say it's localhost
  const oauth = {
    // domain: 'hotplacezc523ea7a-c523ea7a-dev.auth.us-east-2.amazoncognito.com',
    domain: 'getzestty.auth.us-west-2.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],

    redirectSignIn: 'http://localhost:3000/SignIn/',
    redirectSignOut: 'http://localhost:3000/',
    // redirectSignIn: 'https://www.getzestty.com/SignIn/,http://localhost:3000/SignIn/',
    // redirectSignOut: 'https://www.getzestty.com/,http://localhost:3000/',
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  };

  // if not, update the URLs
  if (!isLocalhost) {
    oauth.redirectSignIn = 'https://www.getzestty.com/SignIn/';
    oauth.redirectSignOut = 'https://www.getzestty.com/';
  }


  // copy the constant config (aws-exports.js) because config is read only.
  var configUpdate = config;
  // update the configUpdate constant with the good URLs
  configUpdate.oauth = oauth;

  // Configure Amplify with configUpdate
  Amplify.configure(configUpdate);
}

configureOAuthAWS();

const App = ({google}) => {

  const dispatch = useDispatch();
  const location = useLocation();

  // const city = useSelector(store=>store.filter.city.value);
  const user = useSelector(store=>store.user);
  // const form = useSelector(store=>store.form);
  // const refId = useSelector(store=>store.user.refId);
  // const googleStore = useSelector(store=>store.google.google);


  const checkingUser = useSelector(store=>store.user.checkingUser);
  // const alreadyCheckedUser = useSelector(store=>store.user.alreadyCheckedUser);
  const loadingUserData = useSelector(store=>store.user.loadingData);

  const promotions = useSelector(store=>store.promotions.promotions);
  const promotionsLoader = useSelector(store=>store.promotions.loading);
  const filters = useSelector(store=>store.filter);

  // const activePurchases = useSelector(store=>store.purchases.active)
  // const archivePurchases = useSelector(store=>store.purchases.archive)

  const fetchedSavedPromotions = useSelector(store=>store.promotions.fetchedLikes);

  const firstTime = useSelector(store=>store.user.firstTime);
  const [loadingFirstTime, setLoadingFirstTime] = useState(false); 
  const [isTourOpen, setIsTourOpen] = useState(true); // tour

  const imageCompressionOptions = {
    maxSizeMB: 0.7,
    maxWidthOrHeight: 1920,
    useWebWorker: false
  }

  useEffect(()=>{
    // console.log("Testing", promotions.length, user.isVerified)
    if(promotions.length>0 && user.isVerified && firstTime && !isTourOpen && !loadingFirstTime){
      // console.log("IN")
      setLoadingFirstTime(true)
      UI.sleep(1200)
        .then(() => {
          setIsTourOpen(true)
          setLoadingFirstTime(false)
          dispatch(setFirstTime(user.user.username, false));
        })
        .catch(err => console.log(err))
    }
  },[user.isVerified, promotionsLoader])

//AMPLIFY VER

  //AUTHENTICATION

  //creates a listener for sign in and sign outs
  useEffect(() => {
    // set listener for auth events
    Hub.listen('auth', (data) => {
      const { payload } = data
      if (payload.event === 'signIn') {
        if(!checkingUser){
          //sets the user in the store
          dispatch(checkUser());
          // history.push(from.pathname)
        }
      }
      // this listener is needed for form sign ups since the OAuth will redirect & reload
      if (payload.event === 'signOut' && !checkingUser) {
        dispatch(logInSuccess({user:{ username: null}}));
      }
    })
      // we check for the current user unless there is a redirect to ?signedIn=true
    // if (!window.location.search.includes('?signedin=true')) {
      if(!user.user.hasOwnProperty("username")){
        // if(!alreadyCheckedUser){
          dispatch(checkUser());
        // }

      }
    // }
    // return () => Hub.remove('auth')
  }, [])

  useEffect(()=> {
    dispatch(setGoogle(google))
  },[])

  //gets cities, likes list and purchases if user is set
  useEffect(()=>{
    if(user.user.hasOwnProperty("username")){
      // if(user.user.username!=="google_108642699935174820482" && user.user.attributes.email !=="google_106006354853614407407"){
      //   dispatch(setModal("error","Coming Soon", "Sign up and we'll let you know when we launch!", comingSoon))
      // }
        if(!loadingUserData){
          //gets the cities
          dispatch(fetchCities());
          dispatch(FetchUserData(user.user.username, window.sessionStorage.refId))
          dispatch(fetchPromotions(filters.city.value, true, filters.name, filters.age, filters.tag))
          dispatch(fetchPurchases(user.user.username))
          dispatch(fetchTags(filters.city.value))
          dispatch(fetchNames(filters.city.value))

          //REMEMBER TO RETURN THIS
          //handleUserLocation();
        }

    }
  }, [user.user, dispatch])

  //fetches promotions based on city
  useEffect(()=>{
    if(user.user.hasOwnProperty("username")){
      dispatch(fetchPromotions(filters.city.value, true, filters.name, filters.age, filters.tag))

      dispatch(fetchTags(filters.city.value))
      dispatch(fetchNames(filters.city.value))
    }
  },[filters.city.value])

  useEffect(()=>{
    if(user.likes.length>0 && !promotionsLoader && !fetchedSavedPromotions){
      dispatch(fetchSavedPromotions(user.likes, promotions))
    }
  }, [user.likes, promotionsLoader])

  //fetches promotions based on filters
  useEffect(()=>{
    if(user.user.hasOwnProperty("username")){
      let fetch = false;
      if(filters.name.active){
        fetch = UI.checkFetchPromotionBasedOnName(promotions,filters.name.value)
      }
      if(filters.tag.active && !fetch){
        fetch = UI.checkFetchPromotionsBasedOnTags(promotions,filters.tag.value,filters.tag.limit)
      }
      if(filters.age.active && !fetch){
        fetch = true
      }
      if(fetch){
        dispatch(fetchPromotions(filters.city.value, true, filters.name, filters.age, filters.tag, undefined, promotions))
      }
      // console.log("NEED TO FETCH?", UI.checkFetchPromotionsBasedOnTags(promotions,filters.tag.value,filters.tag.limit))
    }
  },[filters.tag, filters.name, filters.age])

  const handleUserLocation = async() => {
    //gets the users location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async(position) => {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        let addressJson = await UI.getAddressJson(lat,lng, google);
        dispatch(setLocation(lat, lng, addressJson.city, addressJson.country));
        dispatch(setCity(addressJson.city));

      });
    } else {
      console.log("Not Available");
    }
  }

  // let history = useHistory();
  // try{
  //   console.log("APP JSX => ",history.location.state.from)
  // } catch (err) {
  // }

  return (
      <div className="App">
        <Header/>
        <Form/>
        <Navbar/>
        <Routes/>
        <Tour
          steps={instructionsSteps}
          isOpen={isTourOpen}
          onRequestClose={()=> setIsTourOpen(false)}
          rounded={15}
          accentColor={"#564cac"}
        />

        <Filters/>
        <PopupModal/>
        <PaymentMethodsPopupModal/>

        <input
          type="file"
          accept="image/*; capture=camera"
          id="camera"
          style={{display:'none'}}
          onChange={async(e)=>{
              try{

                  var imageFile = e.target.files[0];
                  let blob = await imageCompression(imageFile, imageCompressionOptions)

                  var reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = function() {
                      // var base64Img = reader.result;
                      // dispatch(setImg(base64Img));
                      console.log("IMAGE LOADED")
                      // dispatch(setDisplay("UPLOAD IMAGE", "Confirm photo", display.adId, display.adIndex, display.ad))
                  }
                  // let img = await ZESTTY_BL.Ads.toBase64(ZESTTY_BL.Ads.processFile(e.target.files[0],e.target.files[0].type))
                  //dispatch(setImg(img));
              }
              catch (err) {
                console.log(err)
              }
          }}/>
      </div>

  )
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyBPJUpilKiLyuG7hpmiIqgOfiVP3AXLO0U')
}) (App);
