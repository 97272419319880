// import update from 'react-addons-update';

const initialState = {
    promotions: [],
    lastEvalKey: {},
    fetchedAll: false,
    fetchedLikes: false,
    err: "null",
    loading: false
}

export const promotionsReducer = (state = initialState, action) => {

    switch (action.type) {


        case "GET_PROMOTIONS":
            return {
                ...state,
                loading: true
            }

        case "GET_PROMOTIONS_SUCCESS":
            return {
                ...state,
                promotions: action.promotions,
                lastEvalKey: action.lastEvalKey,
                loading: false
            }

        case "GET_PROMOTIONS_FAILURE":

            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "GET_SAVED_PROMOTIONS":
            return {
                ...state,
                loading: true
            }

        case "GET_SAVED_PROMOTIONS_SUCCESS":
            return {
                ...state,
                promotions: action.promotions,
                fetchedLikes: true,
                loading: false
            }

        case "UPDATE_PROMOTION_SUCCESS":
            return {
                ...state,
                promotions: action.promotions
            }

        case "GET_SAVED_PROMOTIONS_FAILURE":

            return {
                ...state,
                err: action.err,
                fetchedLikes: true,
                loading: false
            }

        case "POST_REPORT":
            return {
                ...state,
                loading: true
            }

        case "POST_REPORT_FAIL":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "POST_REPORT_SUCCESS":
            return {
                ...state,
                loading: true
            };

        case "SET_FETCHED_LIKES":
            return {
                ...state,
                fetchedLikes: action.fetchedLikes
            }

            // case "SET_LIKE":
            //     return update(state, {
            //         promotions: {
            //             [action.index]: {
            //                 isLiked: { $set: action.isLiked }
            //             }
            //         }
            //     });

            // case "SET_PURCHASE_IN_PROMOTIONS":
            //     return update(state, {
            //         promotions: {
            //             [action.index]: {
            //                 isPurchased: { $set: true }
            //             }
            //         }
            //     });


            // case "SET_TAGS":
            //     return {
            //         ...state,
            //         tags: action.tags
            //     }

            // case "SET_NAMES":
            //     return {
            //         ...state,
            //         names: action.names
            //     }

        default:
            return state;
    }
}