import React from "react";
import {useSelector} from 'react-redux'
import {NavLink, Route, Switch} from 'react-router-dom'

//images
import DiscoverActive from '../img_resources/Shared Components/Navbar/discover-active.svg'
import Discover from '../img_resources/Shared Components/Navbar/discover.svg'
import MapActive from '../img_resources/Shared Components/Navbar/map-active.svg'
import Map from '../img_resources/Shared Components/Navbar/map.svg'
import HomeActive from '../img_resources/Shared Components/Navbar/home-active.svg'
import Home from '../img_resources/Shared Components/Navbar/home.svg'
import SavedActive from '../img_resources/Shared Components/Navbar/heart-active.svg'
import Saved from '../img_resources/Shared Components/Navbar/heart.svg'
import PurchasedActive from '../img_resources/Shared Components/Navbar/purchased-active.svg'
import Purchased from '../img_resources/Shared Components/Navbar/purchased.svg'

const Navbar = () => {

  const user = useSelector(store=>store.user);

    return(
      !user.user.username || !user.isVerified?
        <></>:
        <nav className="navbar" >
          <NavLink to={`/Discover`} className="navLink" activeClassName="navLink selectedLink">
              <Switch>
                <Route path="/Discover">
                  <>
                    <img className="icon selectedLinkImage"
                      src={DiscoverActive}
                      alt="discover button" >
                    </img>
                    Discover
                  </>
                </Route>
                <Route path="/">
                  <img className="icon"
                    src={Discover}
                    alt="discover button" >
                  </img>
                </Route>
              </Switch>
          </NavLink>
          
          <NavLink to={`/Map`} className="navLink" activeClassName="navLink selectedLink">
            <Switch>
              <Route path="/Map">
                <>
                  <img className="icon selectedLinkImage"
                    src={MapActive}
                    alt="map button" >
                  </img>
                  Map
                </>
              </Route>
              <Route path="/">
                <img className="icon"
                  src={Map}
                  alt="map button" >
                </img>
              </Route>
            </Switch>
          </NavLink>
          
          <NavLink to={`/Feed`} className="navLink" activeClassName="navLink selectedLink">
            <Switch>
              <Route path="/Feed">
                <>
                  <img className="icon selectedLinkImage"
                    src={HomeActive}
                    alt="feed button" >
                  </img>
                  Feed
                </>
              </Route>
              <Route path="/">
                <img className="icon"
                  src={Home}
                  alt="feed button" >
                </img>
              </Route>
            </Switch>
          </NavLink>

          <NavLink to={`/Saved`} className="navLink" activeClassName="navLink selectedLink">
            <Switch>
              <Route path="/Saved">
                <>
                  <img className="icon selectedLinkImage"
                    src={SavedActive}
                    alt="feed button" >
                  </img>
                  Saved
                </>
              </Route>
              <Route path="/">
                <img className="icon"
                  src={Saved}
                  alt="saved button" >
                </img>
              </Route>
            </Switch>
          </NavLink>
          
          <NavLink to={`/Purchases`} className="navLink" activeClassName="navLink selectedLink" id="purchases-link">
            <Switch>
              <Route path="/Purchases">
                <>
                  <img className="icon selectedLinkImage"
                    src={PurchasedActive}
                    alt="feed button" >
                  </img>
                  Purchases
                </>
              </Route>
              <Route path="/">
                <img className="icon"
                  src={Purchased}
                  alt="purchased button" >
                </img>
              </Route>
            </Switch>
          </NavLink>
        </nav>
    );
}


export default Navbar;